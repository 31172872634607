import React, { useEffect, useState, useContext } from 'react';
import Card from '../Common/CourseCard';
import PageLayout from '../Layouts/PageLayout';
import { getAuthDataCall, getDataCall } from '../../components/services/utils';
import { withRouter, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../Common/Loader';
import Select from 'react-select';
import { UserContext } from '../context/AuthContext';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import SelectCountryModal from '../modals/SelectCountryModal';
import { useModal } from 'react-modal-hook';
import { countryAction } from '../../containers/unauthedUser/actions/country';

function AllCourses(props) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const options = [
        { value: 'low', label: t('course.priceLowToHigh', 'Price low to high') },
        { value: 'high', label: t('course.priceHighToLow', 'Price high to low') },
    ];

    const type = props.match.params.type;
    const sub_type = props.match.params.sub_type;
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [tags, setTags] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [nextPage, setNextPage] = useState('');
    const [priceFilter, setPriceFilter] = useState();
    const [tag, setTag] = useState(0);
    const { user, loggedIn, loadingUser } = useContext(UserContext);
    const userCountry = useSelector((state) => state.unauthedUserInfo.country);
    const [show, setShow] = useState(false);

    const [longitude, setLongitude] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [geolocationError, setGeolocationError] = useState(null);
    const [searchInstructor, setSearchInstrcutor] = useState([]);
    const [instructors, setInstructors] = useState([]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            },
            (error) => {
                setGeolocationError(error);
            }
        );
    }, []);

    const getCountryInfo = async () => {
        const response = await getDataCall(`/v1/country?lat=${latitude}&long=${longitude}`);
        return response.data;
    };

    const [openSelectCountryModal, closeSelectCountryModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <SelectCountryModal closeModal={closeSelectCountryModal} setRecCourses={setData} setShow={setShow} />
            </ReactModal>
        );
    });

    useEffect(() => {
        if (!loggedIn && geolocationError?.code === 1 && userCountry === null) {
            // user denied location
            openSelectCountryModal();
        }
        if (!loggedIn && (latitude === null || longitude === null)) return; // one of the values is missing, do not make the network call
        if (!loggedIn && userCountry === null) {
            getCountryInfo().then((countryInfo) => {
                if (countryInfo.id !== 112 && countryInfo.id !== 17) {
                    // region/country is not being served at this point show modal to select region.
                    console.log('unaccepted region ', countryInfo.id);
                    openSelectCountryModal();
                } else {
                    dispatch(countryAction.idUnauthedCountry({ id: countryInfo.id, name: countryInfo.name }));
                    localStorage.setItem(
                        'unauthedUserCountry',
                        JSON.stringify({ id: countryInfo.id, name: countryInfo.name })
                    );
                }
            });
        }
    }, [latitude, longitude, geolocationError]);

    // browse category courses
    async function getCourseByCategory(url) {
        setLoading(true);
        const resp = await getDataCall(url);
        if (resp.success) {
            setNextPage(resp.data.next_page_url);
            setHasNextPage(true);
            setLoading(false);
            setData(resp.data.data);
        }
        if (resp.data.current_page === resp.data.last_page) {
            setHasNextPage(false);
            return;
        }
    }

    async function getTags(url) {
        setLoading(true);
        const resp = await getDataCall(url);
        if (resp.success) {
            setTags(resp.data);
        }
        setLoading(false);
    }

    async function fetchCourseData() {
        let resp = await getDataCall(nextPage);
        if (resp.success) {
            setNextPage(resp.data.next_page_url);
            setHasNextPage(true);
            setData([...data, ...resp.data.data]);
        }

        if (resp.data.current_page === resp.data.last_page) {
            setHasNextPage(false);
            return;
        }
    }

    // all course
    async function getAllCourses(url) {
        setTag(0);
        setLoading(true);
        const resp = await getAuthDataCall(url);
        if (resp.success) {
            setNextPage(resp.data.next_page_url);
            setLoading(false);
            setData(resp.data.data);
            setSearchInstrcutor(resp.data.data);
            getAllInstructors(resp.data.data);
            setHasNextPage(true);
        }
        if (resp.data.current_page === resp.data.last_page) {
            setHasNextPage(false);
            return;
        }
    }

    function getAllInstructors(courses) {
        const instructorSet = new Set();

        courses?.forEach((course) => {
            course?.instructors?.forEach((instructor) => {
                // Check if the instructor is already added to the set
                if (!instructorSet.has(instructor.id)) {
                    instructorSet.add(instructor.id); // Add the instructor ID to the set
                    instructors.push({
                        value: instructor.id,
                        label: `${instructor.first_name} ${instructor.last_name}`,
                    });
                }
            });
        });
    }

    async function fetchData() {
        let resp = await getDataCall(nextPage);
        if (resp.success) {
            setNextPage(resp.data.next_page_url);
            setData([...data, ...resp.data.data]);
            setHasNextPage(true);
        }
        if (resp.data.current_page === resp.data.last_page) {
            setHasNextPage(false);
            return;
        }
    }

    // filter data
    async function getFilterData(filter, tag) {
        setTag(tag);
        setLoading(true);
        let url;
        if (type === 'all') {
            if (loggedIn) {
                url = `v1/Courses?${filter}&auth=${Cookies.get('auth')}`;
            } else if (userCountry.id !== null) {
                url = `v1/Courses?${filter}&countryId=${userCountry.id}`;
            } else {
                url = `v1/Courses?${filter}&lat=${latitude}&long=${longitude}`;
            }
        } else {
            if (loggedIn) {
                url = `v1/categoryCourses/1?${filter}&auth=${Cookies.get('auth')}`;
            } else if (userCountry.id !== null) {
                url = `v1/Courses?${filter}&countryId=${userCountry.id}`;
            } else {
                url = `v1/categoryCourses/1?${filter}&lat=${latitude}&long=${longitude}`;
            }
        }
        const resp = await getDataCall(url);
        if (resp.success) {
            setNextPage(resp.data.next_page_url);
            setLoading(false);
            setData(resp.data.data);
        }
        if (resp.data.current_page === resp.data.last_page) {
            setHasNextPage(false);
            return;
        }
    }

    // filter by price
    async function getFilterDataBYPrice(filter) {
        setLoading(true);
        let url;
        if (loggedIn) {
            url = `v1/Courses?from=${filter.value}&auth=${Cookies.get('auth')}`;
        } else if (userCountry.id !== null) {
            url = `v1/Courses?${filter}&countryId=${userCountry.id}`;
        } else {
            url = `v1/Courses?from=${filter.value}&lat=${latitude}&long=${longitude}`;
        }
        const resp = await getDataCall(url);
        if (resp.success) {
            setHasNextPage(true);
            setNextPage(resp.data.next_page_url);
            setLoading(false);
            setData(resp.data.data);
        }
        if (resp.data.current_page === resp.data.last_page) {
            setHasNextPage(false);
            return;
        }
    }

    // filter by instructor
    async function getFilterDataByInstructor(filter) {
        const selectedSearch = filter.value;
        if (selectedSearch === null || selectedSearch == '') {
            setData(searchInstructor);
            return;
        }

        const filteredData = searchInstructor.filter((el, i) => {
            if (el?.instructors.find((ke, j) => ke.id == selectedSearch)) {
                return el;
            } else {
                return;
            }
        });

        setData(filteredData);
    }

    useEffect(() => {
        if (!loggedIn) {
            getTags('/v1/tags');
        }
    }, []);
    // const me = useSelector((state) => state.me);
    useEffect(() => {
        if (loggedIn && location.state === undefined && user) {
            getAllCourses(`/v1/Courses?auth=${Cookies.get('auth')}`);
            return;
        }
        if (loggedIn && location.state && user) {
            if (location.sub_category === undefined) {
                getCourseByCategory(`v1/categoryCourses/${location.state}?auth=${Cookies.get('auth')}`);
            } else {
                getCourseByCategory(
                    `v1/categoryCourses/${location.state}/${location.sub_category}?auth=${Cookies.get('auth')}`
                );
            }
            return;
        }
    }, [user, location]);

    useEffect(() => {
        if ((latitude === null || longitude === null) && userCountry === null) return; //haven't recieved geolocation, will rerender when the coords are loaded

        if (!loggedIn && location.state === undefined) {
            if (userCountry !== null && userCountry.id !== null) {
                getAllCourses(`/v1/Courses?countryId=${userCountry.id}`);
                // return;
            }
            getAllCourses(`/v1/Courses?lat=${latitude}&long=${longitude}`);
            // return;
        }
        if (!loggedIn && location.state) {
            if (location.sub_category === undefined) {
                if (userCountry !== null && userCountry.id !== null)
                    getCourseByCategory(`v1/categoryCourses/${location.state}?countryId=${userCountry.id}`);
                else getCourseByCategory(`v1/categoryCourses/${location.state}?lat=${latitude}&long=${longitude}`);
            } else {
                if (userCountry !== null && userCountry.id !== null)
                    getCourseByCategory(
                        `v1/categoryCourses/${location.state}/${location.sub_category}?countryId=${userCountry.id}`
                    );
                else
                    getCourseByCategory(
                        `v1/categoryCourses/${location.state}/${location.sub_category}?lat=${latitude}&long=${longitude}`
                    );
            }
            // return;
        }
    }, [location, longitude, latitude]);

    const customStyles = {
        padding: '5px 0px',
        borderRadius: '11px',
        borderCcolor: 'red',
        width: '100px',
    };
    return (
        <>
            {!loadingUser && !loading ? (
                <PageLayout me={user}>
                    <section className="mb_50 mt_50">
                        <div className="container">
                            <h2 className="page_title">
                                {location.state === undefined
                                    ? t('routes.allCourses')
                                    : type + ' ' + t('common.courses')}
                            </h2>
                            {location.state === undefined && (
                                <div className="row" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                                    <div className="col-lg-7 col-md-8 col-12">
                                        <div className="all_filters">
                                            <button
                                                className={`filter_btn ${
                                                    tag === 0 ? 'btn_primary' : 'btn_primary_inverted'
                                                }`}
                                                onClick={() => {
                                                    if (loggedIn) {
                                                        getAllCourses(
                                                            `v1/Courses?auth=${Cookies.get('auth')}&lat=${latitude}&long=${longitude}`
                                                        );
                                                    } else {
                                                        if (userCountry !== null)
                                                            getAllCourses(`v1/Courses?countryId=${userCountry.id}`);
                                                        else
                                                            getAllCourses(
                                                                `v1/Courses?lat=${latitude}&long=${longitude}`
                                                            );
                                                    }
                                                }}
                                            >
                                                {t('common.all')}
                                            </button>
                                            {tags.length > 0
                                                ? tags.map((item, key) => {
                                                      return (
                                                          <button
                                                              className={`filter_btn ${
                                                                  tag === item.id
                                                                      ? 'btn_primary'
                                                                      : 'btn_primary_inverted'
                                                              }`}
                                                              onClick={() => {
                                                                  getFilterData('tag_id=' + item.id, item.id);
                                                                  setHasNextPage(true);
                                                              }}
                                                              key={key}
                                                          >
                                                              {t('category.' + item.name, item.name)}
                                                          </button>
                                                      );
                                                  })
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-4 col-12 d-lg-block d-md-block d-flex justify-content-center align-items-center flex-row flex-nowrap">
                                        <div className="sort_wrapper row flex-grow-1">
                                            <div className="col-md-6 col-12 px-1">
                                                <Select
                                                    name="form-field-name"
                                                    className="form-control"
                                                    value={priceFilter}
                                                    onChange={(selected) => {
                                                        setPriceFilter(selected);
                                                        getFilterDataBYPrice(selected);
                                                    }}
                                                    placeholder={t('common.select')}
                                                    options={options}
                                                    styles={customStyles}
                                                />
                                            </div>
                                            <div className="col-md-6 col-12 px-1">
                                                {instructors && instructors.length > 0 && (
                                                    <Select
                                                        name="form-field-name"
                                                        className="form-control"
                                                        onChange={(selected) => {
                                                            getFilterDataByInstructor(selected);
                                                        }}
                                                        placeholder={t('common.instructor')}
                                                        options={instructors}
                                                        styles={customStyles}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {data?.length >= 1 ? (
                                <div dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                                    <InfiniteScroll
                                        className="row"
                                        dataLength={data?.length} //This is important field to render the next data
                                        next={location.state === undefined ? fetchData : fetchCourseData}
                                        hasMore={hasNextPage}
                                        loader={<h4>Loading...</h4>}
                                    >
                                        {data?.map((course, index) => (
                                            <Card
                                                key={index}
                                                data={course}
                                                id={course?.id}
                                                category={course?.category?.name}
                                                title={course?.name}
                                                tutor={
                                                    course?.instructor?.first_name + ' ' + course?.instructor?.last_name
                                                }
                                                price={course?.is_free === 0 ? course?.course_sale : 'Free'}
                                                thumbnail={course?.course_image}
                                                isFree={course?.is_free}
                                                isCart={course?.is_cart}
                                                isWishlist={course?.is_wishlist}
                                                isPurchased={course?.is_purchased}
                                                translation={course?.translation}
                                                instructorsList={course?.instructors}
                                            />
                                        ))}
                                    </InfiniteScroll>
                                    {hasNextPage ? (
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: 'fit-content',
                                                margin: 'auto',
                                            }}
                                        >
                                            <button
                                                className="btn_primary btn_ "
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}
                                                onClick={fetchCourseData}
                                            >
                                                <span>See more courses</span>
                                                <span>&darr;</span>
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <h1 style={{ textAlign: 'center', marginTop: '16px' }}>{t('common.comingSoon')}</h1>
                            )}
                        </div>
                    </section>
                </PageLayout>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default withRouter(AllCourses);
