import { profileConstants } from '../constants';

const initialState = {
    loading: false,
    error: true,
    profile: [],
};

export default function authentication(state = initialState, action) {
    switch (action.type) {
        case profileConstants.REQUEST_PROFILE:
            return {
                loading: true,
                error: false,
                profile: [],
            };
        case profileConstants.RECIEVE_PROFILE:
            return {
                loading: false,
                error: false,
                profile: action.profile.data,
            };
        case profileConstants.ERROR_PROFILE:
            return {
                loading: false,
                error: true,
                profile: action.error,
            };
        default:
            return state;
    }
}
