import axios from 'axios';
import Cookies from 'js-cookie';

export const userService = {
    login,
    registerStudent,
    registerCreator,
    logout,
    user,
    forgotPasswordSendMail,
    forgotPasswordVerifyOtp,
    newPassword,
    registerVerificationOtp,
    resendOtp,
};

function user() {
    const requestOptions = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + Cookies.get('EAPISID'),
        },
    });
    return requestOptions
        .get('me')
        .then(handleUserResponse)
        .catch((error) => error.response);
}

function handleUserResponse(response) {
    return response;
}

function login(email, password) {
    const requestOptions = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: { 'Content-Type': 'application/json' },
    });

    return requestOptions
        .post('/auth/login', JSON.stringify({ email, password }))
        .then(handleResponse)
        .catch((error) => {
            if (error.response.status === 401) {
                logout();
                return error.response;
            } else if (error.response.status === 403) {
                return error.response;
            }

            return error.response.data;
        });
}

function registerStudent(payload) {
    const requestOptions = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: { 'Content-Type': 'application/json' },
    });
    return requestOptions
        .post('/auth/register?type=student', JSON.stringify(payload))
        .then(handleRegisterResponse)
        .catch((error) => {
            if (error.response.status === 401) {
                logout();
            }
            return error.response.data;
        });
}

function registerCreator(payload) {
    const requestOptions = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: { 'Content-Type': 'application/json' },
    });

    return requestOptions
        .post('/auth/register?type=instructor', JSON.stringify(payload))
        .then(handleRegisterResponse)
        .catch((error) => {
            if (error.response.status === 401) {
                logout();
            }
            return error.response.data;
        });
}

function forgotPasswordSendMail(email) {
    const requestOptions = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: { 'Content-Type': 'application/json' },
    });

    return requestOptions
        .post('auth/forgot', { email: email })
        .then(handleRegisterResponse)
        .catch((error) => {
            // console.log(error);
        });
}

function forgotPasswordVerifyOtp(email, otp) {
    const requestOptions = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + Cookies.get('tempAUTH&TOKEN'),
        },
    });

    return requestOptions
        .post('auth/forgotVerfiyOtp', { email: email, otp: otp })
        .then(handleRegisterResponse)
        .catch((error) => {
            // console.log(error);
        });
}

function registerVerificationOtp(otp) {
    const requestOptions = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + Cookies.get('tempAUTH&TOKEN'),
        },
    });

    return requestOptions
        .post('auth/otpVerify', { otp: otp })
        .then((res) => res)
        .catch((error) => {
            // console.log(error);
        });
}

function newPassword(email, password, cpassword) {
    const requestOptions = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + Cookies.get('tempAUTH&TOKEN'),
        },
    });

    return requestOptions
        .post('auth/forgotNewPassword', {
            email: email,
            new_password: password,
            confirm_password: cpassword,
        })
        .then(handleResponse)
        .catch((error) => {
            // console.log(error);
        });
}

function handleResponse(response) {
    // store access token in cookie to keep user logged in between page refreshes
    let user = response.data.data;
    if (user.token) {
        Cookies.set('EAPISID', user.token, { expires: 180, path: '/' });
        Cookies.set('auth', user.user.auth_id);
        Cookies.remove('tempAUTH&TOKEN');
    }
    return response;
}

function handleRegisterResponse(response) {
    // store access token in cookie to keep user logged in between page refreshes
    let user = response.data.data;
    if (user.token) {
        Cookies.set('tempAUTH&TOKEN', user.token, { expires: 180, path: '/' });
    }
    return response;
}

function handleResponseForgot(response) {
    // console.log(response);
    let user = response.data.data;
    if (user.token) {
        Cookies.set('EAPISID', user.token, { expires: 180, path: '/' });
    }

    return response;
}

function logout() {
    // remove user from local storage to log user out
    Cookies.remove('EAPISID');
    Cookies.remove('auth');
}

function resendOtp(email) {
    console.log('----called----- resendOtp services');
    const requestOptions = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: { 'Content-Type': 'application/json' },
    });
    return requestOptions
        .post('/auth/forgot', { email: email, resend: true })
        .then((response) => response)
        .catch((error) => {
            // console.log(error);
        });
}
