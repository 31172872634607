import { userConstants } from '../constant';

const initialState = {
    loading: false,
    error: false,
    status: [],
};

export default function register(state = initialState, action) {
    switch (action.type) {
        case userConstants.REQUEST_EMAIL_OTP:
            return {
                loading: true,
                error: false,
                status: [],
            };
        case userConstants.RECEIVE_EMAIL_OTP:
            return {
                loading: false,
                error: false,
                status: action.status,
            };
        case userConstants.ERROR_EMAIL_OTP:
            return {
                error: true,
                loading: false,
                register: action.error,
            };
        default:
            return state;
    }
}
