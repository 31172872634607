import { userConstants } from '../constant';
import { userService } from '../services';

export const forgotVerifyOtpActions = {
    verifyOtp,
};

function verifyOtp(email, otp) {
    return (dispatch) => {
        dispatch(request({ email }));
        userService
            .forgotPasswordVerifyOtp(email, otp)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(success(response.data));
                } else {
                    dispatch(failure(response.data));
                }
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };

    function request(user) {
        return { type: userConstants.REQUEST_FORGOT_VERIFY_OTP, user };
    }
    function success(forgot) {
        return { type: userConstants.RECEIVE_FORGOT_VERIFY_OTP, forgot };
    }
    function failure(error) {
        return { type: userConstants.ERROR_FORGOT_VERIFY_OTP, error };
    }
}
