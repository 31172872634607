import { userConstants } from '../constant';
import { userService } from '../services';

export const registerOTPResend = {
    sendOTPAgain,
    clearResendOtpState,
};

function sendOTPAgain(email) {
    return (dispatch) => {
        dispatch(request());
        return userService
            .resendOtp(email)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(success(response.data));
                } else {
                    dispatch(failure(response));
                }
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };

    function request(user) {
        return { type: userConstants.RESEND_OTP, user };
    }
    function success(status) {
        return { type: userConstants.RESEND_OTP_SUCCESS, status };
    }
    function failure(error) {
        return { type: userConstants.RESEND_OTP_FAILURE, error };
    }
}

function clearResendOtpState() {
    return { type: userConstants.CLEAR_RESEND_OTP_STATE };
}
