import React, { useState, useContext, useEffect } from 'react';
import PageLayout from '../Layouts/PageLayout';
import Loader from '../Common/Loader';
import { UserContext } from '../context/AuthContext';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import AddressList from './addressList';
import AddAddress from './addAddress';
import EditAddress from './editAddress';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function Addresses() {
    let location = useLocation();
    const { t, i18n } = useTranslation();
    const { loadingUser } = useContext(UserContext);
    const [activeTab, setActiveTab] = useState(location.search);
    const history = useHistory();

    useEffect(() => {
        setActiveTab(location.search);
    }, [location]);

    return (
        <React.Fragment>
            {!loadingUser ? (
                <PageLayout>
                    <section className="blue_section">
                        <div className="container">
                            <h1 className="blue_section_title mtb_50">{t('common.myAddresses')}</h1>
                            <div className="blue_section_tabs" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                                <a
                                    className={activeTab === '?all' ? 'active' : ''}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/addresses',
                                            search: 'all',
                                        });
                                    }}
                                    style={{ color: 'white', cursor: 'pointer' }}
                                >
                                    {t('common.all')}
                                </a>
                                <a
                                    className={activeTab === '?add' ? 'active' : ''}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/addresses',
                                            search: 'add',
                                        });
                                    }}
                                    style={{ color: 'white', cursor: 'pointer' }}
                                >
                                    {t('common.add')}
                                </a>
                            </div>
                        </div>
                    </section>
                    {activeTab === '?all' && <AddressList />}
                    {activeTab === '?add' && <AddAddress />}
                    {activeTab === '?edit' && <EditAddress />}
                    <ToastContainer />
                </PageLayout>
            ) : (
                <Loader />
            )}
        </React.Fragment>
    );
}
