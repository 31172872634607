import React, { useContext } from 'react';
import { emptyCart } from '../Utils/helper';
import { CartContext } from '../context/CartContext';
import { useTranslation } from 'react-i18next';

// TODO: Clean this up a lot
function EmptyCartModal({ closeModal, setShow, loggedIn }) {
    const { t } = useTranslation();

    const { setCartCount, setCartData } = useContext(CartContext);
    const handleDeleteAndClose = () => {
        emptyCart(loggedIn, setCartCount, setCartData);

        setShow(false);
        closeModal();
    };

    const handleClose = () => {
        setShow(false);
        closeModal();
    };

    return (
        <div>
            <>
                <div className="lrf_modal">
                    <h2>{t('alertMessage.emptyCart')}</h2>
                </div>
                <div className="lrf_subtext">
                    <p>{t('alertMessage.emptyCartSubtext')}</p>
                </div>
                <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <button class="btn_primary" style={{ marginRight: '5px' }} onClick={handleClose}>
                        Close
                    </button>
                    <button class="btn_primary" style={{ marginLeft: '5px' }} onClick={handleDeleteAndClose}>
                        Proceed
                    </button>
                </div>
            </>
        </div>
    );
}

export default EmptyCartModal;
