import { getAuthDataCall, postAuthDataCall } from '../utils';

export const getStudentList = async () => {
    const resp = await getAuthDataCall(`/auth/DosUserStudent`);
    return resp;
};

export const getInstructorList = async () => {
    const resp = await getAuthDataCall(`/auth/DosUserInstructor`);
    return resp;
};

export const getAllComments = async (id, page) => {
    const resp = await getAuthDataCall(`/auth/UserComments/${id}`);
    return resp;
};

export const postComments = async (id, payload) => {
    const resp = await postAuthDataCall(`auth/UserComment/${id}`, payload);
    return resp;
};
