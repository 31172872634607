import React from 'react';
import { withRouter } from 'react-router-dom';

function DynamicRoutePage(props) {
    const id = props.match.params.id;

    return <div>Dynamic Route {id}</div>;
}

export default withRouter(DynamicRoutePage);
