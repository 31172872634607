import React from 'react';
import { icons } from '../../constants/icons';

function Loader() {
    return (
        <div className="preloader">
            <div className="preloader_inner">
                <div className="preloaderlogo">
                    <img src={icons.dawraty_logo} alt="" />
                </div>
                <div className="preloaderbar"></div>
            </div>
        </div>
    );
}

export default Loader;
