import React, { useEffect, useState } from 'react';
import { loginActions } from '../../containers/user/actions/login';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../../constants/message';
import { icons } from '../../constants/icons';
import { addToCartBulk } from '../services/courses';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {Login authentication} author by Bilal Bhojani
 * @returns
 */

function Login({ forgotOpen, loginClose, signupOpen }) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loginInputs, setLoginInputs] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [passwordToggle, setPasswordToggle] = useState(false);
    const { email, password } = loginInputs;
    const login = useSelector((state) => state.loginData);

    function handleLoginChange(e) {
        const { name, value, type, checked } = e.target;
        setLoginInputs((inputs) => ({
            ...inputs,
            [name]: type === 'checkbox' ? checked : value,
        }));
    }

    function handleEmailClick() {
        const savedEmail = localStorage.getItem('userEmail');
        const savedPassword = localStorage.getItem('userPassword');

        if (savedEmail) {
            setLoginInputs((inputs) => ({
                email: savedEmail,
                password: savedPassword,
            }));
        }
    }

    function loginUser(event) {
        event.preventDefault();
        if (loginInputs.password.trim().length <= 5) {
            setError(true);
            setErrorMsg(messages.PASSWORD_SHORT);
            return;
        }

        if (loginInputs.rememberMe) {
            // Store credentials in localStorage (or sessionStorage as needed)
            localStorage.setItem('userEmail', loginInputs.email);
            localStorage.setItem('userPassword', loginInputs.password);
        } else {
            // Clear stored credentials if "Remember Me" is not checked
            localStorage.removeItem('userEmail');
            localStorage.removeItem('userPassword');
        }

        dispatch(loginActions.login(loginInputs.email, loginInputs.password));
    }

    useEffect(() => {
        if (login !== {} && login.auth === undefined && login.message === 'device_exceeded') {
            setError(true);
            setErrorMsg(messages.LOGIN_FAILURE_DEVICE);
        } else if (login !== {} && login.auth === undefined) {
            setError(true);
            setErrorMsg(messages.LOGIN_ERROR);
        } else if (login.auth.status === 403) {
            setError(true);
            setErrorMsg('Profile Under Review');
        } else {
            setError(false);
        }
    }, [login]);

    useEffect(() => {
        const loginFunc = async () => {
            if (!login?.loading && !login?.error && login?.auth?.status === 'Success') {
                let courseCart = JSON.parse(localStorage.getItem('cartCourse'));
                console.log(login.auth.data.user.type);
                if (courseCart) {
                    let _token = login.auth.data.token;

                    let payload = [];
                    courseCart.map((_item) => {
                        payload.push(_item.id);
                    });

                    const resp = await addToCartBulk({ courses: payload }, _token);
                    if (login.auth.data.user.type === 'student') {
                        window.location.href = '/student-learning?courses';
                    } else {
                        window.location.reload();
                    }
                } else {
                    if (login.auth.data.user.type === 'student') {
                        window.location.href = '/student-learning?courses';
                    } else {
                        window.location.reload();
                    }
                }
            }
        };

        loginFunc();
    }, [login]);

    function signupModal() {
        signupOpen();
        loginClose();
    }

    function forgotModal() {
        forgotOpen();
        loginClose();
    }

    return (
        <div className="lrf_modal">
            <h2>{t('common.login')}</h2>
            <div className="lrf_subtext">
                {t('common.notHaveAccount')}{' '}
                <a onClick={signupModal} style={{ color: '#007bff', textDecoration: 'underline' }}>
                    {t('common.signUp')}
                </a>
            </div>
            <form onSubmit={loginUser}>
                <div className="form-group">
                    <label for="">{t('common.email')}</label>
                    <div className="form_input">
                        <input
                            type="email"
                            value={loginInputs.email}
                            className="form-control"
                            placeholder={t('common.yourEmailAddress')}
                            onChange={handleLoginChange}
                            onClick={handleEmailClick}
                            name="email"
                            required
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label for="">{t('common.password')}</label>
                    <div className="form_input">
                        <input
                            type={passwordToggle ? 'text' : 'password'}
                            value={loginInputs.password}
                            className="form-control"
                            placeholder={t('common.password')}
                            onChange={handleLoginChange}
                            name="password"
                            required
                        />
                        <span className="icon_eye">
                            <img
                                src={icons.icon_eye}
                                alt=""
                                onClick={() => {
                                    setPasswordToggle(!passwordToggle);
                                }}
                            />
                        </span>
                    </div>
                </div>
                <div className="form_flx">
                    <div className="form_accept">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheck"
                            name="rememberMe"
                            onChange={handleLoginChange}
                            checked={loginInputs.rememberMe || false} // Ensure controlled component
                        />
                        <label className="form-check-label" htmlFor="gridCheck">
                            {t('common.rememberMe')}
                        </label>
                    </div>

                    <a className="link_black" onClick={forgotModal}>
                        {t('common.forgetPassword')}
                    </a>
                </div>
                {error && <p className="text-danger">{errorMsg}</p>}
                <button className="btn_primary full_btn big_btn" type="submit">
                    {!login.error && login.loading && <span className="mr-2 spinner-border spinner-border-sm" />}
                    {t('common.login')}
                </button>
            </form>
        </div>
    );
}

export default Login;

//google button
{
    /* <div className="lrf_separator">
          <div className="lrf_separator_line" />
          <span>or Log-in with</span>
          <div className="lrf_separator_line" />
        </div>
        <button className="google_btn full_btn icon_btn">
          <img src={icons.icon_google} alt="" /> Google
        </button> */
}
{
    /* <div>
      <h1>Login Modal</h1>
      <input
        type="email"
        placeholder="Enter email"
        onChange={handleLoginChange}
        name="email"
        required
      />
      <br />
      <br />
      <input
        type="password"
        placeholder="Enter Password"
        onChange={handleLoginChange}
        name="password"
        required
      />
      <br />
      <button onClick={loginUser}>Login</button>
      <br />
      {error && <p>{errorMsg}</p>}
      <a onClick={signupModal}>Go to signup</a>
      <br />
      <a onClick={forgotModal}>Forgot password</a>
    </div> */
}
