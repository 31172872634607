import React, { useState, useEffect } from 'react';
import { registerActions } from '../../containers/user/actions/registerStudent';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../../constants/message';
import { signupEmailVerificationAction } from '../../containers/user/actions/signupEmailVerification';
import { icons } from '../../constants/icons';
import { addToCartBulk } from '../services/courses/index';
import useInterval from './Counter';
import { registerOTPResend } from '../../containers/user/actions/resendOtp';
import Cookies from 'js-cookie';
import { countryAction } from '../../containers/Utils/actions/country';
import { Trans, useTranslation } from 'react-i18next';
/**
 *
 * @param {signup authentication} author by Bilal Bhojani
 * @returns
 */

function Login({ closeSignup, openLogin }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const register = useSelector((state) => state.registerStudent);
    const emailVerification = useSelector((state) => state.emailVerification);
    const resend_otp = useSelector((state) => state.resendOtp);

    const [signUpInputs, setSignUpInputs] = useState({
        first_name: '',
        last_name: '',
        email: '',
        country_id: '112',
        password: '',
        cpassword: '',
        year: '',
    });

    const [fnameError, setFnameError] = useState('');
    const [lnameError, setLnameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [pwdError, setPwdError] = useState('');
    const [cpwdError, setCpwdError] = useState('');
    const [privacyError, setPrivacyError] = useState('');
    const [yearError, setYearError] = useState('');

    const { first_name, last_name, email, password, cpassword, year } = signUpInputs;
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [otpError, setOtpError] = useState(null);
    const [privacyToggle, setPrivacyToggle] = useState(false);
    const [passwordToggle, setPasswordToggle] = useState(false);
    const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
    const [emailOtp, setEmailOtp] = useState('');
    const [otpToggle, setOtpToggle] = useState(false);
    const [counter, setCounter] = useState(60);
    const countryVal = useSelector((state) => state.country);
    const yearOptions = ['firstYear', 'secondYear', 'thirdYear', 'fourthYear', 'fifthYear', 'sixthYear', 'seventhYear'];

    function handleLoginChange(e) {
        const { name, value } = e.target;
        setSignUpInputs((inputs) => ({ ...inputs, [name]: value }));
        if (name === 'first_name') {
            setFnameError('');
        }
        if (name === 'last_name') {
            setLnameError('');
        }
        if (name === 'email') {
            setEmailError('');
        }
        if (name === 'password') {
            setPwdError('');
        }
        if (name === 'cpassword') {
            setCpwdError('');
        }
        if (name === 'year') {
            setYearError('');
        }
    }

    console.log(signUpInputs.year);

    function validateSignUpInputs() {
        let errors = {};
        const { first_name, last_name, email, password, cpassword, year } = signUpInputs;

        if (first_name === '') {
            errors.first_name = t('alertMessage.enterFirstName');
        } else if (first_name.trim().length <= 2) {
            errors.first_name = messages.FNAME_ERROR;
        }

        if (last_name === '') {
            errors.last_name = t('alertMessage.enterLastName');
        } else if (last_name.trim().length <= 2) {
            errors.last_name = messages.LNAME_ERROR;
        }

        if (!email) {
            errors.email = t('alertMessage.enterEmail');
        }

        if (password === '') {
            errors.password = t('alertMessage.enterPassword');
        } else if (password.trim().length <= 5) {
            errors.password = messages.PASSWORD_SHORT;
        }

        if (cpassword === '') {
            errors.cpassword = t('alertMessage.confirmPassword');
        } else if (cpassword.trim().length <= 5) {
            errors.cpassword = messages.PASSWORD_SHORT;
        } else if (password !== cpassword) {
            errors.cpassword = messages.PASSWORD_ERROR;
        }

        if (!privacyToggle) {
            errors.privacy = t('alertMessage.enterPP');
        }
        if (year === null) {
            errors.year = t('alertMessage.enterYear');
        }
        return errors;
    }

    function registerStudent(event) {
        event.preventDefault();
        let errors = validateSignUpInputs();

        console.log('errors: ', errors);

        if (Object.keys(errors).length > 0) {
            if (errors['first_name'] !== '') {
                setFnameError(errors['first_name']);
            }
            if (errors['last_name'] !== '') {
                setLnameError(errors['last_name']);
            }
            if (errors['email'] !== '') {
                setEmailError(errors['email']);
            }
            if (errors['password'] !== '') {
                setPwdError(errors['password']);
            }
            if (errors['cpassword'] !== '') {
                setCpwdError(errors['cpassword']);
            }
            if (errors['privacy'] !== '') {
                setPrivacyError(errors['privacy']);
            }
            if (errors['year'] !== '') {
                setYearError(errors['year']);
            }
        } else {
            dispatch(registerActions.register(signUpInputs));
        }
    }

    useEffect(() => {
        if (privacyToggle) {
            setPrivacyError('');
        }
    }, [privacyToggle]);

    useEffect(() => {
        if (register?.error && register?.register?.data?.message === t('alertMessage.emailTaken')) {
            setError(true);
            setOtpToggle(false);
            setErrorMsg(register?.register?.data?.message);
        }
        if (
            register?.error &&
            register?.register?.data?.message === 'User Details Saved Successfully or Email Sended To OTP'
        ) {
            setOtpToggle(true);
        }
    }, [register]);

    useEffect(() => {
        const signUpFunc = async () => {
            if (
                !emailVerification.loading &&
                !emailVerification.error &&
                emailVerification?.status?.message === 'Email verified Successfully!'
            ) {
                let courseCart = JSON.parse(localStorage.getItem('cartCourse'));
                let auth_data = register.register.data.data;
                let _token = auth_data.token;
                Cookies.set('EAPISID', _token, { expires: 180, path: '/' });
                Cookies.set('auth', auth_data?.user.auth_id);
                if (courseCart) {
                    let payload = [];
                    courseCart.map((_item) => {
                        payload.push(_item.id);
                    });
                    const resp = await addToCartBulk({ courses: payload }, _token);
                    window.location.reload();
                } else {
                    window.location.reload();
                }
            }
            if (emailVerification?.status?.message === 'Invalid Otp' && emailVerification?.status?.status === 402) {
                setOtpError('Invalid OTP');
            }
        };

        signUpFunc();
    }, [emailVerification]);

    useInterval(() => {
        if (counter >= 1 && otpToggle) {
            setCounter(counter - 1);
        }
    }, 1000);

    useEffect(() => {
        if (resend_otp.status?.message === 'Otp Sended To Your Email !!') {
            setCounter(60);
        }
    }, [resend_otp.status]);

    useEffect(() => {
        dispatch(countryAction.getCountry('student'));
    }, []);

    function verifyEmailOTP() {
        dispatch(signupEmailVerificationAction.emailVerify(emailOtp));
    }

    function resendOtp() {
        dispatch(registerOTPResend.sendOTPAgain(register.register.data.data.user.email));
    }

    function goToLogin() {
        closeSignup();
        openLogin();
    }
    return (
        <div>
            <h2>{t('common.signUp')}</h2>
            <div className="lrf_subtext">
                {t('common.haveAccount')}
                <a
                    href="#"
                    onClick={goToLogin}
                    style={{
                        paddingLeft: '10px',
                        color: '#007bff',
                        textDecoration: 'underline',
                    }}
                >
                    {t('common.login')}
                </a>
            </div>
            <form onSubmit={registerStudent}>
                <div className="form_row">
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.firstName')}</label>
                            <div className="form_input">
                                <input
                                    type="text"
                                    value={first_name}
                                    className="form-control"
                                    name="first_name"
                                    onChange={handleLoginChange}
                                    // required
                                />
                                {fnameError && <p className="signup-error">{fnameError}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.lastName')}</label>
                            <div className="form_input">
                                <input
                                    type="text"
                                    value={last_name}
                                    className="form-control"
                                    name="last_name"
                                    onChange={handleLoginChange}
                                    // required
                                />
                                {lnameError && <p className="signup-error">{lnameError}</p>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form_row">
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.email')}</label>
                            <div className="form_input">
                                <input
                                    type="email"
                                    value={email}
                                    className="form-control"
                                    name="email"
                                    onChange={handleLoginChange}
                                    // required
                                />
                                {emailError && <p className="signup-error">{emailError}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.country')}</label>
                            <div className="form_input">
                                <select
                                    name="country_id"
                                    className="form-control"
                                    onChange={handleLoginChange}
                                    value={signUpInputs.country_id}
                                >
                                    {countryVal &&
                                        countryVal.country.map((_) => {
                                            return (
                                                _.label !== 'Israel' && (
                                                    <option key={_.value} value={_.value}>
                                                        {t('countries.' + _.label, _.label)}
                                                    </option>
                                                )
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form_row">
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.year')}</label>
                            <div className="form_input">
                                <select
                                    name="year"
                                    className="form-control"
                                    onChange={handleLoginChange}
                                    value={signUpInputs.year}
                                >
                                    {yearOptions &&
                                        yearOptions.map((year) => {
                                            return <option>{t('year.' + year, year)}</option>;
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form_row">
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.password')}</label>
                            <div className="form_input">
                                <input
                                    type={passwordToggle ? 'text' : 'password'}
                                    value={password}
                                    className="form-control"
                                    name="password"
                                    onChange={handleLoginChange}
                                    // required
                                />
                                <span className="icon_eye">
                                    <img
                                        src={icons.icon_eye}
                                        alt=""
                                        onClick={() => {
                                            setPasswordToggle(!passwordToggle);
                                        }}
                                    />
                                </span>
                            </div>
                            {pwdError && <p className="signup-error">{pwdError}</p>}
                        </div>
                    </div>
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.rePassword')}</label>
                            <div className="form_input">
                                <input
                                    type={confirmPasswordToggle ? 'text' : 'password'}
                                    value={cpassword}
                                    className="form-control"
                                    name="cpassword"
                                    onChange={handleLoginChange}
                                    // required
                                />
                                <span className="icon_eye">
                                    <img
                                        src={icons.icon_eye}
                                        alt=""
                                        onClick={() => {
                                            setConfirmPasswordToggle(!confirmPasswordToggle);
                                        }}
                                    />
                                </span>
                            </div>
                            {cpwdError && <p className="signup-error">{cpwdError}</p>}
                        </div>
                    </div>
                </div>

                <div className="form_flx">
                    <div className="form_accept">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={(e) => {
                                setPrivacyToggle(!privacyToggle);
                            }}
                            defaultChecked={privacyToggle}
                        />
                        <label className="form-check-label" for="gridCheck">
                            <Trans i18nKey="common.termsAndPrivacy">
                                I’ve read and agree to the
                                <a href="/privacy-policy" target="_blank">
                                    {' '}
                                    Terms of Use and Privacy Policy
                                </a>
                            </Trans>
                        </label>
                        {privacyError && <p className="signup-error">{privacyError}</p>}
                    </div>
                </div>
                {error && <p className="text-danger">{errorMsg}</p>}
                {!otpToggle && (
                    <button className="btn_primary full_btn big_btn" type="submit">
                        {!register.error && register.loading && (
                            <span className="mr-2 spinner-border spinner-border-sm"></span>
                        )}
                        {t('common.register')}
                    </button>
                )}
            </form>
            {otpToggle && (
                <>
                    <div className="d-flex justify-content-center align-items-center">
                        <p className="text-center p-0">OTP sent to {email}</p>
                        <p
                            style={{
                                paddingLeft: '10px',
                                color: '#007bff',
                                textDecoration: 'underline',
                            }}
                            className="cursor_pointer"
                            onClick={() => {
                                setOtpToggle(false);
                                setEmailOtp('');
                            }}
                        >
                            Change Email Address
                        </p>
                    </div>
                    {otpError && <p className="text-center text-danger">{otpError}</p>}
                    <div className="row">
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <div className="form-group">
                                {/* <label for="">Enter OTP</label> */}
                                <div className="form_input">
                                    <input
                                        type="text"
                                        value={emailOtp}
                                        className="form-control"
                                        placeholder="Please enter OTP"
                                        name="emailOtp"
                                        onChange={(e) => setEmailOtp(e.target.value)}
                                        required
                                    />
                                </div>
                                <div>Resend OTP in {`${counter}`} secs.</div>
                            </div>
                        </div>
                        <div className="col-sm-7 col-md-7 col-lg-7">
                            <button className="btn_primary big_btn" onClick={verifyEmailOTP}>
                                {!emailVerification.error && emailVerification.loading && (
                                    <span className="mr-2 spinner-border spinner-border-sm"></span>
                                )}
                                Verify OTP
                            </button>
                            <button
                                className="btn_primary big_btn verify-btn"
                                disabled={counter > 0}
                                onClick={resendOtp}
                            >
                                Resend OTP
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Login;

{
    /* <div>
      <h1>Signup Modal</h1>
      <input
        type="text"
        name="first_name"
        placeholder="Enter Fname"
        onChange={handleLoginChange}
        required
      />
      <br />
      <br />
      <input
        type="text"
        name="last_name"
        placeholder="Enter Lname"
        onChange={handleLoginChange}
        required
      />
      <br />
      <br />
      <input
        type="email"
        name="email"
        placeholder="Enter mail"
        onChange={handleLoginChange}
        required
      />
      <br />
      <br />
      <input
        type="password"
        name="password"
        placeholder="Enter password"
        onChange={handleLoginChange}
        required
      />
      <br />
      <br />
      <input
        type="password"
        name="cpassword"
        placeholder="Enter password"
        onChange={handleLoginChange}
        required
      />
      <br />
      <input
        type="checkbox"
        onChange={(e) => {
          setPrivacyToggle(!privacyToggle);
        }}
        defaultChecked={privacyToggle}
      />
      Privacy policy
      <br />
      <br />
      {error && <p>{errorMsg}</p>}
      <button onClick={registerStudent}>Register</button>
      <a onClick={goToLogin}>Go to Login</a>
    </div> */
}
