import axios from 'axios';

export const utilsServices = {
    country,
};

function handleUserResponse(response) {
    return response;
}

function country() {
    const requestOptions = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: { 'Content-Type': 'application/json' },
    });

    return requestOptions
        .get('/v1/countries')
        .then(handleUserResponse)
        .catch((error) => {
            // console.log(error);
        });
}
