import React from 'react';
import { icons } from '../../constants/icons';
import { WhatsappIcon, WhatsappShareButton } from 'react-share';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import appStoreLogo from '../../assets/image/app-store-logo.png';
import playStoreLogo from '../../assets/image/play-store-logo.png';

function Footer() {
    const { t, i18n } = useTranslation();
    const year = new Date().getFullYear();
    return (
        <footer>
            <div className="container">
                <div className="row" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="footer_box">
                            <h4>{t('routes.about')}</h4>
                            <p>{t('aboutUs.welcomeMessage')}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-2">
                        <div className="footer_box">
                            <h4>{t('common.links')}</h4>
                            <ul className="footer_menu">
                                <li>
                                    <Link to={{ pathname: `/about-us` }}>{t('routes.about')}</Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: `/courses/all` }}>{t('routes.allCourses')}</Link>
                                </li>
                                <li>
                                    <a href="/privacy-policy">{t('routes.privacyPolicy')}</a>
                                </li>
                                <li>
                                    <a href="/terms-condition">{t('routes.termsCondition')}</a>
                                </li>
                                <li>
                                    <a href="/faqs">{t('routes.faq')}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="footer_box">
                            <h4>{t('common.availableOn')}</h4>
                            <div style={{ width: '100px' }}>
                                <a href="https://apps.apple.com/us/app/dawraty-educational-platform/id6464328860">
                                    <img src={appStoreLogo} alt="App Store" />
                                </a>
                            </div>
                            <div style={{ width: '100px' }}>
                                <a href="https://play.google.com/store/apps/details?id=com.dawraty&pcampaignid=web_share">
                                    <img src={playStoreLogo} alt="Play Store" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="footer_box">
                            <h4>{t('routes.contactUs')}</h4>
                            <a href="mailto:info@joindawraty.com" className="footer_contact">
                                <figure>
                                    <img src={icons.icon_mail} alt="" />
                                </figure>
                                <span>Info@joindawraty.com</span>
                            </a>
                            <a href="https://wa.me/+96569010025" className="footer_contact">
                                <figure>
                                    <img src={icons.icon_whatsapp} alt="" />
                                    {/* <WhatsappIcon size={25} round={true} /> */}
                                </figure>
                                <span>
                                    {t('common.contactNumber', {
                                        contactNumber: '96569010025',
                                    })}
                                </span>
                            </a>
                            <a href="https://wa.me/+97333769925" className="footer_contact">
                                <figure>
                                    <img src={icons.icon_whatsapp} alt="" />
                                    {/* <WhatsappIcon size={25} round={true} /> */}
                                </figure>
                                <span>
                                    {t('common.contactNumber', {
                                        contactNumber: '97333769925',
                                    })}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer_bottom border_top" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                            <div className="copyright">{t('common.allRightsReserved', { year: year })}</div>
                            <div className="social_media" style={{ paddingRight: '10px' }}>
                                <a href="https://twitter.com/joindawraty?s=11" target="_blank">
                                    <img src={icons.icon_twitter} alt="" />
                                </a>
                                <a href="https://instagram.com/joindawraty?utm_medium=copy_link" target="_blank">
                                    <img src={icons.icon_instagram} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
