import { getCartCount, getCartDetails } from '../../../components/services/courses';
import { profileConstants } from '../constants';
import { profileService } from '../services';

export const profileActions = {
    updateProfile,
};

function updateProfile(payload, setCartCount, setCartData) {
    return (dispatch) => {
        dispatch(request());
        profileService
            .updateProfile(payload)
            .then(async (response) => {
                if (response.status === 200) {
                    dispatch(success(response.data));
                    const cartDetails = await getCartDetails();
                    const cartCount = await getCartCount();
                    console.log(cartDetails);
                    setCartCount(cartCount.data);
                    setCartData(cartDetails.data);
                } else {
                    dispatch(failure(response.data));
                }
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };

    function request(user) {
        return { type: profileConstants.REQUEST_PROFILE, user };
    }
    function success(profile) {
        return { type: profileConstants.RECIEVE_PROFILE, profile };
    }
    function failure(error) {
        return { type: profileConstants.ERROR_PROFILE, error };
    }
}
