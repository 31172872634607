import React, { useState } from 'react';
import { icons } from '../../constants/icons';
import { checkQuestion, getResult } from '../services/quiz';
import { showError, showSuccess } from '../Utils/helper';
import { t, useTranslation } from 'react-i18next';

function Quiz({ quiz, course_id }) {
    const [counter, setCounter] = React.useState(0);
    const [currentAnswer, setCurrentAnswer] = useState([]);
    const [showNext, setShowNext] = useState(false);
    const [checkCurrentAnswer, setCheckCurrentAnswer] = useState([]);
    const [finalResult, setFinalResult] = useState(false);
    const [score, setScore] = useState(0);
    const [border, setBorder] = useState('');
    const [answerClass, setAnswerClass] = useState('');
    const { t } = useTranslation();

    async function checkAnswer() {
        let payload = {
            course_id: parseInt(course_id),
            lesson_id: quiz?.id,
            lesson_quiz_id: quiz?.quiz[counter]?.id,
            answer: currentAnswer,
        };
        const resp = await checkQuestion(payload);
        setCheckCurrentAnswer(resp);
        if (resp?.success === false) {
            setBorder('incorrect_answer_border');
            setAnswerClass('incorrect_answer');
        } else {
            setBorder('correct_answer_border');
            setAnswerClass('correct_answer');
        }
        if (resp) {
            setShowNext(true);
        }
    }

    async function toGetResult() {
        const resp = await getResult(quiz?.id);
        if (resp.success) {
            setFinalResult(true);
            setScore(Math.ceil(resp?.data));
            setScore(resp?.data);
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    return (
        <>
            {!finalResult ? (
                <div className="quiz_box">
                    <span className="quiz_count">
                        Question {counter + 1} of {quiz?.quiz.length}
                    </span>
                    <div
                        className="quiz_question"
                        dangerouslySetInnerHTML={{
                            __html: quiz?.quiz[counter].question.replace(/['"]+/g, ''),
                        }}
                    />
                    <small>
                        {quiz?.quiz[counter]?.question_type !== 'one-answer'
                            ? 'Choose more than one answer'
                            : 'Choose one correct answer'}
                    </small>
                    <div className="quiz_options">
                        {quiz?.quiz[counter]?.options?.map((_answer) => {
                            return (
                                <div
                                    onClick={() => {
                                        if (quiz?.quiz[counter]?.question_type !== 'one-answer') {
                                            if (currentAnswer.includes(_answer)) {
                                                let _filterArr = currentAnswer.filter((_item) => _item !== _answer);
                                                setCurrentAnswer(_filterArr);
                                            } else {
                                                setCurrentAnswer([...currentAnswer, _answer]);
                                            }
                                        } else {
                                            setCurrentAnswer([_answer]);
                                        }
                                    }}
                                >
                                    <label className={currentAnswer.includes(_answer) && `selected ${border}`}>
                                        <input type="checkbox" />
                                        <div className="option_answer">
                                            <div className={`option_number ${answerClass}`}>{}</div>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: _answer.replace(/['"]+/g, ''),
                                                }}
                                            ></span>
                                        </div>
                                        <div className="quiz_indicator">
                                            <img src={icons.icon_circle_tick} alt="" />
                                        </div>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    {checkCurrentAnswer && checkCurrentAnswer?.success === false && (
                        <div className="quiz_answer">
                            <span className="incorrect">The Answer is incorrect</span>
                            {checkCurrentAnswer?.data?.map((_answer) => (
                                <span
                                    className="correct"
                                    dangerouslySetInnerHTML={{
                                        __html: _answer.replace(/['"]+/g, ''),
                                    }}
                                />
                            ))}
                            <br />
                            <b>{quiz?.quiz[counter]?.explanation === '<p><br></p>' ? '' : 'Explanation :'}</b>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: quiz?.quiz[counter]?.explanation.replace(/['"]+/g, ''),
                                }}
                            ></span>
                        </div>
                    )}
                    {checkCurrentAnswer?.success && (
                        <div className="quiz_answer">
                            <span className="correct">The Answer is correct</span>
                            <br />
                            <b>{quiz?.quiz[counter]?.explanation === '<p><br></p>' ? '' : 'Explanation :'}</b>
                            <br />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: quiz?.quiz[counter]?.explanation.replace(/['"]+/g, ''),
                                }}
                            ></span>
                        </div>
                    )}
                    <div className="quiz_button text-right">
                        {showNext && quiz?.quiz?.length - 1 === counter ? (
                            <>
                                <button
                                    className="btn_primary big_btn"
                                    onClick={() => {
                                        toGetResult();
                                    }}
                                >
                                    Result
                                </button>
                            </>
                        ) : showNext && quiz?.quiz?.length - 1 !== counter - 1 ? (
                            <button
                                className="btn_primary big_btn"
                                onClick={() => {
                                    setCheckCurrentAnswer(null);
                                    setCurrentAnswer([]);
                                    setShowNext(false);
                                    setCounter(counter + 1);
                                    setBorder('');
                                    setAnswerClass('');
                                }}
                            >
                                Next
                            </button>
                        ) : (
                            <button
                                className="btn_primary big_btn"
                                onClick={() => {
                                    if (currentAnswer.length === 0) {
                                        showError(t('alertMessage.selectOneOption'));
                                        return;
                                    }
                                    checkAnswer();
                                }}
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </div>
            ) : (
                <div className="quiz_box">
                    <div className="quiz_result">
                        <p>You have completed the {quiz?.name}</p>
                        <p>Your Score</p>
                        <span>{score}%</span>
                    </div>
                </div>
            )}
        </>
    );
}

export default Quiz;
