import React, { useEffect } from 'react';
import Navbar from '../navigation/Navbar';
import Footer from '../navigation/Footer';

function PageLayout(props) {
    return (
        <>
            <Navbar me={props.me} />
            {props.children}
            <Footer />
        </>
    );
}

export default PageLayout;

// We have to use this component on all the pages
