import React, { useEffect, useState, useContext } from 'react';
import StudentAllCourses from './StudentAllCourses';
import StudentWhislist from './StudentWhislist';
import InstructorsList from './InstructorsList';
import Flashcards from './Flashcards';
import Quizzes from './Quizzes';
import { userActions } from '../../containers/user/actions/me';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../Layouts/PageLayout';
import { useLocation } from 'react-router-dom';
import { icons } from '../../constants/icons';
import Loader from '../Common/Loader';
import { UserContext } from '../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CurrentYearModal from '../modals/CurrentYearModal';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';

export default function StudentMyLearning() {
    const { t, i18n } = useTranslation();
    let location = useLocation();
    const [showTab, setShowTab] = useState(true);
    const [instrcutor, setInstructor] = useState(false);
    const { loadingUser, user } = useContext(UserContext);
    const [activeTab, setActiveTab] = useState(location.search);
    const [show, setShow] = useState(false);
    const history = useHistory();

    const [openCurrentYearModal, closeCurrentYearModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <CurrentYearModal closeModal={closeCurrentYearModal} setShow={setShow} />
            </ReactModal>
        );
    });

    useEffect(() => {
        setActiveTab(location.search);
    }, [location]);

    useEffect(() => {
        if (user?.current_year === null) openCurrentYearModal();
    }, [user]);

    return (
        <React.Fragment>
            {!loadingUser ? (
                <PageLayout>
                    <section className="blue_section">
                        <div className="container">
                            <h1 className="blue_section_title mtb_50">{t('common.myDashboard')}</h1>
                            <div className="blue_section_tabs" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                                <a
                                    className={activeTab === '?courses' ? 'active' : ''}
                                    onClick={() => {
                                        setShowTab(true);
                                        setInstructor(false);
                                        history.push({
                                            pathname: '/student-learning',
                                            search: 'courses',
                                        });
                                    }}
                                    style={{
                                        color: 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {t('routes.allCourses')}
                                </a>
                                <a
                                    className={activeTab === '?wishlist' ? 'active' : ''}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/student-learning',
                                            search: 'wishlist',
                                        });
                                    }}
                                    style={{
                                        color: 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {t('common.wishlist')}
                                </a>
                                <a
                                    className={activeTab === '?instructors' ? 'active' : ''}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/student-learning',
                                            search: 'instructors',
                                        });
                                    }}
                                    style={{
                                        color: 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {t('common.myInstructors')}
                                </a>
                                {/* <a
                                    className={
                                        activeTab === "?flashcards"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => {
                                        history.push({
                                            pathname: "/student-learning",
                                            search: "flashcards",
                                        });
                                    }}
                                    style={{
                                        color: "white",
                                        cursor: "pointer",
                                    }}
                                >
                                    {t("common.flashcards")}
                                </a>
                                <a
                                    className={
                                        activeTab === "?quizzes" ? "active" : ""
                                    }
                                    onClick={() => {
                                        history.push({
                                            pathname: "/student-learning",
                                            search: "quizzes",
                                        });
                                    }}
                                    style={{
                                        color: "white",
                                        cursor: "pointer",
                                    }}
                                >
                                    {t("common.quizzes")}
                                </a> */}
                            </div>
                        </div>
                    </section>
                    {activeTab === '?courses' && <StudentAllCourses />}
                    {activeTab === '?wishlist' && <StudentWhislist />}
                    {activeTab === '?instructors' && <InstructorsList />}
                    {/* {activeTab === "?flashcards" && <Flashcards />}
                    {activeTab === "?quizzes" && <Quizzes />} */}
                </PageLayout>
            ) : (
                <Loader />
            )}
        </React.Fragment>
    );
}
