import { userConstants } from '../constant';
import { userService } from '../services';

export const loginActions = {
    login,
};

function login(username, password, redirect) {
    return (dispatch) => {
        dispatch(request({ username }));
        userService
            .login(username, password)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(success(response.data));
                    // window.location.reload();
                } else if (response.status === 424) {
                    dispatch(deviceFailure(response));
                } else {
                    dispatch(failure(response.data));
                }
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }

    function success(auth) {
        return { type: userConstants.LOGIN_SUCCESS, auth };
    }

    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }

    function deviceFailure(response) {
        return {
            type: userConstants.LOGIN_FAILURE_DEVICE,
            response,
        };
    }
}
