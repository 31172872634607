import React, { useEffect, useState } from 'react';

function Pagination({ totalPages, currentPage, goToPage }) {
    const [list, setList] = useState([]);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        if (currentPage > list[list.length - 1]) {
            if (totalPages - (currentPage - 1) > limit) {
                let list = currentPage - 1 + limit;
                let newList = [];
                for (let i = currentPage; i <= list; i++) {
                    newList.push(i);
                }
                setList(newList);
            } else {
                let list = totalPages - (currentPage - 1) + (currentPage - 1);
                let newList = [];
                for (let i = currentPage; i <= list; i++) {
                    newList.push(i);
                }
                setList(newList);
            }
        }

        if (currentPage < list[0]) {
            if (currentPage >= limit) {
                let list = currentPage - limit;
                let newList = [];
                for (let i = list + 1; i <= currentPage; i++) {
                    newList.push(i);
                }
                setList(newList);
            }
        }
    }, [currentPage, totalPages]);

    useEffect(() => {
        if (currentPage === 1 && totalPages >= limit) {
            let newList = [];
            for (let i = 1; i <= limit; i++) {
                newList.push(i);
            }
            setList(newList);
        }

        if (totalPages < limit) {
            let newList = [];
            for (let i = currentPage; i <= totalPages; i++) {
                newList.push(i);
            }
            setList(newList);
        }
    }, [totalPages, currentPage]);

    return (
        list &&
        list.map((num) => {
            return (
                <button
                    className={`pagination-btns ${num === currentPage ? 'pagination-btn-active' : ''}`}
                    key={num}
                    onClick={() => goToPage(num)}
                >
                    {num}
                </button>
            );
        })
    );
}

export default Pagination;
