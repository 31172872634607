import React, { useState, useEffect, useRef } from 'react';
import { registerActions } from '../../containers/user/actions/registerInstructor';
import { countryAction } from '../../containers/Utils/actions/country';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../../constants/message';
import { icons } from '../../constants/icons';
import { signupEmailVerificationAction } from '../../containers/user/actions/signupEmailVerification';
import { registerOTPResend } from '../../containers/user/actions/resendOtp';
import useInterval from './Counter';
import { Trans, useTranslation } from 'react-i18next';

/**
 *
 * @param {signup authentication} author by Taher
 * @returns
 */

function InstructorSignup({ closeSignup, openLogin }) {
    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const registerInstructor = useSelector((state) => state.registerInstructor);
    const resend_otp = useSelector((state) => state.resendOtp);

    const [signUpInputs, setSignUpInputs] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        cpassword: '',
        country_id: '112',
        mobile_number: '+965',
        interest: '',
        cv_file: '',
        cv_ext: '',
    });

    const [fnameError, setFnameError] = useState('');
    const [lnameError, setLnameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [pwdError, setPwdError] = useState('');
    const [cpwdError, setCpwdError] = useState('');
    const [privacyError, setPrivacyError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [cvError, setCvError] = useState('');
    const [interestError, setInterestError] = useState('');

    const { first_name, last_name, email, password, cpassword, mobile_number, cv_ext } = signUpInputs;
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [privacyToggle, setPrivacyToggle] = useState(false);
    const [passwordToggle, setPasswordToggle] = useState(false);
    const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
    const [emailOtp, setEmailOtp] = useState('');
    const [otpToggle, setOtpToggle] = useState(false);
    const [otpError, setOtpError] = useState(null);
    const countryVal = useSelector((state) => state.country);
    const emailVerification = useSelector((state) => state.emailVerification);
    const [successRegister, setSuccessRegister] = useState(false);
    const [counter, setCounter] = useState(60);
    const ref = useRef();
    const privacyRef = useRef();

    function handleLoginChange(e) {
        const { name, value } = e.target;
        setSignUpInputs((inputs) => ({ ...inputs, [name]: value }));
        if (name === 'first_name') {
            setFnameError('');
        }
        if (name === 'last_name') {
            setLnameError('');
        }
        if (name === 'email') {
            setEmailError('');
        }
        if (name === 'password') {
            setPwdError('');
        }
        if (name === 'cpassword') {
            setCpwdError('');
        }
        if (name === 'mobile_number') {
            setMobileError('');
        }
        if (name === 'interest') {
            setMobileError('');
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    function handleFileChange(e) {
        const { name, files, value } = e.target;
        let fileExt = value.split('.');
        let fileExtReverse = fileExt.reverse();
        setSignUpInputs((inputs) => ({ ...inputs, cv_ext: fileExtReverse[0] }));
        getBase64(files[0]).then((data) => {
            setSignUpInputs((inputs) => ({ ...inputs, [name]: data }));
        });
    }

    function verifyEmailOTP() {
        dispatch(signupEmailVerificationAction.emailVerify(emailOtp));
    }

    function resendOtp() {
        dispatch(registerOTPResend.sendOTPAgain(registerInstructor.register.data.user.email));
        setOtpError('');
        setEmailOtp('');
    }

    function validateInstructorSignUp() {
        let errors = {};
        let cvFormats = ['pdf', 'doc', 'docx'];

        if (first_name === '') {
            errors.first_name = t('alertMessage.enterFirstName');
        } else if (first_name.trim().length <= 2) {
            errors.first_name = messages.FNAME_ERROR;
        }

        if (last_name === '') {
            errors.last_name = t('alertMessage.enterLastName');
        } else if (last_name.trim().length <= 2) {
            errors.last_name = messages.LNAME_ERROR;
        }

        if (!email) {
            errors.email = t('alertMessage.enterEmail');
        }

        if (password === '') {
            errors.password = t('alertMessage.enterPassword');
        } else if (password.trim().length <= 5) {
            errors.password = messages.PASSWORD_SHORT;
        }

        if (cpassword === '') {
            errors.cpassword = t('alertMessage.confirmPassword');
        } else if (cpassword.trim().length <= 5) {
            errors.cpassword = messages.PASSWORD_SHORT;
        } else if (password !== cpassword) {
            errors.cpassword = messages.PASSWORD_ERROR;
        }

        if (mobile_number.length === 0) {
            errors.mobile_number = messages.MOBILE_ERROR;
        }

        if (cv_ext && !cvFormats.includes(cv_ext)) {
            errors.cv_ext = messages.FILE_ERROR;
        }

        if (!privacyToggle) {
            errors.privacy = t('alertMessage.enterPP');
        }

        return errors;
    }

    function registerInstructorDetails(event) {
        event.preventDefault();
        let mobilePattern = /^\d{10}$/;
        let cvFormats = ['pdf', 'doc', 'docx'];

        let errors = validateInstructorSignUp();

        if (Object.keys(errors).length > 0) {
            if (errors['first_name'] !== '') {
                setFnameError(errors['first_name']);
            }
            if (errors['last_name'] !== '') {
                setLnameError(errors['last_name']);
            }
            if (errors['email'] !== '') {
                setEmailError(errors['email']);
            }
            if (errors['password'] !== '') {
                setPwdError(errors['password']);
            }
            if (errors['cpassword'] !== '') {
                setCpwdError(errors['cpassword']);
            }
            if (errors['mobile_number'] !== '') {
                setMobileError(errors['mobile_number']);
            }
            if (errors['cv_ext'] !== '') {
                setCvError(errors['cv_ext']);
            }
            if (errors['privacy'] !== '') {
                setPrivacyError(errors['privacy']);
            }
        } else {
            dispatch(registerActions.registerInstructor(signUpInputs));
            dispatch(registerOTPResend.clearResendOtpState());
            setError(false);
        }
    }

    useInterval(() => {
        if (counter >= 1 && otpToggle) {
            setCounter(counter - 1);
        }
    }, 1000);

    useEffect(() => {
        if (resend_otp.status?.message === 'Otp Sended To Your Email !!') {
            setCounter(60);
        }
    }, [resend_otp.status]);

    useEffect(() => {
        if (
            !registerInstructor?.error &&
            registerInstructor?.register?.message === 'The email has already been taken.'
        ) {
            setError(true);
            setOtpToggle(false);
            setErrorMsg(t('alertMessage.emailTaken'));
        }
        if (
            !registerInstructor?.error &&
            registerInstructor?.register?.message === 'User Details Saved Successfully or Email Sended To OTP'
        ) {
            setOtpToggle(true);
        }
    }, [registerInstructor]);

    useEffect(() => {
        dispatch(countryAction.getCountry());
    }, []);

    useEffect(() => {
        if (privacyToggle) {
            setPrivacyError('');
        }
    }, [privacyToggle]);

    useEffect(() => {
        if (cv_ext) {
            setCvError('');
        }
    }, [cv_ext]);

    useEffect(() => {
        if (signUpInputs.country_id === '112') {
            setSignUpInputs({ ...signUpInputs, mobile_number: '+965' });
        } else {
            setSignUpInputs({ ...signUpInputs, mobile_number: '' });
        }
    }, [signUpInputs.country_id]);

    function goToLogin() {
        closeSignup();
        openLogin();
    }

    useEffect(() => {
        if (emailVerification?.status?.message === 'Invalid Otp' && emailVerification?.status?.status === 402) {
            setOtpError('Invalid OTP');
            return;
        }
        if (emailVerification?.status?.message === 'Email verified Successfully!' && !emailVerification?.error) {
            setOtpToggle(false);
            setEmailOtp('');
            setSignUpInputs({
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                cpassword: '',
                country_id: '112',
                mobile_number: '+965',
                interest: '',
                cv_file: '',
                cv_ext: '',
            });
            setPasswordToggle(false);
            setConfirmPasswordToggle(false);
            privacyRef.current.checked = false;
            setPrivacyToggle(false);
            ref.current.value = '';
            setSuccessRegister(true);
            return;
        }
    }, [emailVerification]);

    return (
        <div className="instructor-form">
            <h2>{t('common.signUp')}</h2>
            <div className="lrf_subtext">
                {t('common.haveAccount')}
                <a href="#" onClick={goToLogin} className="login-text">
                    {t('common.login')}
                </a>
            </div>
            <form onSubmit={registerInstructorDetails}>
                <div className="form_row">
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.firstName')}</label>
                            <div className="form_input">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="first_name"
                                    onChange={handleLoginChange}
                                    value={signUpInputs.first_name}
                                />
                                {fnameError && <p className="signup-error">{fnameError}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.lastName')}</label>
                            <div className="form_input">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="last_name"
                                    onChange={handleLoginChange}
                                    value={signUpInputs.last_name}
                                />
                                {lnameError && <p className="signup-error">{lnameError}</p>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label for="">{t('common.email')}</label>
                    <div className="form_input">
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            onChange={handleLoginChange}
                            value={signUpInputs.email}
                        />
                        {emailError && <p className="signup-error">{emailError}</p>}
                    </div>
                </div>
                <div className="form_row">
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.password')}</label>
                            <div className="form_input">
                                <input
                                    type={passwordToggle ? 'text' : 'password'}
                                    className="form-control"
                                    name="password"
                                    onChange={handleLoginChange}
                                    placeholder={t('common.password')}
                                    value={signUpInputs.password}
                                />
                                <span className="icon_eye">
                                    <img
                                        src={icons.icon_eye}
                                        alt=""
                                        onClick={() => {
                                            setPasswordToggle(!passwordToggle);
                                        }}
                                    />
                                </span>
                            </div>
                            {pwdError && <p className="signup-error">{pwdError}</p>}
                        </div>
                    </div>
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.rePassword')}</label>
                            <div className="form_input">
                                <input
                                    type={confirmPasswordToggle ? 'text' : 'password'}
                                    className="form-control"
                                    name="cpassword"
                                    onChange={handleLoginChange}
                                    value={signUpInputs.cpassword}
                                />
                                <span className="icon_eye">
                                    <img
                                        src={icons.icon_eye}
                                        alt=""
                                        onClick={() => {
                                            setConfirmPasswordToggle(!confirmPasswordToggle);
                                        }}
                                    />
                                </span>
                            </div>
                            {cpwdError && <p className="signup-error">{cpwdError}</p>}
                        </div>
                    </div>
                </div>

                <div className="form_row">
                    <div className="form_col">
                        <div className="form-group" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                            <label for="">{t('common.country')}</label>
                            <div className="form_input">
                                <select
                                    name="country_id"
                                    className="form-control country-select"
                                    onChange={handleLoginChange}
                                    value={signUpInputs.country_id}
                                >
                                    {countryVal &&
                                        countryVal.country.map((_) => {
                                            return (
                                                _.label !== 'Israel' && (
                                                    <option key={_.value} value={_.value}>
                                                        {t('countries.' + _.label, _.label)}
                                                    </option>
                                                )
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form_col">
                        <div className="form-group">
                            <label for="">{t('common.mobileNumber')}</label>
                            <div className="form_input">
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    className="form-control"
                                    name="mobile_number"
                                    value={signUpInputs.mobile_number}
                                    onChange={handleLoginChange}
                                />
                                {mobileError && <p className="signup-error">{mobileError}</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label for="">{t('common.likeToTeach')}</label>
                    <div className="form_input">
                        <input
                            type="text"
                            className="form-control"
                            name="interest"
                            placeholder={t('common.biologyPathology')}
                            onChange={handleLoginChange}
                            value={signUpInputs.interest}
                        />
                        <small className="form_below_notes">{t('common.typeInteresetNote')}</small>
                    </div>
                </div>
                <div className="form_row">
                    <div className="upload-cv">
                        <div className="form-group">
                            <label for="">{t('common.uploadCV')}</label>
                            <div className="form_input">
                                <input
                                    type="file"
                                    className="form-control"
                                    name="cv_file"
                                    ref={ref}
                                    onChange={handleFileChange}
                                />
                                {cvError && <p className="signup-error">{cvError}</p>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form_flx">
                    <div className="form_accept">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={(e) => {
                                privacyRef.current.checked = !privacyToggle;
                                setPrivacyToggle(!privacyToggle);
                            }}
                            defaultChecked={privacyToggle}
                            ref={privacyRef}
                        />
                        <label className="form-check-label" for="gridCheck">
                            <Trans i18nKey="common.termsAndPrivacy">
                                I’ve read and agree to the
                                <a href="/privacy-policy" target="_blank">
                                    {' '}
                                    Terms of Use and Privacy Policy
                                </a>
                            </Trans>
                        </label>
                        {privacyError && <p className="signup-error">{privacyError}</p>}
                    </div>
                </div>
                {error && <p className="text-danger">{errorMsg}</p>}
                {!otpToggle && (
                    <button className="btn_primary full_btn big_btn" type="submit">
                        {!registerInstructor.error && registerInstructor.loading && (
                            <span className="mr-2 spinner-border spinner-border-sm"></span>
                        )}
                        {t('common.register')}
                    </button>
                )}
            </form>

            {otpToggle && (
                <>
                    <div className="d-flex justify-content-center align-items-center">
                        {!resend_otp.loading && (
                            <p className="text-center">
                                {resend_otp.status?.message === 'Otp Sended To Your Email !!'
                                    ? 'OTP resend successfully to '
                                    : 'OTP sent to '}
                                {email}
                            </p>
                        )}

                        {!resend_otp.loading && (
                            <p
                                style={{
                                    paddingLeft: '10px',
                                    color: '#007bff',
                                    textDecoration: 'underline',
                                }}
                                className="cursor_pointer"
                                onClick={() => {
                                    setOtpToggle(false);
                                    setEmailOtp('');
                                }}
                            >
                                Change Email Address
                            </p>
                        )}
                    </div>
                    {otpError && <p className="text-center text-danger"> {otpError}</p>}
                    <div className="row">
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <div className="form-group">
                                <div className="form_input">
                                    <input
                                        type="text"
                                        value={emailOtp}
                                        className="form-control"
                                        placeholder="Please enter OTP"
                                        name="emailOtp"
                                        onChange={(e) => setEmailOtp(e.target.value)}
                                        required
                                    />
                                </div>
                                <div>Resend OTP in {`${counter}`} secs.</div>
                            </div>
                        </div>
                        <div className="col-sm-7 col-md-7 col-lg-7">
                            <button className="btn_primary big_btn instructor-vr-btn" onClick={verifyEmailOTP}>
                                {!emailVerification.error && emailVerification.loading && (
                                    <span className="mr-2 spinner-border spinner-border-sm"></span>
                                )}
                                Verify OTP
                            </button>
                            <button
                                className="btn_primary big_btn verify-btn instructor-vr-btn"
                                disabled={counter > 0}
                                onClick={resendOtp}
                            >
                                {!resend_otp.error && resend_otp.loading && (
                                    <span className="mr-2 spinner-border spinner-border-sm"></span>
                                )}
                                Resend OTP
                            </button>
                        </div>
                    </div>
                </>
            )}
            {successRegister && (
                <div className="d-flex justify-content-center align-items-center mt-3">
                    <p className="text-center text-success">Thank you for registering. Your Profile is under review.</p>
                </div>
            )}
        </div>
    );
}

export default InstructorSignup;
