import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageLayout from '../Layouts/PageLayout';
import { getInstructorCourses } from '../services/instructors';
import { showError } from '../Utils/helper';
import Loader from '../Common/Loader';
import { ar_messages, messages } from '../../constants/message';
import { useTranslation } from 'react-i18next';

const InstructorCourses = () => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    async function getMyCourses() {
        const resp = await getInstructorCourses();
        if (resp.success) {
            setData(resp.data);
        } else {
            showError(t('alertMessage.wrong'));
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        getMyCourses();
    }, []);
    return (
        <>
            <PageLayout>
                {loading ? (
                    <Loader />
                ) : (
                    <div className=" main_page_courses">
                        <section class="blue_section">
                            <div class="container">
                                <h1 class="blue_section_title mtb_50 text-center">{t('common.myCourses')}</h1>
                            </div>
                        </section>
                        {data?.length === 0 ? (
                            <h2 className="text-center p-4">
                                {i18n.language == 'ar' ? ar_messages.NO_RESULTS_FOUND : messages.NO_RESULTS_FOUND}
                            </h2>
                        ) : (
                            <div className="container pt_50">
                                <div className="row mt-3 ">
                                    {data?.map((item) => (
                                        <div className="col-md-3 col-12 mb-lg-0 mb-2" key={item?.id}>
                                            <Link
                                                to={{
                                                    pathname: '/instructor/course',
                                                    state: item?.id,
                                                }}
                                            >
                                                <div className="card ccard mb-lg-0 mb-3 ">
                                                    <img
                                                        src={process.env.REACT_APP_ASSETS + item?.course_image}
                                                        className="card-img-top"
                                                        alt="..."
                                                    />
                                                    <div className="card-body py-3 px-0 ml-3 m-0">
                                                        <h6 className="sub_heading  text-dark">{item?.name}</h6>
                                                    </div>
                                                    <div className=" d-flex justify-content-between px-3 mt-1  mb-2">
                                                        <h6 className="card_head_down_one mt-1">{item?.status}</h6>
                                                        {/* <h6 className="card_head_down_two">
                          {" "}
                          Edit Courses <GoPencil className="font-pencil mb-2" />{" "}
                        </h6> */}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </PageLayout>
        </>
    );
};

export default InstructorCourses;
