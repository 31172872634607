import React, { useState } from 'react';
import { icons } from '../../constants/icons';
import { checkQuestion, getResult } from '../services/quiz';
import { showError, showSuccess } from '../Utils/helper';
import { useTranslation } from 'react-i18next';
import getTranslationFromMany from '../../services/helper';

function Quiz({ quiz, course_id, markAsCompleted, uniqueId }) {
    const { t, i18n } = useTranslation();
    const [counter, setCounter] = React.useState(0);
    const [currentAnswer, setCurrentAnswer] = useState([]);
    const [showNext, setShowNext] = useState(false);
    const [checkCurrentAnswer, setCheckCurrentAnswer] = useState([]);
    const [finalResult, setFinalResult] = useState(false);
    const [score, setScore] = useState(0);
    const [border, setBorder] = useState('');
    const [answerClass, setAnswerClass] = useState('');
    let helperProps = {
        t,
    };
    // eslint-disable-next-line no-mixed-operators
    let arExplanation =
        (i18n.language === 'ar' &&
            quiz?.quiz[counter]?.translation[2]?.value === 'no ar translation / nor actual explanation available') ||
        'no ar translations'
            ? ''
            : quiz?.quiz[counter]?.translation[2]?.value;

    async function checkAnswer() {
        let payload = {
            course_id: parseInt(course_id),
            lesson_id: quiz?.id,
            lesson_quiz_id: quiz?.quiz[counter]?.id,
            answer: currentAnswer,
        };

        const resp = await checkQuestion(payload);
        setCheckCurrentAnswer(resp);
        if (resp?.success === false) {
            setBorder('incorrect_answer_border');
            setAnswerClass('incorrect_answer');
        } else {
            setBorder('correct_answer_border');
            setAnswerClass('correct_answer');
        }
        if (resp) {
            setShowNext(true);
        }
    }

    async function toGetResult() {
        const resp = await getResult(quiz?.id);
        if (resp.success) {
            setFinalResult(true);
            setScore(Math.ceil(resp?.data));
            markAsCompleted(quiz, helperProps);
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    return (
        <>
            {!finalResult ? (
                <div className="quiz_box" key={uniqueId} dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                    <span
                        className="quiz_count"
                        style={i18n.language == 'ar' ? { textAlign: 'right', width: '100%' } : {}}
                    >
                        {i18n.language == 'ar' ? 'سؤال ' : 'Question'} {counter + 1}{' '}
                        {i18n.language == 'ar' ? 'من' : 'Of'} {quiz?.quiz.length}
                    </span>
                    <div
                        className="quiz_question"
                        dangerouslySetInnerHTML={{
                            __html:
                                i18n.language == 'ar' && quiz?.quiz[counter].translation[0] != null
                                    ? quiz?.quiz[counter].translation[0]?.value?.replace(/['"]+/g, '')
                                    : quiz?.quiz[counter].question.replace(/['"]+/g, ''),
                        }}
                    />
                    <small style={i18n.language == 'ar' ? { textAlign: 'right', width: '100%' } : {}}>
                        {quiz?.quiz[counter]?.question_type !== 'one-answer'
                            ? i18n.language == 'ar'
                                ? 'اختر أكثر من إجابة واحدة'
                                : 'Choose more than one answer'
                            : i18n.language == 'ar'
                              ? 'اختر إجابة واحدة صحيحة'
                              : 'Choose one correct answer'}
                    </small>
                    <div className="quiz_options">
                        {quiz?.quiz[counter]?.options?.map((_answer, index) => {
                            let translatedOptions = quiz?.quiz[counter]?.translation[1]?.value
                                ? JSON.parse(quiz?.quiz[counter]?.translation[1]?.value)
                                : '';
                            return (
                                <div
                                    onClick={() => {
                                        if (quiz?.quiz[counter]?.question_type !== 'one-answer') {
                                            if (currentAnswer.includes(_answer)) {
                                                let _filterArr = currentAnswer.filter((_item) => _item !== _answer);
                                                setCurrentAnswer(_filterArr);
                                            } else {
                                                setCurrentAnswer([...currentAnswer, _answer]);
                                            }
                                        } else {
                                            setCurrentAnswer([_answer]);
                                        }
                                    }}
                                >
                                    <label className={currentAnswer.includes(_answer) && `selected ${border}`}>
                                        <input type="checkbox" disabled={showNext} />
                                        <div className="option_answer">
                                            <div className={`option_number ${answerClass}`}>{}</div>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        i18n.language == 'ar' &&
                                                        quiz?.quiz[counter].translation[0] != null
                                                            ? translatedOptions[index]?.replace(/['"]+/g, '')
                                                            : _answer.replace(/['"]+/g, ''),
                                                }}
                                            ></span>
                                        </div>
                                        <div className="quiz_indicator">
                                            <img src={icons.icon_circle_tick} alt="" />
                                        </div>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    {checkCurrentAnswer && checkCurrentAnswer?.success === false && (
                        <div className="quiz_answer">
                            <span className={i18n.language == 'ar' ? 'incorrect_rtl' : 'incorrect'}>
                                {i18n.language == 'ar' ? 'الجواب غير صحيح' : 'The Answer is incorrect'}
                            </span>
                            <br />
                            <b>{i18n.language == 'ar' ? '' : 'The Correct Answer is :'} </b>
                            <br />
                            {checkCurrentAnswer?.data?.map((_answer, index) => (
                                <span
                                    className={i18n.language == 'ar' ? 'correct_rtl' : 'correct'}
                                    dangerouslySetInnerHTML={{
                                        __html: i18n.language == 'ar' ? '' : _answer.replace(/['"]+/g, ''),
                                    }}
                                />
                            ))}
                            <br />
                            <b className={i18n.language == 'ar' ? 'bold_rtl' : ''}>
                                {quiz?.quiz[counter]?.explanation === '<p><br></p>'
                                    ? ''
                                    : i18n.language == 'ar'
                                      ? 'توضيح :'
                                      : 'Explanation :'}
                            </b>
                            <br />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: arExplanation.replace(/['"]+/g, ''),
                                }}
                            ></span>
                        </div>
                    )}
                    {checkCurrentAnswer?.success && (
                        <div className="quiz_answer">
                            <span className={i18n.language == 'ar' ? 'correct_rtl' : 'correct'}>
                                {t('alertMessage.correctAnswer')}
                            </span>
                            <br />
                            <b className={i18n.language == 'ar' ? 'bold_rtl' : ''}>
                                {quiz?.quiz[counter]?.explanation === '<p><br></p>'
                                    ? ''
                                    : i18n.language == 'ar'
                                      ? 'توضيح :'
                                      : 'Explanation :'}
                            </b>
                            <br />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: arExplanation.replace(/['"]+/g, ''),
                                }}
                            ></span>
                        </div>
                    )}
                    <div className="quiz_button text-right">
                        {showNext && quiz?.quiz?.length - 1 === counter ? (
                            <>
                                <button
                                    className="btn_primary big_btn"
                                    onClick={() => {
                                        toGetResult();
                                    }}
                                >
                                    {i18n.language == 'ar' ? 'نتيجة' : 'Result'}
                                </button>
                            </>
                        ) : showNext && quiz?.quiz?.length - 1 !== counter - 1 ? (
                            <button
                                className="btn_primary big_btn"
                                onClick={() => {
                                    setCheckCurrentAnswer(null);
                                    setCurrentAnswer([]);
                                    setShowNext(false);
                                    setCounter(counter + 1);
                                    setBorder('');
                                    setAnswerClass('');
                                }}
                            >
                                {i18n.language == 'ar' ? 'التالي' : 'Next'}
                            </button>
                        ) : (
                            <button
                                className="btn_primary big_btn"
                                onClick={() => {
                                    if (currentAnswer.length === 0) {
                                        showError(t('alertMessage.selectOneOption'));
                                        return;
                                    }
                                    checkAnswer();
                                }}
                            >
                                {i18n.language == 'ar' ? 'اجب' : 'Submit'}
                            </button>
                        )}
                    </div>
                </div>
            ) : (
                <div className="quiz_box">
                    <div className="quiz_result">
                        <p>
                            {i18n.language == 'ar' ? 'لقد أكملت' : 'You have completed the'}{' '}
                            {i18n.language == 'ar' ? quiz?.translation?.value : quiz?.name}
                        </p>
                        <p>{i18n.language == 'ar' ? 'درجاتك' : 'Your Score'}</p>
                        <span>{score}%</span>
                    </div>
                </div>
            )}
        </>
    );
}

export default Quiz;
