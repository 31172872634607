import React, { useContext } from 'react';
import HomeComponent from '../HomeComponents/Home';
import InstructorHome from '../HomeComponents/InstructorHome';
import PageLayout from '../Layouts/PageLayout';
import Loader from '../Common/Loader';
import { UserContext } from '../context/AuthContext';

function Home() {
    const { user, loadingUser, category } = useContext(UserContext);
    function getPage() {
        if (user?.type === 'instructor') {
            return <InstructorHome />;
        } else {
            return <HomeComponent category={category} />;
        }
    }
    return <>{!loadingUser ? <PageLayout me={user}>{getPage()}</PageLayout> : <Loader />}</>;
}
export default Home;
