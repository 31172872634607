import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import { showError, utcToLocalDateTime } from '../Utils/helper';
import { getIncomeHistory, getRecentOrders } from '../services/instructors';
import Loader from '../Common/Loader';
import { icons } from '../../constants/icons';
import { Spinner } from '../Utils/helper';
import { ar_messages, messages } from '../../constants/message';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { FaFilter } from 'react-icons/fa';
import { dateFormat } from '../services/utils';
import { UserContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';

function InstructorView() {
    const { t, i18n } = useTranslation();
    const { user } = useContext(UserContext);
    const [data, setData] = useState(null);
    const [orders, setOrders] = useState(null);
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [datePickerShow, setDatePickerShow] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    async function toGetIncomeHistory(query) {
        const resp = await getIncomeHistory(query);
        if (resp.success) {
            setData(resp.data);
        } else {
            showError(t('alertMessage.wrong'));
        }
        setLoading(false);
    }

    async function togetRecentOrders(query) {
        setSpinner(true);
        const resp = await getRecentOrders(query);
        if (resp.success) {
            setOrders(resp?.data);
            setCurrentPage(resp?.data?.current_page);
            setLastPage(resp?.data?.last_page);
        } else {
            showError(t('alertMessage.wrong'));
        }
        setLoading(false);
        setSpinner(false);
    }

    useEffect(() => {
        setLoading(true);
        toGetIncomeHistory('');
        togetRecentOrders('');
    }, []);

    function getAmount() {
        let total = 0;
        data?.map((_income) => {
            total += _income?.month_amount;
        });
        return total;
    }

    function pagination() {
        let _page = [];
        for (let i = 1; i <= orders?.last_page; i++) {
            _page.push(i);
        }
        return (
            <>
                {_page?.map((_p) => (
                    <li
                        className={`page-item ${_p === currentPage && 'active'} cursor_pointer`}
                        onClick={() =>
                            togetRecentOrders(
                                `?page=${_p}&startDate=${dateFormat(dateRange.startDate)}&endDate=${dateFormat(dateRange.endDate)}`
                            )
                        }
                    >
                        <a className="page-link">{_p}</a>
                    </li>
                ))}
            </>
        );
    }

    function handleSelect(ranges) {
        setDateRange({
            ...dateRange,
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
        });
        togetRecentOrders(
            `?page=${currentPage}&startDate=${dateFormat(ranges.selection.startDate)}&endDate=${dateFormat(ranges.selection.endDate)}`
        );
    }
    return (
        <div className="container">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="head-wrap">
                        {/* <div className="head-text">Income History</div>
          </div>
          <div className="income">
            <div className="income-table">
              <div className="income-header">
                <div className="income-row">Year</div>
                <div className="income-row">Month</div>
                <div className="income-row">Amount</div>
                {
                  user?.show_commission === 1 ? (
                  <div className="income-row">Commission %</div>
                  ) : null
                }
              </div>
              {data?.length === 0 ? (
                <h2 className="p-4 text-center">{i18n.language == 'ar' ? ar_messages.NO_RESULTS_FOUND : messages.NO_RESULTS_FOUND}</h2>
              ) : (
                <>
                  {data?.map((_income) => (
                    <div className="income-data">
                      <div className="income-row">{_income?.year}</div>
                      <div className="income-row">{_income?.month}</div>
                      <div className="income-row">
                        KD {_income?.month_amount}
                      </div>
                      {
                        user?.show_commission === 1 ? (
                          <div className="income-row">
                            {_income?.commission_percent} %
                          </div>
                        ) : null
                      } 
                    </div>
                  ))}
                </>
              )}
            </div> 

            <div className="card1 card1-inst">
              <div className="card1-text">Total Revenue</div>
              <div>
                <img
                  src={icons.icon_calculator}
                  alt=""
                  className="card1-icon"
                />
              </div>
              <div className="card1-value">KD {getAmount()}</div>
            </div>*/}
                    </div>
                    <div className="table-inst mt_50 ">
                        <div className="table-headText table-instructor pl-0">
                            Recent Order
                            <div className="filter_btn float-right">
                                <button
                                    className="btn_secondary big_btn"
                                    onClick={() => {
                                        setDatePickerShow(!datePickerShow);
                                    }}
                                >
                                    <div className="btn_icon">
                                        <FaFilter size="medium" />
                                    </div>
                                </button>
                                {datePickerShow ? (
                                    <DateRangePicker
                                        className="dateAickerAbs"
                                        ranges={[dateRange]}
                                        onChange={handleSelect}
                                    />
                                ) : null}
                            </div>
                        </div>
                        <table className="table table-bordered table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    {/* <th scope="col">Amount</th> */}
                                    <th scope="col">Course</th>
                                </tr>
                            </thead>
                            {orders?.data?.length === 0 ? (
                                <h2 className="text-center p-4">
                                    {i18n.language == 'ar' ? ar_messages.NO_RESULTS_FOUND : messages.NO_RESULTS_FOUND}
                                </h2>
                            ) : (
                                <tbody>
                                    {orders?.data?.map((_item) => (
                                        <tr>
                                            <td>{utcToLocalDateTime(_item?.created_at)}</td>
                                            {/* <td>KD {_item?.amount}</td> */}
                                            <td>{_item?.course_details?.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                        {orders?.data?.length > 0 &&
                            (spinner ? <Spinner /> : <ul className="pagination pagination-md">{pagination()}</ul>)}
                    </div>
                </>
            )}
        </div>
    );
}

export default InstructorView;
