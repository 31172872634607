import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { icons } from '../../constants/icons';
import { getWishlist, deleteWishlist } from '../services/courses/index';
import { showError, showSuccess, Spinner } from '../Utils/helper';
import { useHistory } from 'react-router-dom';
import { ar_messages, messages } from '../../constants/message';
import { t, useTranslation } from 'react-i18next';
import getTranslationFromMany from '../../services/helper';

export default function StudentWhislist() {
    const { t, i18n } = useTranslation();
    const [wishlist, setWishlist] = useState([]);
    const [searchCourseArray, setSearchCourseArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    async function wishlistData() {
        setLoading(true);
        const resp = await getWishlist();
        if (resp.success) {
            setWishlist(resp.data);
            setSearchCourseArray(resp.data);
            setLoading(false);
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    useEffect(() => {
        wishlistData();
    }, []);

    async function removeWishList(id) {
        const resp = await deleteWishlist(id);
        if (resp.success) {
            showSuccess(t('alertMessage.removedFromWishList'));
            let result = wishlist.filter((item) => item.course_id !== id);
            setWishlist(result);
        } else {
            showError(t('alertMessage.wrong'));
        }
    }
    function redirect(id) {
        history.push(`/course-details/${id}`);
    }
    function searchCourse(search) {
        let filterArr = searchCourseArray?.filter((_search) => _search?.courses?.name.toLowerCase().includes(search));
        setWishlist(filterArr);
    }
    return (
        <React.Fragment>
            <section className="pt_100 pb_50" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                <div className="container">
                    <div className="course_search mb_30">
                        <div className="search">
                            <form action="">
                                <div className="search_input" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                                    <div className="search_magnify">
                                        <img src={icons.icon_magnify} alt="" />
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('common.search')}
                                        onChange={(e) => {
                                            searchCourse(e.target.value);
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                    {!loading ? (
                        <div className="row">
                            {wishlist.length === 0 ? (
                                <h2 className="p-4 text-center">
                                    {i18n.language == 'ar' ? ar_messages.NO_RESULTS_FOUND : messages.NO_RESULTS_FOUND}
                                </h2>
                            ) : (
                                <>
                                    {wishlist?.map((wishlist) => {
                                        let tutor =
                                            wishlist?.courses?.instructor?.first_name +
                                            ' ' +
                                            wishlist?.courses?.instructor?.last_name;
                                        let price =
                                            wishlist?.courses?.is_free === 0 ? wishlist?.courses?.course_sale : 'Free';
                                        let isFree = !!wishlist?.courses?.is_free;
                                        return (
                                            <div className="col-12 col-md-3" key={wishlist?.course_id}>
                                                <div className="course_box student_course mtb_15">
                                                    <div
                                                        className="course_thumbnail"
                                                        onClick={() => redirect(wishlist?.course_id)}
                                                    >
                                                        <img
                                                            src={
                                                                wishlist?.courses?.course_image
                                                                    ? process.env.REACT_APP_ASSETS +
                                                                      wishlist?.courses?.course_image
                                                                    : icons.course_thumbnail
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="student_course_inner">
                                                        <h2 className="course_box_title">
                                                            {t('dynamic', {
                                                                en: wishlist?.courses?.name,
                                                                ar: getTranslationFromMany(
                                                                    wishlist?.courses?.translation,
                                                                    'name',
                                                                    wishlist?.courses?.name
                                                                ),
                                                            })}
                                                        </h2>
                                                        <div className="course_box_subtitle">
                                                            {tutor ? t('courseAuthors.' + tutor, tutor) : ''}
                                                        </div>
                                                        <div className="price_wishlist">
                                                            {isFree ? (
                                                                <div className="wishprice">{t('common.free')}</div>
                                                            ) : !price?.on_sale ? (
                                                                wishlist?.courses?.country_id === 112 ? (
                                                                    <div className="wishprice">
                                                                        {t('course.price_in_kd', {
                                                                            price: price?.old_price,
                                                                        })}
                                                                    </div>
                                                                ) : (
                                                                    <div className="wishprice">
                                                                        {t('course.price_in_bd', {
                                                                            price: price?.old_price,
                                                                        })}
                                                                    </div>
                                                                )
                                                            ) : wishlist?.courses?.country_id === 112 ? (
                                                                <>
                                                                    <div className="wishprice">
                                                                        {t('course.price_in_kd', {
                                                                            price: price?.new_price,
                                                                        })}
                                                                    </div>
                                                                    <div className="wishprice price_strike">
                                                                        {t('course.price_in_kd', {
                                                                            price: price?.old_price,
                                                                        })}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="wishprice">
                                                                        {t('course.price_in_bd', {
                                                                            price: price?.new_price,
                                                                        })}
                                                                    </div>
                                                                    <div className="wishprice price_strike">
                                                                        {t('course.price_in_bd', {
                                                                            price: price?.old_price,
                                                                        })}
                                                                    </div>
                                                                </>
                                                            )}
                                                            <a
                                                                href="#"
                                                                className="wishheart"
                                                                onClick={() => removeWishList(wishlist?.course_id)}
                                                            >
                                                                <img src={icons.icon_heart_blue_filled} alt="" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </div>
            </section>
            <ToastContainer />
        </React.Fragment>
    );
}

{
    /* <div className="no_data plr_20">No Data Found</div>; */
}
