import { useContext } from 'react';
import { UserContext } from '../context/AuthContext';
import PageLayout from '../Layouts/PageLayout';
import Loader from '../Common/Loader';

function Faqs() {
    const { loadingUser } = useContext(UserContext);
    return (
        <>
            {!loadingUser ? (
                <PageLayout>
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="privacy-section">
                                    <h1 className="text-center text-underline">FAQ</h1>
                                    <h2 className="text-center">Dawraty Educational Group</h2>
                                    <ol type="A">
                                        <li>
                                            <h6 className="text-underline">FAQ's </h6>
                                            <ol type="1">
                                                <li>
                                                    <h6 className="text-underline">
                                                        What does Dawraty Educational Group offer?
                                                    </h6>
                                                    <p>
                                                        <span>
                                                            Dawraty Educational Group provides a platform for accessing
                                                            educational courses across various disciplines, initially
                                                            focusing on medical courses with plans to expand into
                                                            pharmacy, mathematics, and allied health fields.
                                                        </span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <h6 className="text-underline">
                                                        How can I use Dawraty's services?
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        To use Dawraty's services, including accessing courses and
                                                        content, you must agree to our Terms and Conditions and Privacy
                                                        Policy. By accessing the website or any Dawraty service, you
                                                        accept these terms.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">
                                                        Can I become an instructor on Dawraty?
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        Yes, you can apply to become an instructor through our "Become
                                                        an Instructor" option. We welcome qualified educators to
                                                        contribute to our diverse course offerings.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">How do I enroll in courses?</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        To enroll in a course, you need to create an account on Dawraty.
                                                        Once registered, you can browse available courses, select the
                                                        ones that interest you, and proceed with payment to gain access.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">
                                                        What are the payment terms for courses?
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        All course payments must be made in full before accessing the
                                                        course content. We offer refunds within seven (7) days of
                                                        purchase, subject to our discretion. Credits may also be issued
                                                        for future course enrollments.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">
                                                        How long do I have access to course materials?
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        Course access durations vary. Some courses offer lifetime
                                                        access, while others provide access for a limited period. Please
                                                        review the specific course details for more information.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">
                                                        What are my responsibilities as a user of Dawraty?
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        You are responsible for maintaining the confidentiality of your
                                                        account information. Sharing account details or using the
                                                        platform for unauthorized purposes violates our Terms and
                                                        Conditions and may result in account suspension.
                                                    </span>
                                                </p>

                                                <li>
                                                    <h6 className="text-underline">
                                                        Can I share my account with others?
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        No, sharing your Dawraty account information with others is
                                                        prohibited and constitutes a violation of our Terms and
                                                        Conditions.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">
                                                        What should I do if I encounter technical issues or have further
                                                        questions?
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        For technical support or any inquiries regarding our services,
                                                        please contact us at info@joindawraty.com. We are here to assist
                                                        you.
                                                    </span>
                                                </p>

                                                <li>
                                                    <h6 className="text-underline">
                                                        How can I report copyright infringement?
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        If you believe that any content on Dawraty violates your
                                                        copyright, please notify us at info@joindawraty.com with the
                                                        necessary details.
                                                    </span>
                                                    <span>Interactive Services</span>
                                                </p>
                                            </ol>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageLayout>
            ) : (
                <Loader />
            )}
        </>
    );
}
export default Faqs;
