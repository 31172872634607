import { userConstants } from '../constant';
import { userService } from '../services';

export const registerActions = {
    register,
};

function register(payload) {
    return (dispatch) => {
        dispatch(request());
        return userService
            .registerStudent(payload)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(success(response.data));
                    if (response.data.message !== 'The email has already been taken.') {
                        // window.location.reload();
                    }
                    // console.log(response.data);
                }
                dispatch(failure(response));
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };

    function request(user) {
        return { type: userConstants.REGISTER_STUDENT_REQUEST, user };
    }
    function success(auth) {
        return { type: userConstants.REGISTER_STUDENT_SUCCESS, auth };
    }
    function failure(error) {
        return { type: userConstants.REGISTER_STUDENT_FAILURE, error };
    }
}
