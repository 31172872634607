import React, { useContext } from 'react';
import PageLayout from '../Layouts/PageLayout';
import Loader from '../Common/Loader';
import { UserContext } from '../context/AuthContext';

function PrivacyPolicy() {
    const { loadingUser } = useContext(UserContext);
    return (
        <>
            {!loadingUser ? (
                <PageLayout>
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="privacy-section">
                                    <h1 className="text-center text-underline">PRIVACY POLICY</h1>
                                    <h2 className="text-center">Dawraty Educational Group</h2>
                                    <p>
                                        Dawraty Educational Group (&ldquo;Dawraty&rdquo;, &ldquo;we&rdquo;,
                                        &ldquo;us&rdquo;, or &ldquo;ours&rdquo;) is committed to protecting your
                                        privacy. We have prepared this Privacy Policy (or &ldquo;Policy&rdquo;) to
                                        describe our practices regarding our collection and use of the personal
                                        information of individuals who interact with our website (referred to as our
                                        &ldquo;Site&rdquo; or &ldquo;Website&rdquo;). This Policy provides information
                                        regarding the type of information we collect, and what we may do with that
                                        information. It also describes with whom we share it, and how we keep it secure.
                                    </p>
                                    <p>
                                        The services that we provide and to which this Policy applies include online
                                        services such as email newsletters and online content (together, the
                                        &ldquo;Services&rdquo;).
                                    </p>
                                    <p>
                                        You ensure that you are authorized and have the right to share your child&#39;s
                                        information on this website for the benefit of the child&rsquo;s education. In
                                        the event you are found to be unauthorized to share the child&rsquo;s
                                        information, Dawraty reserves the right to terminate this Agreement including
                                        the T&amp;Cs and report you to the appropriate authorities.
                                    </p>
                                    <p>This policy applies to information we collect: </p>
                                    <ul>
                                        <li>
                                            <span>On this Website.</span>
                                        </li>
                                        <li>
                                            <span>
                                                In email, text, and other electronic messages between you and this
                                                Website.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Through mobile and desktop applications you download from this Website,
                                                which provide dedicated non-browser-based interaction between you and
                                                this Website.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                When you interact with our advertising and applications on third-party
                                                websites and services, if those applications or advertising include
                                                links to this policy.
                                            </span>
                                        </li>
                                    </ul>
                                    <p>It does not apply to information collected by:</p>
                                    <ul>
                                        <li>
                                            <span>
                                                Use offline or through any other means, including on any other website
                                                operated by us or any third party; or{' '}
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Any third party, including through any application or content (including
                                                advertising) that may link to or be accessible from the Website.
                                            </span>
                                        </li>
                                    </ul>
                                    <p>
                                        Please read this policy carefully to understand our policies and practices
                                        regarding your information and how we will treat it. If you do not agree with
                                        our policies and practices, your choice is not to use our Website. By accessing
                                        or using this Website, you agree to this privacy policy. This policy may change
                                        from time to time at which point you will be asked to consent to any changes or
                                        amendments.{' '}
                                    </p>
                                    <h6 className="text-underline">Children Under the Age of 18</h6>
                                    <p>
                                        Our Website is not intended for children under 18 years of age. No one under age
                                        18 may provide any personal information to the Website. We do not knowingly
                                        collect personal information from children under 18. If you are under 18, do not
                                        use or provide any information on this Website or through any of its features,
                                        register on the Website, make any purchases through the Website, use any of the
                                        interactive or public comment features of this Website, or provide any
                                        information about yourself to us, including your name, address, telephone
                                        number, email address, or any screen name or user name you may use. If we learn
                                        we have collected or received personal information from a child under 18 without
                                        verification of parental consent, we will delete that information. If you
                                        believe we might have any information from or about a child under 18 without the
                                        consent of a parent or legal guardian, please contact us at info@joindawraty.com
                                    </p>
                                    <h6 className="text-underline">
                                        Information We Collect About You and How We Collect It
                                    </h6>
                                    <p>
                                        We collect several types of information from and about users of our Website,
                                        including information:
                                    </p>
                                    <ul>
                                        <li>
                                            <span>
                                                By which you may be personally identified, such as name, physical
                                                address, e-mail address, telephone number, personal identification
                                                number or any other identifier by which you may be contacted online or
                                                offline (&quot;
                                            </span>
                                            <strong>personal information</strong>
                                            <span>&quot;);</span>
                                        </li>
                                        <li>
                                            <span>
                                                That is about you but individually does not identify you, such as
                                                avatars, nicknames, usernames; and/or
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                About your internet connection, the equipment you use to access our
                                                Website, and usage details.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                If applying to for an education loan through our Website, we may also
                                                collect information and documentation that would necessary for our third
                                                party lenders in order to assess your qualification for a loan. This
                                                information would be uploaded and provided by you&mdash;it would not be
                                                collected automatically or without your consent.{' '}
                                            </span>
                                        </li>
                                    </ul>
                                    <p>We collect this information:</p>
                                    <ul>
                                        <li>
                                            <span>Directly from you when you provide it to us.</span>
                                        </li>
                                        <li>
                                            <span>
                                                Automatically as you navigate through the site. Information collected
                                                automatically may include usage details, IP addresses, and information
                                                collected through cookies, web beacons, and other tracking technologies.
                                            </span>
                                        </li>
                                        <li>
                                            <span>From third parties, for example, our business partners.</span>
                                        </li>
                                    </ul>
                                    <p className="text-underline">Information You Provide to Us</p>
                                    <p>The information we collect on or through our Website may include:</p>
                                    <ul>
                                        <li>
                                            <span>
                                                Information that you provide by filling in forms on our Website. This
                                                includes information provided at the time of registering to use our
                                                Website, subscribing to our service, posting material, or requesting
                                                further services. We may also ask you for information when you enter a
                                                promotion sponsored by us, and when you report a problem with our
                                                Website.
                                            </span>
                                        </li>
                                        <li>
                                            <span>Information regarding your child&rsquo;s academics</span>
                                        </li>
                                        <li>
                                            <span>
                                                Records and copies of your correspondence (including email addresses) if
                                                you contact us.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Details of transactions you carry out through our Website and of the
                                                fulfillment services requested.{' '}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Your search queries on the Website.</span>
                                        </li>
                                    </ul>
                                    <p className="text-underline">
                                        Information We Collect Through Automatic Data Collection Technologies
                                    </p>
                                    <p>
                                        As you navigate through and interact with our Website, we may use automatic data
                                        collection technologies to collect certain information about your equipment,
                                        browsing actions, and patterns, including:
                                    </p>
                                    <ul>
                                        <li>
                                            <span>
                                                Details of your visits to our Website, including traffic data, location
                                                data, logs, and other communication data and the resources that you
                                                access and use on the Website to tailor your experience and better meet
                                                your needs.{' '}
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                Information about your computer and internet connection, including your
                                                IP address, operating system, and browser type.
                                            </span>
                                        </li>
                                    </ul>
                                    <p>
                                        The information we collect automatically may include personal information. We
                                        may maintain it or associate it with personal information we collect in other
                                        ways or receive from third parties. It helps us to improve our Website and to
                                        deliver a better and more personalized service, including by enabling us to:
                                    </p>
                                    <ul>
                                        <li>
                                            <span>Estimate our audience size and usage patterns.</span>
                                        </li>
                                        <li>
                                            <span>
                                                Store information about your preferences, allowing us to customize our
                                                Website according to your individual interests.
                                            </span>
                                        </li>
                                        <li>
                                            <span>Speed up your searches.</span>
                                        </li>
                                        <li>
                                            <span>Recognize you when you return to our Website.</span>
                                        </li>
                                    </ul>
                                    <p>The technologies we use for this automatic data collection may include:</p>
                                    <ul>
                                        <li>
                                            <strong>Cookies (or browser cookies).</strong>
                                            <span>
                                                A cookie is a small file placed on the hard drive of your computer. You
                                                may refuse to accept browser cookies by activating the appropriate
                                                setting on your browser. However, if you select this setting you may be
                                                unable to access certain parts of our Website. Unless you have adjusted
                                                your browser setting so that it will refuse cookies, our system will
                                                issue cookies when you direct your browser to our Website.{' '}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>Flash Cookies.</strong>
                                            <span>
                                                Certain features of our Website may use local stored objects (or Flash
                                                cookies) to collect and store information about your preferences and
                                                navigation to, from, and on our Website. Flash cookies are not managed
                                                by the same browser settings as are used for browser cookies.{' '}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>Web Beacons.</strong>
                                            <span>
                                                Pages of our the Website and our e-mails may contain small electronic
                                                files known as web beacons (also referred to as clear gifs, pixel tags,
                                                and single-pixel gifs) that permit the Company, for example, to count
                                                users who have visited those pages or opened an email and for other
                                                related website statistics (for example, recording the popularity of
                                                certain website content and verifying system and server integrity).{' '}
                                            </span>
                                        </li>
                                    </ul>
                                    <p>
                                        We do not collect personal information automatically, but we may tie this
                                        information to personal information about you that we collect from other sources
                                        or you provide to us.
                                    </p>
                                    <h6 className="text-underline">
                                        Third-Party Use of Cookies and Other Tracking Technologies
                                    </h6>
                                    <p>
                                        Some content or applications, including advertisements, on the Website are
                                        served by third-parties, including advertisers, ad networks and servers, content
                                        providers, and application providers. These third parties may use cookies alone
                                        or in conjunction with web beacons or other tracking technologies to collect
                                        information about you when you use our website. The information they collect may
                                        be associated with your personal information or they may collect information,
                                        including personal information, about your online activities over time and
                                        across different websites and other online services. They may use this
                                        information to provide you with interest-based (behavioral) advertising or other
                                        targeted content.{' '}
                                    </p>
                                    <p>
                                        We do not control these third parties&#39; tracking technologies or how they may
                                        be used. If you have any questions about an advertisement or other targeted
                                        content, you should contact the responsible provider directly.{' '}
                                    </p>
                                    <h6 className="text-underline">How We Use Your Information</h6>
                                    <p>
                                        We use information that we collect about you or that you provide to us,
                                        including any personal information:
                                    </p>
                                    <ul>
                                        <li>
                                            <span>To present our Website and its contents to you.</span>
                                        </li>
                                        <li>
                                            <span>
                                                To provide you with information, products, or services that you request
                                                from us.
                                            </span>
                                        </li>
                                        <li>
                                            <span>To fulfill any other purpose for which you provide it.</span>
                                        </li>
                                        <li>
                                            <span>
                                                To provide you with notices about your account, including expiration and
                                                renewal notices.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                To carry out our obligations and enforce our rights arising from any
                                                contracts entered into between you and us.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                To notify you about changes to our Website or any products or services
                                                we offer or provide though it.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                To allow you to participate in interactive features on our Website.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                In any other way we may describe when you provide the information.
                                            </span>
                                        </li>
                                        <li>
                                            <span>For any other purpose with your consent.</span>
                                        </li>
                                    </ul>
                                    <p>
                                        You may &ldquo;opt out&rdquo; of any future sharing of your Dawraty account
                                        information from our database at any time in accordance with the process
                                        described below; however, a Dawraty representative may have saved a copy of your
                                        account and account-related information from before you opted out.
                                    </p>
                                    <p>
                                        In addition, we process your personal information for the following purposes: to
                                        respond to any questions or comments that you submitted to us on our Site or
                                        otherwise; to carry out any obligations entered into between us, such as
                                        providing you with Services that you have requested; to notify you of changes to
                                        our Services; to seek your views or comments on the Services we provide and send
                                        you communications by post, e-mail or text message which you have requested or
                                        that may be of interest to you, including newsletters, or promotions of our
                                        Services or events; to obtain or maintain insurance coverage, manage risks or
                                        obtain professional advice and defend our rights and establish, exercise and
                                        defend our legal claims; to facilitate your use of your web account on the Site;
                                        or to otherwise provide, create and maintain a trusted online environment and
                                        comply with our legal obligations.
                                    </p>
                                    <p>
                                        We may also use your information to contact you about our own and
                                        third-parties&#39; goods and services that may be of interest to you.
                                    </p>
                                    <p>
                                        We may use the information we have collected from you to enable us to display
                                        advertisements to our advertisers&#39; target audiences. Even though we do not
                                        disclose your personal information for these purposes without your consent, if
                                        you click on or otherwise interact with an advertisement, the advertiser may
                                        assume that you meet its target criteria.
                                    </p>
                                    <h6 className="text-underline">Disclosure of Your Information</h6>
                                    <p>
                                        We may disclose aggregated information about our users, and information that
                                        does not identify any individual, without restriction (i.e., information that
                                        does not personally identify you directly) or statistical information about you,
                                        including statistical data and historical use data, with others for a variety of
                                        purposes, including for their own uses, for example, for improving their
                                        services for you and others.
                                    </p>
                                    <p>
                                        We may disclose personal information that we collect or you provide as described
                                        in this privacy policy:
                                    </p>
                                    <ul>
                                        <li>
                                            <span>To our subsidiaries and affiliates.</span>
                                        </li>
                                        <li>
                                            <span>
                                                To contractors, service providers, and other third parties we use to
                                                support our business and who are bound by contractual obligations to
                                                keep personal information confidential and use it only for the purposes
                                                for which we disclose it to them.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                To a buyer or other successor in the event of a merger, divestiture,
                                                restructuring, reorganization, dissolution, or other sale or transfer of
                                                some or all of the Company&#39;s assets, whether as a going concern or
                                                as part of bankruptcy, liquidation, or similar proceeding, in which
                                                personal information held by the Company about our Website users is
                                                among the assets transferred.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                To third parties to market their products or services to you if you have
                                                consented to these disclosures. We contractually require these third
                                                parties to keep personal information confidential and use it only for
                                                the purposes for which we disclose it to them.{' '}
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                For any other purpose disclosed by us when you provide the information.
                                            </span>
                                        </li>
                                        <li>
                                            <span>With your consent.</span>
                                        </li>
                                    </ul>
                                    <p>We may also disclose your personal information:</p>
                                    <ul>
                                        <li>
                                            <span>
                                                To comply with any court order, law, or legal process, including to
                                                respond to any government or regulatory request.
                                            </span>
                                        </li>
                                        <li>
                                            <span>To enforce or apply our terms and conditions </span>
                                            <span>[INSERT AS LINK TO DAWRATY TERMS AND CONDITIONS]</span>
                                            <span>or and other agreements if applicable.</span>
                                        </li>
                                        <li>
                                            <span>
                                                If we believe disclosure is necessary or appropriate to protect the
                                                rights, property, or safety of the Company, our customers, or others.
                                                This includes exchanging information with other companies and
                                                organizations for the purposes of fraud protection and credit risk
                                                reduction.
                                            </span>
                                        </li>
                                    </ul>
                                    <p>
                                        We may provide links to other websites, such as social networking sites (e.g.,
                                        Instagram and YouTube), or our third party partner websites (such as schools and
                                        financing companies). These other websites may provide links to our Website.
                                        Third party sites operate according to their own terms of use and privacy
                                        policies and Dawraty has no control over these third party websites. If you wish
                                        to opt-out of any information sharing conducted by any third party website that
                                        you link to through us, or to us through them, you must do so in accordance with
                                        their specific opt-out policies and procedures. By using our Website and
                                        Services, you acknowledge and agree that we are not responsible for the
                                        availability of such third-party sites, and do not endorse and are not
                                        responsible or liable for any content, advertising, products or other materials
                                        on or available from such sites. You further acknowledge and agree that Dawraty
                                        will not be responsible or liable, directly or indirectly, for any damage or
                                        loss caused or alleged to be caused by or in connection with use of or reliance
                                        on any content, advertising, products, or other materials on or available from
                                        such sites.
                                    </p>
                                    <p>
                                        Notwithstanding anything herein to the contrary, we reserve the right to
                                        disclose any personally identifiable or non-personally identifiable information
                                        about you if we are required to do so by law, with respect to copyright and
                                        other intellectual property infringement claims, or if we believe that such
                                        action is necessary to: fulfill a request from any government/statutory
                                        authority; conform with the requirements of the law or legal process; protect or
                                        defend our legal rights or property, our Site, or other users; or in an
                                        emergency to protect the security and safety of our users or the general public.
                                    </p>
                                    <h6 className="text-underline">Opting Out</h6>
                                    <p>
                                        If at any point after giving us your personally identifiable information, you no
                                        longer desire to continue to receive additional information from us about
                                        Dawraty, you may &ldquo;opt out&rdquo; by emailing info@joindawraty.com and
                                        putting the words &ldquo;remove my name&rdquo; in the subject line of your
                                        email. Please note, you cannot opt out of our sharing of your personal
                                        information with our Business Partners, or our sharing of de-identified
                                        information, or your personal information as required by law or legitimate
                                        business interests. Also, if you opt out of receiving marketing-related emails
                                        from us, we may still send you important administrative messages, from which you
                                        cannot opt out.
                                    </p>
                                    <h6 className="text-underline">Cross-border transfers of information</h6>
                                    <p>
                                        Our Site and the Services we offer through it are Kuwait and Bahrain based and
                                        are controlled, operated and administered by Dawraty and its processors from
                                        offices and servers. Our Site and this Privacy Policy is strictly intended for
                                        audiences located in Kuwait and Bahrain and are provided in accordance with and
                                        subject to applicable Kuwait and Bahrain law. If you decide to continue to
                                        access our Site from your location outside Kuwait or Bahrain, you hereby agree
                                        that your use of the Site or any Services we may offer are subject to this
                                        Policy and your personal information will be transferred or processed in Kuwait
                                        or Bahrain (as applicable), and you may have fewer privacy rights and
                                        protections than you have in your country of origin.
                                    </p>
                                    <h6 className="text-underline">
                                        Storage of personal information: your right to delete and correct it
                                    </h6>
                                    <p>
                                        We retain your personal information as reasonably necessary to fulfill the
                                        purposes for which we collected it or you provided it, and to comply with our
                                        legal obligations. In no event will we keep your personal information for longer
                                        than is reasonably necessary for the purposes defined in this Policy or our
                                        Terms and Conditions. You have the right to request that we delete your personal
                                        information, subject to certain exceptions, such as our need to prevent and
                                        protect against fraud or other illegal activity, to complete business
                                        transactions with you, and for such other purposes as allowed by law. You can
                                        correct or update your personal information by updating your profile or by
                                        contacting us. For more information on where and how long your personal
                                        information is stored, your requests to delete it or correct it, please contact
                                        us using the contact us form on our website.
                                    </p>
                                    <h6 className="text-underline">Data Security</h6>
                                    <p>
                                        We have implemented measures designed to secure your personal information from
                                        accidental loss and from unauthorized access, use, alteration, and disclosure.
                                        All information you provide to us is stored on our secure servers behind
                                        firewalls.{' '}
                                    </p>
                                    <p>
                                        The safety and security of your information also depends on you. Where we have
                                        given you (or where you have chosen) a password for access to certain parts of
                                        our Website, you are responsible for keeping this password confidential. We ask
                                        you not to share your password with anyone. We urge you to be careful about
                                        giving out information in public areas of the Website like message boards. The
                                        information you share in public areas may be viewed by any user of the Website.
                                    </p>
                                    <p>
                                        Unfortunately, the transmission of information via the internet is not
                                        completely secure. Although we do our best to protect your personal information,
                                        we cannot guarantee the security of your personal information transmitted to our
                                        Website. Any transmission of personal information is at your own risk. We are
                                        not responsible for circumvention of any privacy settings or security measures
                                        contained on the Website. We cannot guarantee the absolute security of
                                        information, or that our systems will be completely free from third party
                                        interception or are incorruptible from viruses. We cannot and do not guarantee
                                        that information you send from your computer/device to us over the internet will
                                        be protected by any form of encryption (encoding software).
                                    </p>
                                    <h6 className="text-underline">User Rights</h6>
                                    <p>
                                        Subject to certain limitations on certain rights, you have the following rights
                                        in relation to your information, which you can exercise by writing to
                                        info@joindawraty.com.
                                    </p>
                                    <p>
                                        &#9679;to request access to your information and information related to our use
                                        and processing of your information;
                                    </p>
                                    <p>&#9679;to request the correction or deletion of your information;</p>
                                    <p>&#9679;to request that we restrict our use of your information;</p>
                                    <p>
                                        &#9679;to receive information which you have provided to us in a structured,
                                        commonly used and machine-readable format;{' '}
                                    </p>
                                    <p>
                                        &#9679;to withdraw your consent to our use of your information at any time where
                                        we rely on your consent to use or process that information.{' '}
                                    </p>
                                    <p>
                                        Please note that if you withdraw your consent, this will not affect the
                                        lawfulness of our use and processing of your information on the basis of your
                                        consent before the point in time when you withdraw your consent.
                                    </p>
                                    <h6 className="text-underline">Limitation of liability</h6>
                                    <p>
                                        You understand and agree that any dispute over the privacy of your information
                                        is subject to the terms and conditions of this privacy policy and the Dawraty
                                        Terms and Conditions (including any indemnification and limitations on damages
                                        contained in that agreement). See more at{' '}
                                    </p>
                                    <h6 className="text-underline">Consent</h6>
                                    <p>
                                        By visiting our Site or by providing your information, you consent to the
                                        collection, use, storage, disclosure and otherwise processing of your
                                        information (including sensitive personal information) on the Platform in
                                        accordance with this Privacy Policy. If you disclose to us any personal
                                        information relating to others including minors, you represent that you have the
                                        authority to do so and permit us to use the information in accordance with this
                                        Privacy Policy. You, while providing your personal information over the Platform
                                        or any partner platforms or establishments, consent to us (including our other
                                        corporate entities, affiliates, lending partners, technology partners, marketing
                                        channels, business partners and other third parties) to contact you through SMS,
                                        instant messaging apps, call and/or e-mail for the purposes specified in this
                                        Privacy Policy. In the event you deny providing any information that is required
                                        to access certain services we offer, we reserve the right to restrict or deny
                                        the provision of our services for which we consider such information to be
                                        necessary.
                                    </p>
                                    <h6 className="text-underline">Changes to Our Privacy Policy</h6>
                                    <p>
                                        Any amendment to our Privacy Policy is subject to your prior written consent and
                                        approval. Changes are made at our discretion.{' '}
                                    </p>
                                    <h6 className="text-underline">Contact Information</h6>
                                    <p>
                                        To ask questions or comment about this privacy policy and our privacy practices,
                                        contact us at:{' '}
                                    </p>
                                    <p>info@joindawraty.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageLayout>
            ) : (
                <Loader />
            )}
        </>
    );
}
export default PrivacyPolicy;
