import axios from 'axios';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { icons } from '../../../constants/icons';
import { removeCourseFromCart } from '../../Utils/helper';

export function printcartData(
    cartArr,
    helperProps,
    history,
    customData = {
        free: 'Free',
    }
) {
    return (
        <>
            {cartArr?.map((_cart) => {
                return _cart?.chapters === null || _cart?.chapters?.length === 0 ? (
                    <a className="cart_row cursor_pointer">
                        <div className="cart_img">
                            <img
                                src={'https://www.admin.dawratycourses.com' + _cart?.courses?.course_image}
                                alt="course_image"
                            />
                        </div>
                        <div className="cart_course_details">
                            <div className="cart_course_title">{_cart?.courses?.name}</div>
                            <div className="cart_course_instructor">
                                {`${_cart?.courses?.instructor?.first_name} ${_cart?.courses?.instructor?.last_name}`}
                            </div>
                            <div className="cp_wrapper">
                                {_cart?.courses?.is_free === 1 ? (
                                    <div className="price_discount">{customData.free}</div>
                                ) : !_cart?.courses?.course_sale?.on_sale ? (
                                    <div className="price_discount">
                                        {_cart?.courses?.country_id === 112
                                            ? helperProps.t('course.price_in_kd', {
                                                  price: _cart?.courses?.course_sale?.old_price,
                                              })
                                            : helperProps.t('course.price_in_bd', {
                                                  price: _cart?.courses?.course_sale?.old_price,
                                              })}
                                    </div>
                                ) : _cart?.courses?.country_id === 112 ? (
                                    <>
                                        <div className="price_discount">
                                            {helperProps.t('course.price_in_kd', {
                                                price: _cart?.courses?.course_sale?.new_price,
                                            })}
                                        </div>
                                        <div className="price_strike">
                                            {helperProps.t('course.price_in_kd', {
                                                price: _cart?.courses?.course_sale?.old_price,
                                            })}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="price_discount">
                                            {helperProps.t('course.price_in_bd', {
                                                price: _cart?.courses?.course_sale?.new_price,
                                            })}
                                        </div>
                                        <div className="price_strike">
                                            {helperProps.t('course.price_in_bd', {
                                                price: _cart?.courses?.course_sale?.old_price,
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <button
                            type="button"
                            className="cart-item-remove-btn"
                            onClick={() =>
                                removeCourseFromCart(_cart, helperProps, 'cart-loading', history, {
                                    removeFromCart: customData.removeCourseFromCart,
                                })
                            }
                        >
                            <img src={icons.icon_close} alt="close" />
                        </button>
                    </a>
                ) : (
                    multipleChapter(_cart)
                );
            })}
        </>
    );
}
export function printCartDataLoggedOut(
    cartArr,
    helperProps,
    customData = {
        free: 'Free',
    }
) {
    return (
        <>
            {cartArr?.map((_cart) => (
                <a className="cart_row cursor_pointer">
                    <div className="cart_img">
                        <img src={'https://www.admin.dawratycourses.com' + _cart?.course_image} alt="course_image" />
                    </div>
                    <div className="cart_course_details">
                        <div className="cart_course_title">{_cart?.name}</div>
                        <div className="cart_course_instructor">
                            {`${_cart?.instructor?.first_name} ${_cart?.instructor?.last_name}`}
                        </div>
                        <div className="cp_wrapper">
                            {_cart?.isFree === 1 ? (
                                <div className="price_discount">{customData.free}</div>
                            ) : !_cart?.course_sale?.on_sale ? (
                                _cart?.country_id === 112 ? (
                                    <div className="price_discount">
                                        {helperProps.t('course.price_in_kd', {
                                            price: _cart?.course_sale?.old_price,
                                        })}
                                    </div>
                                ) : (
                                    <div className="price_discount">
                                        {helperProps.t('course.price_in_bd', {
                                            price: _cart?.course_sale?.old_price,
                                        })}
                                    </div>
                                )
                            ) : _cart?.country_id === 112 ? (
                                <>
                                    <div className="price_discount">
                                        {helperProps.t('course.price_in_kd', { price: _cart?.course_sale?.new_price })}
                                    </div>
                                    <div className="price_strike">
                                        {helperProps.t('course.price_in_kd', { price: _cart?.course_sale?.old_price })}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="price_discount">
                                        {helperProps.t('course.price_in_bd', { price: _cart?.course_sale?.new_price })}
                                    </div>
                                    <div className="price_strike">
                                        {helperProps.t('course.price_in_bd', { price: _cart?.course_sale?.old_price })}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <button
                        type="button"
                        className="cart-item-remove-btn"
                        onClick={() => removeCourseFromCart(_cart, helperProps, 'cart-loading')}
                    >
                        <img src={icons.icon_close} alt="close" />
                    </button>
                </a>
            ))}
        </>
    );
}

function multipleChapter(_cart) {
    let filterArrayChapter = [];
    _cart?.courses?.chapter?.map((item) => {
        _cart?.chapters?.map((_item) => {
            if (item?.id === _item) {
                filterArrayChapter.push(item);
            }
        });
    });
    return (
        <>
            {filterArrayChapter?.map((_chap) => {
                return (
                    <a href="#" className="cart_row">
                        <div className="cart_img">
                            <img
                                src={'https://www.admin.dawratycourses.com' + _cart?.courses?.course_image}
                                alt="course_image"
                            />
                        </div>

                        <div className="cart_course_details">
                            <div className="cart_course_title">{_cart?.courses?.name}</div>
                            <div className="cart_course_title">{_chap?.name}</div>
                            <div className="cart_course_instructor">{_chap?.price}</div>
                        </div>
                    </a>
                );
            })}
        </>
    );
}
// function chapNamePrice(cart) {
//   let _price;
//   let name;
//   cart?.courses?.chapter?.map((_item) => {
//     if (_item?.id === cart?.chapters[0]) {
//       _price = _item?.price;
//       name = _item?.name;
//     }
//   });
//   return (
//     <>
//       <span>{name}</span>
//       <div className="cp_wrapper">{_price}</div>
//     </>
//   );
// }

let cancelToken;

export const handleSearchChange = async (e, setSearchCourseData, loggedIn, countryInfo, setLoadingSearch) => {
    const searchTerm = e.target.value;
    setLoadingSearch(true);
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    try {
        let results;
        if (!loggedIn) {
            results = await axios.get(
                `${'https://www.admin.dawratycourses.com/api'}/v1/Courses?search=${searchTerm}&countryId=${countryInfo.id}`,
                { cancelToken: cancelToken.token } //Pass the cancel token to the current request
            );
            setLoadingSearch(false);
        } else {
            results = await axios.get(
                `${'https://www.admin.dawratycourses.com/api'}/v1/Courses?search=${searchTerm}&auth=${Cookies.get('auth')}`,
                { cancelToken: cancelToken.token } //Pass the cancel token to the current request
            );
            setLoadingSearch(false);
        }
        if (!searchTerm) {
            setSearchCourseData(results?.data?.data?.data);
            setSearchCourseData(null);
        } else {
            setSearchCourseData(results?.data?.data);
        }
    } catch (error) {
        // console.log(error);
    }
};

export function logoutUser() {
    Cookies.remove('EAPISID');
    Cookies.remove('auth');
    localStorage.removeItem('update_cart');
    <Link to="/" />;
}
