import { userConstants } from '../constant';

const initialState = {
    loading: false,
    error: false,
    password: [],
};

export default function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.REQUEST_NEW_PASSWORD:
            return {
                loading: true,
                error: false,
                password: [],
            };
        case userConstants.RECEIVE_NEW_PASSWORD:
            return {
                loading: false,
                error: false,
                password: action.password.message,
            };
        case userConstants.ERROR_NEW_PASSWORD:
            return {
                loading: false,
                error: true,
                password: action.error,
            };
        default:
            return state;
    }
}
