import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setCurrentYear } from '../services/student';
import { showError, showSuccess } from '../Utils/helper';

function CurrentYearModal({ closeModal, setShow }) {
    const { t } = useTranslation();

    const [selectedYear, setSelectedYear] = useState('');
    const yearOptions = ['firstYear', 'secondYear', 'thirdYear', 'fourthYear', 'fifthYear', 'sixthYear', 'seventhYear'];

    async function handleYearSelect() {
        const resp = await setCurrentYear({ year: selectedYear })
            .then((res) => {
                showSuccess(t('alertMessage.success'));
                closeModal();
                setShow(false);
            })
            .catch((err) => showError(t('alertMessage.wrong')));
    }

    return (
        <div className="lrf_modal">
            <h2>Select Current Year</h2>
            <div className="lrf_subtext">
                <p>Kindly select your current year of study</p>
                <p>الرجاء تحديد السنة الدراسية</p>
            </div>
            <div className="lrf_subtext">
                <select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    className="react_select"
                    style={{
                        paddingLeft: '30px',
                        paddingRight: '28px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}
                >
                    <option value="" disabled>
                        Select a year
                    </option>
                    {yearOptions.map((year, index) => (
                        <option key={index} value={year}>
                            {t('year.' + year, year)}
                        </option>
                    ))}
                </select>
                <div>
                    <button className="btn_primary big_btn full_btn mt-3" onClick={handleYearSelect}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CurrentYearModal;
