import { userConstants } from '../constant';

export default function register(state = {}, action) {
    switch (action.type) {
        case userConstants.REGISTER_INSTRUCTOR_REQUEST:
            return {
                loading: true,
                error: false,
                register: [],
            };
        case userConstants.REGISTER_INSTRUCTOR_SUCCESS:
            return {
                register: action.auth,
                loading: false,
                error: false,
            };
        case userConstants.REGISTER_STUDENT_FAILURE:
            return {
                error: true,
                loading: false,
                register: action.error,
            };
        default:
            return state;
    }
}
