import { unauthedUserConstants } from '../constants';

export const countryAction = {
    idUnauthedCountry,
};

//push country code to redux store TODO: Refactor code to be cleaner, better error handling
function idUnauthedCountry(countryInfo = null) {
    return (dispatch) => {
        if (countryInfo === null) dispatch(failure({}));

        dispatch(success({ id: countryInfo.id, name: countryInfo.name }));
    };

    function success(country) {
        return { type: unauthedUserConstants.RECIEVE_COUNTRY_INFO, country };
    }

    function failure(error) {
        return { type: unauthedUserConstants.ERROR_COUNTRY_INFO, error };
    }
}
