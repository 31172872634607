import React, { useEffect, useState } from 'react';
import { icons } from '../../constants/icons';
import { Link } from 'react-router-dom';
import { getInstructorList } from '../services/discussionForum';
import { showError, Spinner } from '../Utils/helper';
import { ar_messages, messages } from '../../constants/message';
import { useTranslation } from 'react-i18next';

function InstructorsList() {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    async function getInstructors() {
        setLoading(true);
        const resp = await getInstructorList();
        if (resp.success) {
            setData(resp?.data?.data);
        } else {
            showError(t('alertMessage.wrong'));
        }
        setLoading(false);
    }
    useEffect(() => {
        getInstructors();
    }, []);

    return (
        <div className="container mtb_50">
            {loading ? (
                <Spinner />
            ) : (
                <div className="row">
                    {data?.length === 0 ? (
                        <h2 className="p-4 text-center">
                            {i18n.language == 'ar' ? ar_messages.NO_RESULTS_FOUND : messages.NO_RESULTS_FOUND}
                        </h2>
                    ) : (
                        <>
                            {data?.map((item) => (
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="instructor_box">
                                        <div class="instructor_profile">
                                            {/* <img src="img/testimony-user.png" alt="" /> */}
                                            <div>{`${item?.first_name[0]} ${item?.last_name[0]}`}</div>
                                        </div>
                                        <div className="instructor_box_name mb-3">{`${item?.first_name} ${item?.last_name}`}</div>

                                        <Link
                                            to={{
                                                pathname: `/chat/${item?.id}`,
                                                state: { data: item },
                                            }}
                                            className="instructor_btn "
                                            style={{ color: '#fff', cursor: 'pointer' }}
                                        >
                                            {t('common.askQuestion')}
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default InstructorsList;
