import React, { useContext } from 'react';
import PageLayout from '../Layouts/PageLayout';
import Loader from '../Common/Loader';
import { UserContext } from '../context/AuthContext';

function TermsCondition() {
    const { loadingUser } = useContext(UserContext);
    return (
        <>
            {!loadingUser ? (
                <PageLayout>
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="privacy-section">
                                    <h1 className="text-center text-underline">Terms and Conditions</h1>
                                    <h2 className="text-center">Dawraty Educational Group</h2>
                                    <ol type="A">
                                        <li>
                                            <h6 className="text-underline">Terms of Use and Services </h6>
                                            <ol type="a">
                                                <li>
                                                    <h6 className="text-underline">
                                                        Acceptance of these Terms and Conditions.
                                                    </h6>
                                                    <p>
                                                        <span>
                                                            The Terms and Conditions govern any individual or entity
                                                            (also referred to as &ldquo;User,&rdquo; &ldquo;You,&rdquo;
                                                            or &ldquo;Your&rdquo;) accessing this Website, utilizing
                                                            this Website, app and the Services mentioned hereunder. By
                                                            using this website or any Dawraty service or application
                                                            (collectively, the &ldquo;Website,&rdquo;
                                                            &ldquo;Platform,&rdquo; or &ldquo;Service&rdquo;), you
                                                            accept the Terms and Conditions (&ldquo;T&amp;Cs&rdquo;)
                                                            mentioned hereinafter in full and a contractual relationship
                                                            is established between you and Dawraty Educational Group
                                                            (&ldquo;Dawraty,&rdquo; &ldquo;We,&rdquo; &ldquo;Us,&rdquo;
                                                            &ldquo;Our,&rdquo; or &ldquo;Company&rdquo;). These T&amp;Cs
                                                            govern your access and use of the Website, including any
                                                            content, functionality, and services offered on or through
                                                        </span>
                                                        <span>
                                                            <a href="https://www.joindawraty.com/" target="_blank">
                                                                {' '}
                                                                https://www.joindawraty.com/
                                                            </a>
                                                        </span>
                                                        <span>
                                                            &nbsp;whether as a guest or a registered user. If you
                                                            disagree with these T&amp;Cs or any part of these T&amp;Cs
                                                            you must not use this Website or any of its services.
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <span>
                                                            Please read these T&amp;Cs carefully before you start to use
                                                            the Website.
                                                        </span>
                                                        <span>
                                                            By using the Website or by clicking to accept or agree to
                                                            the Terms and Conditions when this option is made available
                                                            to you, you accept and agree to be bound and abide by these
                                                            Terms and Conditions and our Privacy Policy, found at{' '}
                                                        </span>
                                                        <span>[DAWRATY PRIVACY POLICY URL],</span>
                                                        <span>&nbsp;incorporated herein by reference.</span>
                                                        <span>
                                                            &nbsp;If you do not agree to these Terms and Conditions or
                                                            the Privacy Policy, you must not access or use the Website.
                                                        </span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <span>Changes to the Terms and Conditions</span>
                                                    <p>
                                                        <span>S</span>
                                                        <span>
                                                            ome Services offered through our Platform may be subject to
                                                            additional or amended T&amp;Cs that we publish from time to
                                                            time.{' '}
                                                        </span>
                                                        <span>
                                                            Any amendment to these T&amp;Cs is subject to your prior
                                                            written consent and approval.{' '}
                                                        </span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <h6 className="text-underline">Platform Description</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        Our Platform aims to deliver education through various courses
                                                        by connecting students to educators across multiple areas of
                                                        expertise.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">
                                                        Accessing the Website and Account Security
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        To access the Website or some of the resources it offers, you
                                                        may be asked to provide certain registration details or other
                                                        information. It is a condition of your use of the Website that
                                                        all the information you provide on the Website is correct,
                                                        current, and complete. You agree that all information you
                                                        provide to register with this Website or otherwise, including,
                                                        but not limited to, through the use of any interactive features
                                                        on the Website, is governed by our{' '}
                                                    </span>
                                                    <span>Privacy Policy</span>
                                                    <span>&nbsp;</span>
                                                    <span>[LINK TO DAWRATY PRIVACY POLICY]</span>
                                                    <span>.</span>
                                                </p>
                                                <p>
                                                    <span>
                                                        If you choose, or are provided with, a username, password, or
                                                        any other piece of information as part of our security
                                                        procedures, you must treat such information as confidential, and
                                                        you must not disclose it to any other person or entity. You also
                                                        acknowledge that your account is personal to you and agree not
                                                        to provide any other person with access to this Website or
                                                        portions of it using your username, password, or other security
                                                        information. You agree to notify us immediately of any
                                                        unauthorized access to or use of your username or password or
                                                        any other breach of security. You also agree to ensure that you
                                                        exit from your account at the end of each session. You should
                                                        use particular caution when accessing your account from a public
                                                        or shared computer so that others are not able to view or record
                                                        your password or other personal information.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        We have the right to disable any username, password, or other
                                                        identifier, whether chosen by you or provided by us, at any time
                                                        in our sole discretion for any or no reason, including if, in
                                                        our opinion, you have violated any provision of these Terms and
                                                        Conditions.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        We will not be liable if for any reason all or any part of the
                                                        Website is unavailable at any time or for any period. From time
                                                        to time, we may restrict access to some parts of the Website, or
                                                        the entire Website, by users, including registered users.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>You are responsible for both:</span>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span>
                                                            Making all arrangements necessary for you to have access to
                                                            the Website.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Ensuring that all authorized persons who access the Website
                                                            through your internet connection are aware of these Terms
                                                            and Conditions and comply with them.
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p>
                                                    <span>
                                                        You also agree that we or third parties acting on our behalf may
                                                        send you emails regarding important information like your use of
                                                        the Website and other electronic communications such as
                                                        newsletters, promotional materials, etc. You may opt out of
                                                        receiving promotional messages.
                                                    </span>
                                                </p>
                                                <a> </a>
                                                <li>
                                                    <h6 className="text-underline">Content Enrollment and Access</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        Courses offered on our Platform may have varying access
                                                        durations. Some courses provide lifetime access, while others
                                                        grant access for a limited time. You are responsible for
                                                        reviewing the access terms for each course in which you enroll,
                                                        and your access to course materials may be revoked, at our
                                                        discretion, if you violate these Terms and Conditions.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span></span>
                                                </p>
                                                <p>
                                                    <span>
                                                        You agree not to share your account information with other
                                                        users. Accounts may not be shared and doing so will be
                                                        considered a violation of these Terms and Conditions.{' '}
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Payments, Credits, and Refunds </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        All course payments must be made in full prior to accessing any
                                                        course content. Refunds may be granted, at our sole discretion,
                                                        if requested within seven (7) days of the initial purchase.
                                                        Refund requests beyond this period will not be considered.
                                                        Credits may be also be issued at our sole discretion and may be
                                                        applied toward future course enrollments.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Course Purchase Terms </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        By purchasing a course on Dawraty, you agree to abide by the
                                                        access and usage terms outlined for that specific course and
                                                        these Terms and Conditions. Failure to comply with all terms may
                                                        result in suspension or termination of your access to course
                                                        content.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Intellectual Property Rights</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        The Website and its entire contents, features, and functionality
                                                        (including but not limited to all information, software, text,
                                                        displays, images, video, and audio, and the design, selection,
                                                        and arrangement thereof) are owned by the Company, its
                                                        licensors, or other providers of such material and are protected
                                                        by applicable local and international copyright, trademark,
                                                        patent, trade secret, and other intellectual property or
                                                        proprietary rights laws.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        These Terms and Conditions permit you to use the Website for
                                                        your personal, non-commercial use only. You must not reproduce,
                                                        distribute, modify, create derivative works of, publicly
                                                        display, publicly perform, republish, download, store, or
                                                        transmit any of the material on our Website, except as follows:
                                                    </span>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span>
                                                            Your computer may temporarily store copies of such materials
                                                            in RAM incidental to your accessing and viewing those
                                                            materials.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            You may store files that are automatically cached by your
                                                            Web browser for display enhancement purposes.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            You may print or download one copy of a reasonable number of
                                                            pages of the Website for your own personal, non-commercial
                                                            use and not for further reproduction, publication, or
                                                            distribution.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            If we provide desktop, mobile, or other applications for
                                                            download, you may download a single copy to your computer or
                                                            mobile device solely for your own personal, non-commercial
                                                            use, provided you agree to be bound by our end user license
                                                            agreement for such applications.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            If we provide social media features or links to social media
                                                            with certain content, you may take such actions as are
                                                            enabled by such features.
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p>
                                                    <span>You must not:</span>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span>Modify copies of any materials from this site.</span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Use any illustrations, photographs, video or audio
                                                            sequences, or any graphics separately from the accompanying
                                                            text.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Delete or alter any copyright, trademark, or other
                                                            proprietary rights notices from copies of materials from
                                                            this site.
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p>
                                                    <span>
                                                        You must not access or use for any commercial purposes any part
                                                        of the Website or any services or materials available through
                                                        the Website.{' '}
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        If you wish to make any use of material on the Website other
                                                        than as permitted by this section, please address your request
                                                        to:{' '}
                                                    </span>
                                                    <span>info@joindawraty.com.</span>
                                                </p>
                                                <a> </a>
                                                <p>
                                                    <span>
                                                        If you print, copy, modify, download, or otherwise use or
                                                        provide any other person with access to any part of the Website
                                                        in breach of the Terms and Conditions, your right to use the
                                                        Website will stop immediately and you must, at our option,
                                                        return or destroy any copies of the materials you have made. No
                                                        right, title, or interest in or to the Website or any content on
                                                        the Website is transferred to you, and all rights not expressly
                                                        granted are reserved by Dawraty. Any use of the Website not
                                                        expressly permitted by these Terms and Conditions is a breach of
                                                        these T&amp;Cs and may violate copyright, trademark, and other
                                                        laws.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Trademarks</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        The Company name, the short form name &ldquo;Dawraty&rdquo;, the
                                                        Company logo, and all related names, logos, product and service
                                                        names, designs, and slogans are trademarks of the Company or its
                                                        affiliates or licensors. You must not use such marks without the
                                                        prior written permission of the Company. All other names, logos,
                                                        product and service names, designs, and slogans on this Website
                                                        are the trademarks of their respective owners.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Prohibited Uses</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        You may use the Website only for lawful purposes and in
                                                        accordance with these Terms and Conditions. You agree not to use
                                                        the Website:
                                                    </span>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span>
                                                            In any way that violates any applicable local, or
                                                            international law or regulation (including, without
                                                            limitation, any laws regarding the export of data or
                                                            software).
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            For the purpose of exploiting, harming, or attempting to
                                                            exploit or harm minors in any way by exposing them to
                                                            inappropriate content, asking for personally identifiable
                                                            information, or otherwise.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            To send, knowingly receive, upload, download, use, or re-use
                                                            any material that does not comply with the Content Standards
                                                            set out in these Terms and Conditions.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            To transmit, or procure the sending of, any advertising or
                                                            promotional material without our prior written consent,
                                                            including any &quot;junk mail,&quot; &quot;chain
                                                            letter,&quot; &quot;spam,&quot; or any other similar
                                                            solicitation.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            To impersonate or attempt to impersonate the Company, a
                                                            Company employee, another user, or any other person or
                                                            entity (including, without limitation, by using email
                                                            addresses or screen names associated with any of the
                                                            foregoing).
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            To engage in any other conduct that restricts or inhibits
                                                            anyone&#39;s use or enjoyment of the Website, or which, as
                                                            determined by us, may harm the Company or users of the
                                                            Website, or expose them to liability.
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p>
                                                    <span>Additionally, you agree not to:</span>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span>
                                                            Use the Website in any manner that could disable,
                                                            overburden, damage, or impair the site or interfere with any
                                                            other party&#39;s use of the Website, including their
                                                            ability to engage in real time activities through the
                                                            Website.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Use any robot, spider, or other automatic device, process,
                                                            or means to access the Website for any purpose, including
                                                            monitoring or copying any of the material on the Website.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Use any manual process to monitor or copy any of the
                                                            material on the Website, or for any other purpose not
                                                            expressly authorized in these Terms and Conditions, without
                                                            our prior written consent.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Use any device, software, or routine that interferes with
                                                            the proper working of the Website.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Introduce any viruses, trojan horses, worms, logic bombs, or
                                                            other material that is malicious or technologically harmful.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Attempt to gain unauthorized access to, interfere with,
                                                            damage, or disrupt any parts of the Website, the server on
                                                            which the Website is stored, or any server, computer, or
                                                            database connected to the Website.{' '}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Attack the Website via a denial-of-service attack or a
                                                            distributed denial-of-service attack.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Otherwise attempt to interfere with the proper working of
                                                            the Website.
                                                        </span>
                                                    </li>
                                                </ul>
                                                <li>
                                                    <h6 className="text-underline">User Contributions</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        The Website may contain message boards, bulletin boards, review
                                                        pages, links to social media accounts, and other interactive
                                                        features (collectively, &quot;
                                                    </span>
                                                    <span>Interactive Services</span>
                                                    <span>
                                                        &quot;) that allow users to post, submit, publish, display, or
                                                        transmit to other users or other persons (hereinafter, &quot;
                                                    </span>
                                                    <span>post</span>
                                                    <span>&quot;) content or materials (collectively, &quot;</span>
                                                    <span>User Contributions</span>
                                                    <span>&quot;) on or through the Website.</span>
                                                </p>
                                                <a> </a>
                                                <p>
                                                    <span>
                                                        All User Contributions must comply with the Content Standards
                                                        set out in these Terms and Conditions.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        Any User Contribution you post to the site will be considered
                                                        non-confidential and non-proprietary. By providing any User
                                                        Contribution on the Website, you grant us and our affiliates and
                                                        service providers, and each of their and our respective
                                                        licensees, successors, and assigns the right to use, reproduce,
                                                        modify, perform, display, distribute, and otherwise disclose to
                                                        third parties any such material for any purpose.{' '}
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>You represent and warrant that: </span>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span>
                                                            You own or control all rights in and to the User
                                                            Contributions and have the right to grant the license
                                                            granted above to us and our affiliates and service
                                                            providers, and each of their and our respective licensees,
                                                            successors, and assigns.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            All of your User Contributions do and will comply with these
                                                            Terms and Conditions.{' '}
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p>
                                                    <span>
                                                        You understand and acknowledge that you are responsible for any
                                                        User Contributions you submit or contribute, and you, not the
                                                        Company, have full responsibility for such content, including
                                                        its legality, reliability, accuracy, and appropriateness.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        We are not responsible or liable to any third party for the
                                                        content or accuracy of any User Contributions posted by you or
                                                        any other user of the Website.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">
                                                        Monitoring and Enforcement; Termination
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>We have the right to:</span>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span>
                                                            Remove or refuse to post any User Contributions for any or
                                                            no reason in our sole discretion.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Take any action with respect to any User Contribution that
                                                            we deem necessary or appropriate in our sole discretion,
                                                            including if we believe that such User Contribution violates
                                                            these Terms and Conditions, including the Content Standards,
                                                            infringes any intellectual property right or other right of
                                                            any person or entity, threatens the personal safety of users
                                                            of the Website or the public, or could create liability for
                                                            the Company.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Disclose your identity or other information about you to any
                                                            third party who claims that material posted by you violates
                                                            their rights, including their intellectual property rights
                                                            or their right to privacy.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Take appropriate legal action, including without limitation,
                                                            referral to law enforcement, for any illegal or unauthorized
                                                            use of the Website.{' '}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Terminate or suspend your access to all or part of the
                                                            Website for any or no reason, including without limitation,
                                                            any violation of these Terms and Conditions.
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p>
                                                    <span>
                                                        Without limiting the foregoing, we have the right to cooperate
                                                        fully with any law enforcement authorities or court order
                                                        requesting or directing us to disclose the identity or other
                                                        information of anyone posting any materials on or through the
                                                        Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS
                                                        AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS
                                                        RESULTING FROM ANY ACTION TAKEN BY THE COMPANY/ANY OF THE
                                                        FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
                                                        INVESTIGATIONS BY EITHER THE COMPANY/SUCH PARTIES OR LAW
                                                        ENFORCEMENT AUTHORITIES.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        However, we cannot review all material before it is posted on
                                                        the Website, and cannot ensure prompt removal of objectionable
                                                        material after it has been posted. Accordingly, we assume no
                                                        liability for any action or inaction regarding transmissions,
                                                        communications, or content provided by any user or third party.
                                                        We have no liability or responsibility to anyone for performance
                                                        or nonperformance of the activities described in this section.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Content Standards</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        These Content Standards apply to any and all User Contributions
                                                        and use of Interactive Services. User Contributions must in
                                                        their entirety comply with all applicable local, and
                                                        international laws and regulations. Without limiting the
                                                        foregoing, User Contributions must not:
                                                    </span>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span>
                                                            Contain any material that is defamatory, obscene, indecent,
                                                            abusive, offensive, harassing, violent, hateful,
                                                            inflammatory, or otherwise objectionable.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Promote sexually explicit or pornographic material,
                                                            violence, or discrimination based on race, sex, religion,
                                                            nationality, disability, sexual orientation, or age.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Infringe any patent, trademark, trade secret, copyright, or
                                                            other intellectual property or other rights of any other
                                                            person.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Violate the legal rights (including the rights of publicity
                                                            and privacy) of others or contain any material that could
                                                            give rise to any civil or criminal liability under
                                                            applicable laws or regulations or that otherwise may be in
                                                            conflict with these Terms and Conditions and our Privacy
                                                            Policy [
                                                        </span>
                                                        <span>LINK TO DAWRATY PRIVACY POLICY</span>
                                                        <span>].</span>
                                                    </li>
                                                    <li>
                                                        <span>Be likely to deceive any person.</span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Promote any illegal activity, or advocate, promote, or
                                                            assist any unlawful act.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Cause annoyance, inconvenience, or needless anxiety or be
                                                            likely to upset, embarrass, alarm, or annoy any other
                                                            person.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Impersonate any person, or misrepresent your identity or
                                                            affiliation with any person or organization.{' '}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Involve commercial activities or sales, such as contests,
                                                            sweepstakes, and other sales promotions, barter, or
                                                            advertising.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Give the impression that they emanate from or are endorsed
                                                            by us or any other person or entity if this is not the case.
                                                        </span>
                                                    </li>
                                                </ul>
                                                <li>
                                                    <h6 className="text-underline">Copyright Infringement</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        If you believe that any User Contributions violate your
                                                        copyright, please send us a notice of copyright infringement. It
                                                        is the policy of the Company to terminate the user accounts of
                                                        repeat infringers.{' '}
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Reliance on Information Posted</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        The information presented on or through the Website is made
                                                        available solely for general information purposes. We do not
                                                        warrant the accuracy, completeness, or usefulness of this
                                                        information. Any reliance you place on such information is
                                                        strictly at your own risk. We disclaim all liability and
                                                        responsibility arising from any reliance placed on such
                                                        materials by you or any other visitor to the Website, or by
                                                        anyone who may be informed of any of its contents.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        This Website may include/includes content provided by third
                                                        parties, including materials provided by other users, bloggers,
                                                        and third-party licensors, syndicators, aggregators, and/or
                                                        reporting services. All statements and/or opinions expressed in
                                                        these materials, and all articles and responses to questions and
                                                        other content, other than the content provided by the Company,
                                                        are solely the opinions and the responsibility of the person or
                                                        entity providing those materials. These materials do not
                                                        necessarily reflect the opinion of the Company. We are not
                                                        responsible, or liable to you or any third party, for the
                                                        content or accuracy of any materials provided by any third
                                                        parties.
                                                    </span>
                                                </p>
                                                <a> </a>
                                                <li>
                                                    <h6 className="text-underline">
                                                        Changes to the Website and Platform
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        We may update the content on this Website from time to time, but
                                                        its content is not necessarily complete or up-to-date. Any of
                                                        the material on the Website may be out of date at any given
                                                        time, and we are under no obligation to update such material.{' '}
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">
                                                        Information About You and Your Visits to the Website
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        All information we collect on this Website is subject to our
                                                        Privacy Policy
                                                    </span>
                                                    <span>[LINK TO DAWRATY PRIVACY POLICY]</span>
                                                    <span>
                                                        . By using the Website, you consent to all actions taken by us
                                                        with respect to your information in compliance with the Privacy
                                                        Policy.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">
                                                        Linking to the Website and Social Media Features
                                                    </h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        You may link to our homepage, provided you do so in a way that
                                                        is fair and legal and does not damage our reputation or take
                                                        advantage of it, but you must not establish a link in such a way
                                                        as to suggest any form of association, approval, or endorsement
                                                        on our part without our express written consent.{' '}
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        This Website may provide certain social media features that
                                                        enable you to:
                                                    </span>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span>
                                                            Link from your own or certain third-party websites to
                                                            certain content on this Website.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Send emails or other communications with certain content, or
                                                            links to certain content, on this Website.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Cause limited portions of content on this Website to be
                                                            displayed or appear to be displayed on your own or certain
                                                            third-party websites.
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p>
                                                    <span>
                                                        You may use these features solely as they are provided by us,
                                                        and solely with respect to the content they are displayed with,
                                                        and otherwise in accordance with any additional terms and
                                                        conditions we provide with respect to such features. Subject to
                                                        the foregoing, you must not:
                                                    </span>
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span>
                                                            Establish a link from any website that is not owned by you.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Cause the Website or portions of it to be displayed on, or
                                                            appear to be displayed by, any other site, for example,
                                                            framing, deep linking, or in-line linking.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Link to any part of the Website other than the homepage.
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Otherwise take any action with respect to the materials on
                                                            this Website that is inconsistent with any other provision
                                                            of these Terms and Conditions.
                                                        </span>
                                                    </li>
                                                </ul>
                                                <p>
                                                    <span>
                                                        The website from which you are linking, or on which you make
                                                        certain content accessible, must comply in all respects with the
                                                        Content Standards set out in these Terms and Conditions.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        You agree to cooperate with us in causing any unauthorized
                                                        framing or linking immediately to stop. We reserve the right to
                                                        withdraw linking permission without notice.
                                                    </span>
                                                </p>
                                                <a> </a>
                                                <p>
                                                    <span>
                                                        We may disable all or any social media features and any links at
                                                        any time without notice in our discretion.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Links from the Website</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        If the Website contains links to other sites and resources
                                                        provided by third parties, these links are provided for your
                                                        convenience only. This includes links contained in
                                                        advertisements, including banner advertisements and sponsored
                                                        links. We have no control over the contents of those sites or
                                                        resources and accept no responsibility for them or for any loss
                                                        or damage that may arise from your use of them. If you decide to
                                                        access any of the third-party websites linked to this Website,
                                                        you do so entirely at your own risk and subject to the terms and
                                                        conditions of use for such websites.
                                                    </span>
                                                </p>
                                                <a> </a>
                                                <li>
                                                    <h6 className="text-underline">Geographic Restrictions</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        We provide this Website for use only by persons located in
                                                        Kuwait and Bahrain. We make no claims that the Website or any of
                                                        its content is accessible or appropriate outside of Kuwait and
                                                        Bahrain. Access to the Website may not be legal by certain
                                                        persons or in certain countries. If you access the Website from
                                                        outside Kuwait and Bahrain, you do so on your own initiative and
                                                        are responsible for compliance with local laws.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Disclaimer of Warranties</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        You understand that we cannot and do not guarantee or warrant
                                                        that files available for downloading from the internet or the
                                                        Website will be free of viruses or other destructive code. You
                                                        are responsible for implementing sufficient procedures and
                                                        checkpoints to satisfy your particular requirements for
                                                        anti-virus protection and accuracy of data input and output, and
                                                        for maintaining a means external to our site for any
                                                        reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED
                                                        BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
                                                        DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                                                        TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
                                                        EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
                                                        MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
                                                        OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY
                                                        MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
                                                        OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE,
                                                        ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                                                        WEBSITE ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS
                                                        AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
                                                        EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON
                                                        ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION
                                                        WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
                                                        QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT
                                                        LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
                                                        ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
                                                        WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                                                        THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                                                        UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR
                                                        THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
                                                        HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
                                                        OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR
                                                        EXPECTATIONS.{' '}
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY
                                                        DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                                                        IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                                                        ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS
                                                        FOR PARTICULAR PURPOSE.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
                                                        EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Limitation on Liability</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE
                                                        COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS,
                                                        EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES
                                                        OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
                                                        CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
                                                        WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER
                                                        WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                                                        CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                                                        TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
                                                        LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                                                        ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                                                        DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
                                                        OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.{' '}
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
                                                        EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Indemnification</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        You agree to defend, indemnify, and hold harmless the Company,
                                                        its affiliates, licensors, and service providers, and its and
                                                        their respective officers, directors, employees, contractors,
                                                        agents, licensors, suppliers, successors, and assigns from and
                                                        against any claims, liabilities, damages, judgments, awards,
                                                        losses, costs, expenses, or fees (including reasonable
                                                        attorneys&#39; fees) arising out of or relating to your
                                                        violation of these Terms and Conditions or your use of the
                                                        Website, including, but not limited to, your User Contributions,
                                                        any use of the Website&#39;s content, services, and products
                                                        other than as expressly authorized in these Terms and
                                                        Conditions, or your use of any information obtained from the
                                                        Website.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Governing Law and Jurisdiction</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        All matters relating to the Website and these Terms and
                                                        Conditions, and any dispute or claim arising therefrom or
                                                        related thereto (in each case, including non-contractual
                                                        disputes or claims), shall be governed by and construed in
                                                        accordance with the laws of Kuwait and Bahrain (whichever
                                                        jurisdiction in which the claim or dispute arose. If a dispute
                                                        arises outside of Kuwait or Bahrain. Kuwait law shall apply)
                                                        without giving effect to any choice or conflict of law provision
                                                        or rule (whether Kuwait, Bahrain or any other jurisdiction).
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        Any legal suit, action, or proceeding arising out of, or related
                                                        to, these Terms and Conditions or the Website shall be{' '}
                                                    </span>
                                                    <span>
                                                        resolved through negotiation, mediation, or arbitration, as
                                                        agreed upon by both parties&mdash;not within State courts.{' '}
                                                    </span>
                                                    <span>
                                                        You waive any and all objections to the exercise of jurisdiction
                                                        over you by such courts and to venue in such courts.
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Waiver and Severability</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        No waiver by the Company of any term or condition set out in
                                                        these Terms and Conditions shall be deemed a further or
                                                        continuing waiver of such term or condition or a waiver of any
                                                        other term or condition, and any failure of the Company to
                                                        assert a right or provision under these Terms and Conditions
                                                        shall not constitute a waiver of such right or provision.
                                                    </span>
                                                </p>
                                                <p>
                                                    <span>
                                                        If any provision of these Terms and Conditions is held by a
                                                        court or other tribunal of competent jurisdiction to be invalid,
                                                        illegal, or unenforceable for any reason, such provision shall
                                                        be eliminated or limited to the minimum extent such that the
                                                        remaining provisions of these Terms and Conditions will continue
                                                        in full force and effect.{' '}
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Entire Agreement</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        The Terms and Conditions and our Privacy Policy, and any
                                                        applicable conditions constitute the sole and entire agreement
                                                        between you and the Company regarding the Website and supersede
                                                        all prior and contemporaneous understandings, agreements,
                                                        representations, and warranties, both written and oral,
                                                        regarding the Website.{' '}
                                                    </span>
                                                </p>
                                                <li>
                                                    <h6 className="text-underline">Your Comments and Concerns</h6>
                                                </li>
                                                <p>
                                                    <span>
                                                        All notices of copyright infringement claims should be sent to{' '}
                                                    </span>
                                                    <span>info@joindawraty.com</span>
                                                    <span>&nbsp;in the manner and by the means set out therein.</span>
                                                </p>
                                                <p>
                                                    <span>
                                                        All other feedback, comments, requests for technical support,
                                                        and other communications relating to the Website should be
                                                        directed to:{' '}
                                                    </span>
                                                    <span>info@joindawraty.com.</span>
                                                </p>
                                            </ol>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageLayout>
            ) : (
                <Loader />
            )}
        </>
    );
}
export default TermsCondition;
