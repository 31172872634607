import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './languageSelector.css';

function LanguageSelector() {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    useEffect(() => {
        if (i18n.language !== undefined && selectedLanguage !== i18n.language) {
            setSelectedLanguage(i18n.language);
        }
    }, [i18n.language]);

    const changeLanguageHandler = async () => {
        const nextLanguage = selectedLanguage === 'ar' ? 'en' : 'ar';
        setSelectedLanguage(nextLanguage);
        // Note: AsyncStorage is not available in web, so we'll use localStorage instead
        localStorage.setItem('user-language', nextLanguage);
        // Note: I18nManager.forceRTL is not available in web, so we'll skip this
        i18n.changeLanguage(nextLanguage);
    };

    return (
        <button className="language-btn-container" onClick={changeLanguageHandler}>
            <div className="language-toggle">
                {selectedLanguage === 'en' ? (
                    <>
                        {/* <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZc1DpoXk-JxZUHBpVSM2d_O9Ozy3zwDQuEw&s"
                            alt="English flag"
                            className="flag"
                        /> */}
                        <span className="language-text">EN</span>
                    </>
                ) : (
                    <>
                        {/* <img
                            src="https://png.pngtree.com/png-vector/20221123/ourmid/pngtree-saudi-arabia-flag-round-icon-png-image_6477146.png"
                            alt="Arabic flag"
                            className="flag"
                        /> */}
                        <span className="language-text">AR</span>
                    </>
                )}
            </div>
        </button>
    );
}

export default LanguageSelector;
