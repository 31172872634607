import { userConstants } from '../constant';

const initialState = {
    loading: false,
    error: false,
    forgot: [],
};

export default function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.REQUEST_FORGOT_PASSWORD_MAIL:
            return {
                loading: true,
                error: false,
                forgot: [],
            };
        case userConstants.RECEIVE_FORGOT_PASSWORD_MAIL:
            return {
                loading: false,
                error: false,
                forgot: action.forgot.message,
            };
        case userConstants.ERROR_FORGOT_PASSWORD_MAIL:
            return {
                loading: false,
                error: true,
                forgot: action.error,
            };
        default:
            return state;
    }
}
