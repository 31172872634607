import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function MenuItem(props) {
    const { t } = useTranslation();
    const cat = props.props;
    const [showSubCategory, setShowSubCategory] = useState(false);
    return (
        <div className="mm_menu_item" key={cat?.id}>
            <div className="mm_menu_tab">
                <Link
                    to={`/courses/${cat.name}`}
                    to={{
                        pathname: `/courses/${cat.name.split(' ').join('')}`,
                        state: cat.id,
                    }}
                >
                    {t('dynamic', { en: cat.name, ar: cat?.translation?.value ? cat?.translation?.value : cat?.name })}
                </Link>
                <div
                    className="mm_menu_link"
                    onClick={() => {
                        setShowSubCategory(!showSubCategory);
                    }}
                ></div>
            </div>
            {showSubCategory && (
                <div className="sub_mm_menu">
                    <div className="sub_mm_menu_topic">
                        {cat?.sub_categories?.map((sub_cat) => (
                            <div className="sub_mm_menu_item" key={sub_cat?.id}>
                                <Link
                                    to={`/courses/${cat.name}/${sub_cat.name}`}
                                    to={{
                                        pathname: `/courses/${cat.name}/${sub_cat.name.split(' ').join('')}`,
                                        state: cat.id,
                                        sub_category: sub_cat.id,
                                    }}
                                    className="sub_mm_menu_link"
                                >
                                    {sub_cat.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
