import React, { useState } from 'react';
import Footer from '../navigation/Footer';
import Navbar from '../navigation/Navbar';
import learningImage from '../../assets/image/learning-3.png';
import '../../assets/styles/about.css';
import { withRouter, Link, useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import Login from '../modals/Login';
import Signup from '../modals/Signup';
import ForgotPassword from '../modals/ForgotPassword';
import { icons } from '../../constants/icons';
import YousefAlRumaih from '../../assets/image/about-us/Yousef-Al-Rumaih.png';
import KhaledAleidan from '../../assets/image/about-us/Khaled-Aleidan.png';
import SalmanAlOtaibi from '../../assets/image/about-us/Salman-Al-Otaibi.png';
import YousefAlsalem from '../../assets/image/about-us/Yousef-Alsalem.png';
import KhalidAlRajhi from '../../assets/image/about-us/Khalid-Al-Rajhi.png';
import AldanahDougherty from '../../assets/image/about-us/Aldanah-Dougherty.png';
import JanineDougherty from '../../assets/image/about-us/Janine-Dougherty.png';
import { useTranslation } from 'react-i18next';

function AboutUs() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [openLoginModal, closeLoginModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeLoginModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <Login forgotOpen={openForgotPasswordModal} loginClose={closeLoginModal} signupOpen={openSignupModal} />
            </ReactModal>
        );
    });
    const [openSignupModal, closeSignupModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeSignupModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <Signup closeSignup={closeSignupModal} openLogin={openLoginModal} />
            </ReactModal>
        );
    });
    const [openForgotPasswordModal, closeForgotPasswordModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeForgotPasswordModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <ForgotPassword closeForgot={closeForgotPasswordModal} openLogin={openLoginModal} />
            </ReactModal>
        );
    });

    return (
        <React.Fragment>
            <Navbar />
            <section className="about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-12">
                            <p className="title">{t('aboutUs.bannerTitle')}</p>
                            <div className="buttons">
                                <Link to={{ pathname: `/courses/all` }} className="view-courses-button btn btn_primary">
                                    {t('aboutUs.bannerButton')}
                                </Link>
                                <button className="join-button btn btn_secondary ml-3" onClick={openSignupModal}>
                                    {t('aboutUs.bannerJoin')}
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 d-flex justify-content-center">
                            <img className="" src={learningImage} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt_50 mb_50">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="page_title">{t('aboutUs.learnWithUs')}</h2>
                            <p className="text-center">{t('aboutUs.welcomeMessage')}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our_team mt_50">
                <div className="container">
                    <h2 className="page_title">{t('aboutUs.teamTitle')}</h2>
                    <div className="row">
                        <div className="col-md-4 col-12 mb-4">
                            <div className="team_card">
                                <div className="profile-container">
                                    <img className="profile" src={SalmanAlOtaibi} alt="" />
                                </div>
                                <div className="profile_details">
                                    <h3>{t('aboutUs.teams.person1.name')}</h3>
                                    <p className="bio">{t('aboutUs.teams.person1.bio')}</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4 col-12 mb-4">
                            <div className="team_card">
                                <div className="profile-container">
                                    <img className="profile" src={KhaledAleidan} alt="" />
                                </div>
                                <div className="profile_details">
                                    <h3>{t('aboutUs.teams.person2.name')}</h3>
                                    <p className="bio">{t('aboutUs.teams.person2.bio')}</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-4 col-12 mb-4">
                            <div className="team_card">
                                <div className="profile-container">
                                    <img className="profile" src={YousefAlRumaih} alt="" />
                                </div>
                                <div className="profile_details">
                                    <h3>{t('aboutUs.teams.person3.name')}</h3>
                                    <p className="bio">{t('aboutUs.teams.person3.bio')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-4">
                            <div className="team_card">
                                <div className="profile-container">
                                    <img className="profile" src={JanineDougherty} alt="" />
                                </div>
                                <div className="profile_details">
                                    <h3>{t('aboutUs.teams.person4.name')}</h3>
                                    <p className="bio">{t('aboutUs.teams.person4.bio')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-4">
                            <div className="team_card">
                                <div className="profile-container">
                                    <img className="profile" src={KhalidAlRajhi} alt="" />
                                </div>
                                <div className="profile_details">
                                    <h3>{t('aboutUs.teams.person5.name')}</h3>
                                    <p className="bio">{t('aboutUs.teams.person5.bio')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-4">
                            <div className="team_card">
                                <div className="profile-container">
                                    <img className="profile" src={YousefAlsalem} alt="" />
                                </div>
                                <div className="profile_details">
                                    <h3>{t('aboutUs.teams.person6.name')}</h3>
                                    <p className="bio">{t('aboutUs.teams.person6.bio')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mb-4">
                            <div className="team_card">
                                <div className="profile-container">
                                    <img className="profile" src={AldanahDougherty} alt="" />
                                </div>
                                <div className="profile_details">
                                    <h3>{t('aboutUs.teams.person7.name')}</h3>
                                    <p className="bio">{t('aboutUs.teams.person7.bio')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default withRouter(AboutUs);
