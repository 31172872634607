import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteAddressApi } from '../services/address';
import { showError, showSuccess, Spinner } from '../Utils/helper';
import { useTranslation } from 'react-i18next';

export default function AddressCard(props) {
    const { t } = useTranslation();
    const { item = {} } = props;
    const history = useHistory();
    const [deleteSpinner, setDeleteSpinner] = useState(false);

    async function handleDelete(id) {
        setDeleteSpinner(true);
        const resp = await deleteAddressApi(id);
        if (resp.success) {
            showSuccess(t('alertMessage.success'));
            window.location.reload();
        } else {
            showError(t('alertMessage.wrong'));
        }
        setDeleteSpinner(false);
    }

    return (
        <div className="col-12 col-md-12 col-lg-12 pb_20">
            <div className="address_box">
                <div className="category">
                    {item.category}
                    <div className="float-right">
                        <button
                            className="btn_primary small_btn mr-3 mb-1"
                            onClick={() => {
                                history.push({
                                    pathname: '/addresses/' + item.id,
                                    search: 'edit',
                                });
                            }}
                        >
                            <div className="btn_text">{t('common.edit')}</div>
                        </button>
                        <button
                            className="btn_danger small_btn mb-1"
                            onClick={() => {
                                handleDelete(item.id);
                            }}
                        >
                            <div className="btn_text">
                                {deleteSpinner && <span className="mr-2 spinner-border spinner-border-sm" />}
                                Delete
                            </div>
                        </button>
                    </div>
                </div>
                <div className="hr-line mb_20"></div>
                {item.country_id === 112 ? (
                    <div className="address">{`${item?.apartment ? item?.apartment + ',' : ''} ${item?.house ? item?.house + ',' : ''} ${item?.block ? item?.block + ',' : ''} ${item?.street ? item?.street + ',' : ''} ${item?.city ? item?.city + ',' : ''} - ${item?.country?.name}`}</div>
                ) : (
                    <div className="address">{`${item?.address}, ${item?.city}, ${item?.state}, ${item?.pin_code} - ${item?.country?.name}`}</div>
                )}
            </div>
        </div>
    );
}
