import { getAuthDataCall, postAuthDataCall, putAuthDataCall } from '../utils';

export const getStudentOrders = async () => {
    const resp = await getAuthDataCall(`/auth/studentOrder`);
    return resp;
};

export const getStudentCourse = async () => {
    const resp = await getAuthDataCall(`/auth/studentCourse`);
    return resp;
};

export const getCourseById = async (_id) => {
    const resp = await getAuthDataCall(`/auth/studentCourse/${_id}`);
    return resp;
};

export const lessonCompletionUpdate = async (payload) => {
    const resp = await postAuthDataCall(`/auth/lessonUpdates`, payload);
    return resp;
};

export const getStudentCredit = async (_id) => {
    const resp = await getAuthDataCall(`/auth/credit/${_id}`);
    return resp;
};

export const getStudentThreads = async (_id) => {
    const resp = await getAuthDataCall(`/auth/threads/${_id}`);
    return resp;
};

export const putStudentThreads = async (_id, payload) => {
    const resp = await putAuthDataCall(`/auth/threads/${_id}`, payload);
    return resp;
};

export const setCurrentYear = async (payload) => {
    const resp = await postAuthDataCall(`/auth/setCurrentYear`, payload);
};
