import React, { useState, useEffect, useContext } from 'react';
import PageLayout from '../Layouts/PageLayout';
import { icons } from '../../constants/icons';
import Loader from '../Common/Loader';
import MainHeader from '../header';
import Empty from '../empty';
import { UserContext } from '../context/AuthContext';
import { getNotificationsList } from '../services/notifications';
import { showError, Spinner } from '../Utils/helper';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Notifications() {
    const { t } = useTranslation();
    const { user, loadingUser, resetNotification } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);

    async function getNotifications() {
        setLoading(true);
        const resp = await getNotificationsList();
        if (resp.success) {
            setNotifications(resp?.data);
        } else {
            showError(t('alertMessage.wrong'));
        }
        resetNotification();
        setLoading(false);
    }

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <>
            {!loadingUser ? (
                <PageLayout me={user}>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center p_100">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <MainHeader title="Notifications" />
                            {notifications.length > 0 ? (
                                <div className="container mp-3 py-3">
                                    <div className="row justify-content-center">
                                        {notifications?.map((notification, index) => (
                                            <div className="col-10 card p-2 mb-2" key={index}>
                                                <div className="notify_row">
                                                    <figure>{/* <img src={icons.notification_1} alt=""/> */}</figure>
                                                    <Link
                                                        to={{
                                                            pathname:
                                                                notification?.notification_type.name === 'doubt'
                                                                    ? '/chat/' + notification.created_by
                                                                    : '',
                                                            state: { data: notification.sender_details },
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="notify_content">
                                                            <p>
                                                                {t(
                                                                    'notification.' + notification.title,
                                                                    notification.title
                                                                )}
                                                            </p>
                                                            {notification?.notification_type.name === 'doubt' ? (
                                                                <p>
                                                                    from -{' '}
                                                                    {notification.first_name +
                                                                        ' ' +
                                                                        notification.last_name}
                                                                </p>
                                                            ) : (
                                                                ''
                                                            )}
                                                            <span>{notification.human_readable_created_at}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <Empty title={t('common.noNotificationsFound')} />
                            )}
                        </>
                    )}
                </PageLayout>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default Notifications;
