import { userConstants } from '../constant';

const initialState = {
    loading: false,
    error: false,
    register: [],
};

export default function register(state = initialState, action) {
    switch (action.type) {
        case userConstants.REGISTER_STUDENT_REQUEST:
            return {
                loading: true,
                error: false,
                register: [],
            };
        case userConstants.REGISTER_STUDENT_SUCCESS:
            return {
                loading: false,
                error: false,
                register: action.register,
            };
        case userConstants.REGISTER_STUDENT_FAILURE:
            return {
                error: true,
                loading: false,
                register: action.error,
            };
        default:
            return state;
    }
}
