import React, { useState, useEffect } from 'react';
import { forgotPasswordMailActions } from '../../containers/user/actions/forgotPasswordMail';
import { forgotVerifyOtpActions } from '../../containers/user/actions/forgotVerifyOtp';
import { newPasswordActions } from '../../containers/user/actions/newPassword';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param {forgot password authentication} author by Bilal Bhojani
 * @returns
 */

function ForgotPassword({ closeForgot, openLogin }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const forgotPasswordMail = useSelector((state) => state.forgotPasswordMail);
    const forgotVerifyOtp = useSelector((state) => state.forgotVerifyOtp);
    const newPassword = useSelector((state) => state.newPassword);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [toggle, setToggle] = useState(false);
    const [passwordToggle, setPasswordToggle] = useState(false);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    // const [otpError, setOtpError] = useState(false);
    // const [otpErrorMessage, setOtpErrorMessage] = useState(false);
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');

    function sendMail(event) {
        event.preventDefault();
        dispatch(forgotPasswordMailActions.forgotPassword(email));
    }
    function verifyOTP(event) {
        event.preventDefault();
        if (otp.trim().length < 5) {
            setError(true);
            setErrorMsg('OTP must be of 6 characters');
            return;
        }
        dispatch(forgotVerifyOtpActions.verifyOtp(email, otp));
    }
    function changePassword(event) {
        event.preventDefault();
        if (password !== cpassword) {
            return;
        }
        dispatch(newPasswordActions.newPassword(email, password, cpassword));
    }
    useEffect(() => {
        if (
            !forgotPasswordMail.loading &&
            !forgotPasswordMail.error &&
            forgotPasswordMail.forgot === 'Otp Sended To Your Email !!'
        ) {
            setError(false);
            setToggle(true);
            return;
        }
        if (
            !forgotPasswordMail.loading &&
            !forgotPasswordMail.error &&
            forgotPasswordMail.forgot === 'Given Email not Found'
        ) {
            setError(true);
            setErrorMsg(forgotPasswordMail.forgot);
            return;
        }
    }, [forgotPasswordMail]);
    useEffect(() => {
        if (
            !forgotVerifyOtp.loading &&
            !forgotVerifyOtp.error &&
            forgotVerifyOtp.forgot === 'Otp verified Successfully for forgot password !!'
        ) {
            setError(false);
            setToggle(false);
            setPasswordToggle(true);
            return;
        }
        if (!forgotVerifyOtp.loading && !forgotVerifyOtp.error && forgotVerifyOtp.forgot === 'Invalid Otp') {
            setError(true);
            setErrorMsg(forgotVerifyOtp.forgot);
            return;
        }
    }, [forgotVerifyOtp]);
    useEffect(() => {
        if (!newPassword.error && !newPassword.loading && newPassword.password === 'Password updated successfully.') {
            window.location.reload();
            return;
        }

        if (newPassword.password === 'Please enter a password which is not similar then current password.') {
            setError(true);
            setErrorMsg('Please enter a password which is not similar then current password.');
            return;
        }
    }, [newPassword]);
    function goToLogin() {
        setToggle(false);
        setPasswordToggle(false);
        closeForgot();
        openLogin();
    }
    return (
        <div>
            {!toggle && !passwordToggle ? (
                <>
                    <div className="lrf_modal">
                        <h2>Forgot Password</h2>
                        <div className="lrf_subtext">
                            Please enter your username or email address. You will receive a link to create a new
                            password via email. Remember now?{' '}
                            <a href="#" onClick={goToLogin}>
                                Back to Login
                            </a>
                        </div>
                        <form onSubmit={sendMail}>
                            <div className="form-group">
                                <label for="">{t('common.email')}</label>
                                <div className="form_input">
                                    <input
                                        type="email"
                                        className="form-control"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        placeholder=""
                                    />
                                </div>
                            </div>
                            {error && <p className="text-danger">{errorMsg}</p>}
                            <button type="submit" className="btn_primary full_btn big_btn">
                                {!forgotPasswordMail.error && forgotPasswordMail.loading && (
                                    <span className="mr-2 spinner-border spinner-border-sm"></span>
                                )}
                                Reset Password
                            </button>
                        </form>
                    </div>
                </>
            ) : toggle && !passwordToggle ? (
                <>
                    <div className="lrf_modal">
                        {/* <h2>Enter OTP</h2> */}
                        <div className="lrf_subtext">OTP sent to {email}</div>
                        <form onSubmit={verifyOTP}>
                            <div className="form-group">
                                <label for="">Enter OTP</label>
                                <div className="form_input">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter OTP"
                                        value={otp}
                                        onChange={(e) => {
                                            setOtp(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            {error && <p className="text-danger">{errorMsg}</p>}
                            <button type="submit" className="btn_primary full_btn big_btn">
                                {!forgotVerifyOtp.error && forgotVerifyOtp.loading && (
                                    <span className="mr-2 spinner-border spinner-border-sm"></span>
                                )}
                                Reset Password
                            </button>
                        </form>
                    </div>
                </>
            ) : !toggle && passwordToggle ? (
                <>
                    <div className="lrf_modal">
                        <h2>Set New Password</h2>
                        <form onSubmit={changePassword}>
                            <div className="form-group">
                                <label for="">New Password</label>
                                <div className="form_input">
                                    <input
                                        className="form-control"
                                        value={password}
                                        type="password"
                                        placeholder="confirm password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="">Confirm New Password</label>
                                <div className="form_input">
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={cpassword}
                                        onChange={(e) => {
                                            setCPassword(e.target.value);
                                        }}
                                        placeholder="Re-Enter Password"
                                    />
                                </div>
                            </div>
                            {error && <p className="text-danger">{errorMsg}</p>}
                            <button type="submit" className="btn_primary full_btn big_btn">
                                {!newPassword.error && newPassword.loading && (
                                    <span className="mr-2 spinner-border spinner-border-sm"></span>
                                )}
                                Set Password
                            </button>
                        </form>
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default ForgotPassword;
