import React, { useState, useContext, useEffect, useRef } from 'react';
import PageLayout from '../Layouts/PageLayout';
import { Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import { withRouter, useLocation } from 'react-router-dom';
// import ReactAudioPlayer from "react-audio-player";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import 'video-react/dist/video-react.css'; // import css
import {
    Player,
    ControlBar,
    PlayToggle,
    ReplayControl,
    ForwardControl,
    BigPlayButton,
    PlaybackRateMenuButton,
} from 'video-react';
import { getCourseById } from '../services/instructors';
import { showError } from '../Utils/helper';
import Loader from '../Common/Loader';
import { ToastContainer } from 'react-toastify';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { UserContext } from '../context/AuthContext';
import Quiz from './Quiz';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import PDFReader from '../pdfReader';
import { useTranslation } from 'react-i18next';
import getTranslationFromMany from '../../services/helper';

const InstructorCourse = (props) => {
    const { t } = useTranslation();
    const playerRef = useRef(null);
    const { loggedIn } = useContext(UserContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [view, setView] = useState('');
    const [videoState, setVideoState] = useState(null);
    const [currentLesson, setCurrentLesson] = useState(null);
    const [counter, setCounter] = useState(false);
    const [videoEnd, setVideoEnd] = useState(false);
    const [isDownloadable, setIsDownloadable] = useState(false);
    const course_id = props.location.state;
    const [uniqueId, setUniqueId] = useState('');

    useEffect(() => {
        if (!loggedIn) {
            <Redirect to="/" />;
        }
    }, [loggedIn]);

    useEffect(() => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        toGetCourseById();
    }, []);

    async function toGetCourseById() {
        setLoading(true);
        const resp = await getCourseById(course_id);
        if (resp.success) {
            setData(resp.data);
            if (resp?.data?.chapter[0]?.lessons[0]?.media[0]?.is_downloadable === 1) {
                setIsDownloadable(true);
            }
            setVideoUrl(resp?.data?.chapter[0]?.lessons[0]?.media[0]?.url);
            setCurrentLesson(resp?.data?.chapter[0]?.lessons[0]);
            setView(
                resp?.data?.chapter[0]?.lessons[0]?.quiz?.length > 0
                    ? 'quiz'
                    : resp?.data?.chapter[0]?.lessons[0]?.media[0]?.media_type
            );
            setLoading(false);
        } else {
            showError('Somthing went wrong');
            setLoading(false);
        }
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <PageLayout>
                    <div className="container-fluid ptb_50">
                        <h1 className="page_title text-left">{data?.name}</h1>
                        {/* <h2 className="topic_name">Course Topic Name</h2> */}
                        <div className="course_wrapper">
                            {view === 'video' ? (
                                <div className="course_video">
                                    <Player src={process.env.REACT_APP_CONTENT_URL + videoUrl} ref={playerRef}>
                                        <BigPlayButton position="center" />
                                        <ControlBar autoHide={false}>
                                            <PlayToggle />
                                            <ReplayControl seconds={10} order={2.2} />
                                            <ForwardControl seconds={10} order={3.2} />
                                            <PlaybackRateMenuButton rates={[2, 1, 1.75, 1.5]} />
                                        </ControlBar>
                                    </Player>
                                </div>
                            ) : view === 'pdf' ? (
                                <PDFReader videoUrl={videoUrl} isDownloadable={isDownloadable} />
                            ) : view === 'image' ? (
                                <img
                                    src={process.env.REACT_APP_CONTENT_URL + videoUrl}
                                    height="720"
                                    style={{
                                        width: '66%',
                                        height: '720px',
                                    }}
                                    alt={data?.name}
                                />
                            ) : view === 'audio' ? (
                                <div className="course_video">
                                    <AudioPlayer
                                        autoPlay
                                        src={process.env.REACT_APP_ASSETS + videoUrl}
                                        showSkipControls={false}
                                        loop={false}
                                    />
                                </div>
                            ) : (
                                <Quiz key={uniqueId} quiz={currentLesson} course_id={course_id} />
                            )}

                            <div className="course_topic">
                                <div className="topic_box ptb_30">
                                    <h3 className="text-center mb_20">{t('course.courseContent')}</h3>
                                    <Accordion className=".scrollable-content">
                                        {data?.chapter?.map((_chapter) => {
                                            return (
                                                <AccordionItem key={_chapter?.id}>
                                                    {/* {_chapter?.name} */}
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>{_chapter?.name}</AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel className="accordion-item-panel">
                                                        {_chapter?.lessons?.map((_lesson) => {
                                                            return (
                                                                <div
                                                                    className={`topic_row ptb_10 plr_30 ${
                                                                        currentLesson?.id === _lesson?.id
                                                                            ? 'active_chapter'
                                                                            : 'inactive'
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="topic_info"
                                                                        onClick={() => {
                                                                            if (_lesson?.type === 'quiz') {
                                                                                setCurrentLesson(_lesson);
                                                                                setView(_lesson?.type);
                                                                            } else {
                                                                                setVideoUrl(_lesson?.media[0]?.url);
                                                                                setCurrentLesson(_lesson);
                                                                                setVideoEnd(false);
                                                                                setView(_lesson?.media[0].media_type);
                                                                            }
                                                                            setUniqueId('prefix-' + _lesson?.id);
                                                                        }}
                                                                    >
                                                                        <h6>{_lesson?.name}</h6>
                                                                        <div className="topic_time">
                                                                            {/*<figure>*/}
                                                                            {/*  <svg*/}
                                                                            {/*    id="play_circle-24px"*/}
                                                                            {/*    width="24"*/}
                                                                            {/*    height="24"*/}
                                                                            {/*    viewBox="0 0 24 24"*/}
                                                                            {/*  >*/}
                                                                            {/*    <g id="Group_94" data-name="Group 94">*/}
                                                                            {/*      <rect*/}
                                                                            {/*        id="Rectangle_457"*/}
                                                                            {/*        data-name="Rectangle 457"*/}
                                                                            {/*        width="24"*/}
                                                                            {/*        height="24"*/}
                                                                            {/*        fill="none"*/}
                                                                            {/*      />*/}
                                                                            {/*    </g>*/}
                                                                            {/*    <g id="Group_95" data-name="Group 95">*/}
                                                                            {/*      <path*/}
                                                                            {/*        id="Path_227"*/}
                                                                            {/*        className="topic_time_icon"*/}
                                                                            {/*        data-name="Path 227"*/}
                                                                            {/*        d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM9.5,16.5v-9l7,4.5Z"*/}
                                                                            {/*        fill="gray"*/}
                                                                            {/*      />*/}
                                                                            {/*    </g>*/}
                                                                            {/*  </svg>*/}
                                                                            {/*</figure>*/}
                                                                            {/*<small>10 min</small>*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            );
                                        })}
                                    </Accordion>
                                </div>
                            </div>
                            <div className="course_overview">
                                <div className="mt_20">
                                    <ul className="nav nav-tabs mb_50" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link active"
                                                id="Overview-tab"
                                                data-toggle="tab"
                                                href="#Overview"
                                                role="tab"
                                                aria-controls="Overview"
                                                aria-selected="true"
                                            >
                                                {t('common.overview')}
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link"
                                                id="qaa-tab"
                                                data-toggle="tab"
                                                href="#qaa"
                                                role="tab"
                                                aria-controls="qaa"
                                                aria-selected="false"
                                            >
                                                {t('common.Q&A')}
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="Overview"
                                            role="tabpanel"
                                            aria-labelledby="Overview-tab"
                                        >
                                            <h3>{t('common.aboutCourse')}</h3>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: t('dynamic', {
                                                        en: data?.description?.replace(/['"]+/g, ''),
                                                        ar: getTranslationFromMany(
                                                            data?.translation,
                                                            'description',
                                                            data?.description?.replace(/['"]+/g, '')
                                                        ),
                                                    }),
                                                }}
                                            />

                                            <h3 className="mb_10 mt_20">{t('common.learningObjectives')}</h3>
                                            <ul className="circle_tick_list">
                                                {data?.course_objectives?.map((obj) => (
                                                    <li key={obj?.id}>
                                                        {t('dynamic', {
                                                            en: obj?.objective,
                                                            ar: obj?.translation?.value
                                                                ? obj?.translation?.value
                                                                : obj?.objective,
                                                        })}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="qaa"
                                            role="tabpanel"
                                            aria-labelledby="qaa-tab"
                                        >
                                            <Accordion>
                                                {data?.question_answers?.map((_item) => (
                                                    <AccordionItem>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>{_item?.question}</AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <p>{_item?.answer}</p>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </PageLayout>
            )}
        </>
    );
};

export default withRouter(InstructorCourse);
