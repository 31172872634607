import { utilsConstants } from '../constants';

const initialState = {
        loading: true,
        error: false,
        country: [],
    },
    UserReducer = (state = initialState, action) => {
        switch (action.type) {
            case utilsConstants.REQUEST_COUNTRY:
                return {
                    ...state,
                    loading: true,
                };
            case utilsConstants.RECIEVE_COUNTRY:
                return Object.assign({}, state, {
                    loading: false,
                    error: false,
                    country: action.country,
                });
            case utilsConstants.ERROR_COUNTRY:
                return {
                    ...state,
                    loading: false,
                    error: true,
                };
            default:
                return state;
        }
    };

export default UserReducer;
