import axios from 'axios';
import Cookies from 'js-cookie';

const header = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
const authHeader = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + Cookies.get('EAPISID'),
        'access-control-allow-origin': '*',
    },
});

export const getDataCall = async (url) => {
    const response = await header.get(url);
    return response.data;
};

export const postDataCall = async (url, payload) => {
    const response = await header.post(url, payload);
    // logic if needed
    return response.data;
};
export const getAuthDataCall = async (url) => {
    const response = await authHeader.get(url);
    return response.data;
};

export const getAuthDataCall2 = (url) => {
    const response = authHeader
        .get(url)
        .then((res) => res.data)
        .catch((error) => error.response.data);
    return response;
};

export const postAuthDataCall = async (url, payload) => {
    return await authHeader
        .post(url, payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response;
        });
    // logic if needed
};

// export const putAuthDataCall = async(url, payload) => {
//   return await authHeader.put(url, payload).then(res => {
//     console.log('worked');
//     return res.data;
//   }).catch(err => {
//     console.log(err.response);
//     return err.response;
//   });
// };

export const postAuthDataCall2 = async (url, payload) => {
    const response = authHeader
        .post(url, payload)
        .then((res) => res.data)
        .catch((error) => error.response.data);
    return response;
};

export const deleteAuthDataCall = async (url) => {
    const response = await authHeader.delete(url);
    return response.data;
};

export const dateFormat = (date) => {
    let d = new Date(date);
    return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
};

export const putAuthDataCall = async (url, payload) => {
    return await authHeader
        .put(url, payload)
        .then((res) => {
            console.log('worked');
            return res.data;
        })
        .catch((err) => {
            console.log(err.response);
            return err.response;
        });
};
