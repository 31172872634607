import { userConstants } from '../constant';
import { userService } from '../services';

export const forgotPasswordMailActions = {
    forgotPassword,
};

function forgotPassword(email) {
    return (dispatch) => {
        dispatch(request({ email }));
        userService
            .forgotPasswordSendMail(email)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(success(response.data));
                } else {
                    dispatch(failure(response.data));
                }
            })
            .catch((error) => {
                dispatch(failure(error));
            });
    };

    function request(user) {
        return { type: userConstants.REQUEST_FORGOT_PASSWORD_MAIL, user };
    }
    function success(forgot) {
        return { type: userConstants.RECEIVE_FORGOT_PASSWORD_MAIL, forgot };
    }
    function failure(error) {
        return { type: userConstants.ERROR_FORGOT_PASSWORD_MAIL, error };
    }
}
