export const messages = {
    //sign up page
    FNAME_ERROR: 'First name is too short. Atleast 3 character is must',
    LNAME_ERROR: 'Last name is too short. Atleast 3 character is must',
    PASSWORD_ERROR: 'Password and confirm password are mismatching',
    PRIVACY_ERROR: 'Please accept the privacy policy',
    LOGIN_ERROR: 'Invalid credentials',
    LOGIN_FAILURE_DEVICE: 'Device Limit exceeded Please Contact Dawraty Support for Further assistance.',
    PASSWORD_SHORT: 'Password must be of atleast 6 characters',
    COUNTRY_ERROR: 'Please select country',
    MOBILE_ERROR: 'Please enter the mobile no',
    FILE_ERROR: 'Please upload file in pdf/doc/docx format only',
    NO_RESULTS_FOUND: 'No Results Found',
};

export const ar_messages = {
    //sign up page
    FNAME_ERROR: 'First name is too short. Atleast 3 character is must',
    LNAME_ERROR: 'Last name is too short. Atleast 3 character is must',
    PASSWORD_ERROR: 'Password and confirm password are mismatching',
    PRIVACY_ERROR: 'Please accept the privacy policy',
    LOGIN_ERROR: 'Invalid credentials',
    LOGIN_FAILURE_DEVICE: 'Device Limit exceeded Please Contact Dawraty Support for Further assistance.',
    PASSWORD_SHORT: 'Password must be of atleast 6 characters',
    COUNTRY_ERROR: 'Please select country',
    MOBILE_ERROR: 'Please enter the mobile no',
    FILE_ERROR: 'Please upload file in pdf/doc/docx format only',
    NO_RESULTS_FOUND: 'لم يتم العثور على نتائج',
};

// universal constant message
