import React from 'react';
import { Helmet } from 'react-helmet';

function MetaData(props) {
    let { title, description, image } = props;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="https://joindawraty.com" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta property="og:title" content={title} />
            <meta property="og:site_name" content="Dawraty.com" />
            <meta property="og:url" content="https://joindawraty.com" />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="product" />
            <meta property="og:image" content={image} />
        </Helmet>
    );
}

export default MetaData;
