import { getAuthDataCall, getDataCall, postAuthDataCall, deleteAuthDataCall } from '../utils';
import axios from 'axios';

export const getCoursesList = async () => {
    const resp = await getAuthDataCall(`/v1/course_instructor`);
    return resp;
};

export const searchCourse = async (param) => {
    const resp = await getDataCall(`/v1/Courses?search=${param}`);
    return resp;
};

export const addToCart = async (payload) => {
    const resp = await postAuthDataCall('/auth/carts', payload);
    return resp;
};

export const addToCartBulk = async (payload, token) => {
    const authHeader = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'access-control-allow-origin': '*',
        },
    });

    const response = await authHeader.post('/auth/carts?get_by=bulk_course', payload);
    return response;
};

export const getCartDetails = async () => {
    const resp = await getAuthDataCall('/auth/carts');
    return resp;
};

export const getCartCount = async () => {
    const resp = await getAuthDataCall('auth/cart_count');
    return resp;
};
export const deleteCourseCart = async (id) => {
    const resp = await deleteAuthDataCall(`auth/carts/${id}`);
    return resp;
};
export const emptyUserCart = async () => {
    const resp = await deleteAuthDataCall('/auth/emptyCart');
    return resp;
};

export const deleteChapter = async (course_id, chap_id) => {
    const resp = await deleteAuthDataCall(`auth/carts/${course_id}?chapter=${chap_id}`);
    return resp;
};

export const addToWishList = async (payload) => {
    const resp = await postAuthDataCall('/auth/wishLists', payload);
    return resp;
};

export const getWishlist = async () => {
    const resp = await getAuthDataCall('/auth/wishLists');
    return resp;
};

export const deleteWishlist = async (id) => {
    const resp = await deleteAuthDataCall(`auth/wishLists/${id}`);
    return resp;
};
