import { postAuthDataCall, getAuthDataCall } from '../utils';

export const upvoteFlashcard = async (payload) => {
    const resp = await postAuthDataCall(`/auth/flashcards/upvote`, payload);
    return resp;
};

export const unupvoteFlashcard = async (payload) => {
    const resp = await postAuthDataCall(`/auth/flashcards/unupvote`, payload);
    return resp;
};

export const downvoteFlashcard = async (payload) => {
    const resp = await postAuthDataCall(`/auth/flashcards/downvote`, payload);
    return resp;
};

export const undownvoteFlashcard = async (payload) => {
    const resp = await postAuthDataCall(`/auth/flashcards/undownvote`, payload);
    return resp;
};

export const favoriteFlashcard = async (payload) => {
    const resp = await postAuthDataCall(`/auth/flashcards/favorite`, payload);
    return resp;
};

export const reportFlashcard = async (payload) => {
    const resp = await postAuthDataCall('/auth/flashcards/report_issue', payload);
    return resp;
};

export const getFlashcards = async (course_id, chapter_id) => {
    const resp = await getAuthDataCall(`/auth/flashcards?course_id=${course_id}&chapter_id=${chapter_id}`);
    return resp;
};

export const upvoteQuizQuestion = async (payload) => {
    const resp = await postAuthDataCall(`/auth/ai_quizzes/upvote`, payload);
    return resp;
};

export const unupvoteQuizQuestion = async (payload) => {
    const resp = await postAuthDataCall(`/auth/ai_quizzes/unupvote`, payload);
    return resp;
};

export const downvoteQuizQuestion = async (payload) => {
    const resp = await postAuthDataCall(`/auth/ai_quizzes/downvote`, payload);
    return resp;
};

export const undownvoteQuizQuestion = async (payload) => {
    const resp = await postAuthDataCall(`/auth/ai_quizzes/undownvote`, payload);
    return resp;
};

export const favoriteQuizQuestion = async (payload) => {
    const resp = await postAuthDataCall(`/auth/ai_quizzes/favorite`, payload);
    return resp;
};

export const getQuizQuestions = async (course_id, chapter_id) => {
    const resp = await getAuthDataCall(`/auth/ai_quizzes?course_id=${course_id}&chapter_id=${chapter_id}`);
    return resp;
};

export const reportQuizQuestion = async (payload) => {
    const resp = await postAuthDataCall('/auth/ai_quizzes/report_issue', payload);
    return resp;
};

export const reportTutorResponse = async (payload) => {
    const resp = await postAuthDataCall('/auth/ai_tutor/report_issue', payload);
    return resp;
};
