import { userConstants } from '../../user/constant';

const initialState = {
        loading: true,
        error: false,
        user: [],
    },
    UserReducer = (state = initialState, action) => {
        switch (action.type) {
            case userConstants.REQUEST_USER:
                return {
                    ...state,
                    loading: true,
                };
            case userConstants.RECEIVE_USER:
                return Object.assign({}, state, {
                    loading: false,
                    error: false,
                    user: action.user,
                });
            case userConstants.ERROR_USER:
                return {
                    ...state,
                    loading: false,
                    error: true,
                    user: action.user,
                };
            default:
                return state;
        }
    };

export default UserReducer;
