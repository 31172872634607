import { toast } from 'react-toastify';
import moment, { lang, utc } from 'moment';
import {
    addToCart,
    addToWishList,
    deleteCourseCart,
    getCartCount,
    getCartDetails,
    emptyUserCart,
} from '../services/courses/index';
import { Trans, useTranslation } from 'react-i18next';

export const showError = (message, time = 2000, position = 'bottom-right') => {
    toast.error(message, {
        hideProgressBar: true,
        position: position,
        autoClose: time,
    });
};

export const scrollToSelector = (selector) => {
    const section = document.querySelector('#' + selector);
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const showSuccess = (message) => {
    toast.success(message, {
        hideProgressBar: true,
        position: 'bottom-right',
        autoClose: 2000,
    });
};

export const Spinner = () => {
    return (
        <div
            className="spinner"
            style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginRight: '5px',
            }}
        />
    );
};

export const addCourseToCart = async (payload, helperProps, loaderText, data, customMsg, openEmptyCartModal) => {
    // const differingRegions = checkDifferingCourseRegions(helperProps.cartData, data, openEmptyCartModal);
    // if (differingRegions) return;
    //loop here if data.countryId is not the same as cart.courses.country id then dont add, differing regions
    if (!helperProps.loggedIn) {
        addToCartLocalStorage(helperProps, data, customMsg);
    } else {
        helperProps.setLoadingCart(loaderText);
        const resp = await addToCart(payload);
        if (resp.success) {
            showSuccess(customMsg?.addToCartSuccess);
            const _count = await getCartCount();
            const _resp = await getCartDetails();
            helperProps.setCartCount(_count.data);
            helperProps.setCartData(_resp.data);
            helperProps.setLoadingCart(null);
        } else {
            helperProps.setLoadingCart(null);
            showSuccess(customMsg?.courseExist);
        }
    }
};

function addToCartLocalStorage(helperProps, data, customMsg) {
    let payload = JSON.parse(localStorage.getItem('cartCourse')) || [];
    let count = 0;
    if (payload.length === 0) {
        payload = [data, ...payload];
        localStorage.setItem('cartCourse', JSON.stringify(payload));
        helperProps.setCartCount(helperProps.cartCount + 1);
        helperProps.setCartData(JSON.parse(localStorage.getItem('cartCourse')));
        showSuccess(customMsg?.addToCartSuccess);
        return;
    }
    payload.map((_item) => {
        if (_item.id === data.id) {
            showSuccess(customMsg.courseExist);
            count++;
        }
    });
    if (count === 0) {
        let newPayload = [data, ...payload];
        localStorage.setItem('cartCourse', JSON.stringify(newPayload));
        helperProps.setCartCount(helperProps.cartCount + 1);
        helperProps.setCartData(JSON.parse(localStorage.getItem('cartCourse')));
        showSuccess(customMsg?.addToCartSuccess);
    }
}

// const checkDifferingCourseRegions = (cartItems, course, openEmptyCartModal) => {
//   if(cartItems === null) return; //cart is empty
//   for (let item of cartItems) {
//     if (item.courses.country_id !== course.country_id) {
//       openEmptyCartModal();
//       return true
//     }
//   } return false;
//   return false;
// }

export const removeCourseFromCart = async (data, helperProps, loaderText, history, customMsg) => {
    if (!helperProps.loggedIn) {
        let payload = JSON.parse(localStorage.getItem('cartCourse'));
        let newPayload = payload.filter((_item) => _item.id !== data.id);
        localStorage.setItem('cartCourse', JSON.stringify(newPayload));
        helperProps.setCartCount(helperProps.cartCount - 1);
        helperProps.setCartData(JSON.parse(localStorage.getItem('cartCourse')));
        showSuccess(customMsg?.removeFromCart);
    } else {
        helperProps.setLoadingCart(loaderText);
        const resp = await deleteCourseCart(data.course_id);
        if (resp.success) {
            showSuccess(customMsg?.removeFromCart);
            if (history.location.pathname === '/checkout') {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }

            const _count = await getCartCount();
            const _resp = await getCartDetails();
            helperProps.setCartCount(_count.data);
            helperProps.setCartData(_resp.data);
            helperProps.setLoadingCart(null);
        } else {
            helperProps.setLoadingCart(null);
            showSuccess(customMsg?.removeFromCart);
        }
    }
};

export const emptyCart = async (loggedIn, setCartCount, setCartData, setLoadingCart) => {
    if (!loggedIn) {
        console.log('hit');
        localStorage.setItem('cartCourse', null);
        setCartCount(0);
        setCartData([]);
        // showSuccess("Cart successfully emptied.")
    } else {
        const resp = await emptyUserCart();
        if (resp.success) {
            const _count = await getCartCount();
            const _resp = await getCartDetails();
            setCartCount(_count.data);
            setCartData(_resp.data);
            showSuccess('Cart successfully emptied.');
        } else {
            showError('Something went wrong.');
        }
    }
};

export const addToWishListData = async (payload, helperProps) => {
    if (!helperProps.loggedIn) {
        showSuccess(helperProps.t('alertMessage.loginWishlist'));
    } else {
        helperProps.setLoadingCart('wishlist-loading');
        const resp = await addToWishList(payload);
        if (resp.success) {
            showSuccess(helperProps.t('alertMessage.addedWishlist'));
            helperProps.setLoadingCart(null);
        } else {
            showSuccess(helperProps.t('alertMessage.wishListExists'));
            helperProps.setLoadingCart(null);
        }
    }
};

let format = 'MMM yyyy';
export const utcToLocalDateTime = (utcTime) => {
    try {
        const utc_timeZ = moment.utc(utcTime).format('DD MMM YYYY');
        // const localTime = moment.utc(utc_timeZ).toDate();
        // const formatString = moment(localTime).format(format);
        // return formatString == "Invalid date" ? "-" : formatString;
        return utc_timeZ;
    } catch (e) {
        return '-';
    }
};

export const utcToLocalDateTimeLangWise = (utcTime, lang = 'en-US') => {
    try {
        const date = new Date(Date(utcTime));
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(lang, options);
    } catch (e) {
        return '-';
    }
};

export const addCourseToCartBulk = (token, _cartCourse) => {
    let _payload;
    _cartCourse.map((_item) => {
        // console.log(_item);
        // _payload.push()
    });
};

export const kuwaitGovernatesAr = {
    'العاصمة': [
        'ضاحية عبد الله السالم',
        'العديلية',
        'بنيد القار',
        'الدعية',
        'الدسمة',
        'الدوحة',
        'ميناء الدوحة',
        'الفيحاء',
        'فيلكا',
        'غرناطة',
        'مدينة جابر الأحمد',
        'جِبْلَة',
        'كيفان',
        'الخالدية',
        'المنصورية',
        'المرقاب',
        'النهضة',
        'شمال غرب الصليبيخات',
        'النزهة',
        'القادسية',
        'قرطبة',
        'الروضة',
        'الشامية',
        'شرق',
        'الشويخ',
        'الشويخ الصناعية',
        'ميناء الشويخ',
        'الصليبخات',
        'السرة',
        'جزيرة ام النمل',
        'اليرموك',
        'الصالحية',
        'قبلة',
        'عبدالله السالم',
    ],
    'الأحمدي': [
        'أبو حليفة',
        'ميناء عبد الله',
        'الأحمدي',
        'علي صباح السالم',
        'العقيلة',
        'بر الأحمدي',
        'بنيدر',
        'بر الأحمدي الظهر',
        'الفحيحيل',
        'فهد الأحمد',
        'هدية',
        'جابر العلي',
        'جواخير الوفرة',
        'الجليعة',
        'الخيران',
        'المهبولة',
        'المنقف',
        'المقوع',
        'مدينة الخيران الجديدة',
        'الوفرة الجديدة',
        'النويصيب',
        'الرقة',
        'مدينة صباح الأحمد',
        'مدينة صباح الأحمد البحرية',
        'الصباحية',
        'الشعيبة',
        'جنوب الصباحية',
        'الوفرة',
        'الزور',
        'الظهر',
    ],
    'الفروانية': [
        'عبدالله المبارك',
        'منطقة المطار',
        'الأندلس',
        'العارضية',
        'العارضية حرفية',
        'العارضية المنطقة الصناعية',
        'اشبيلية',
        'الضجيج',
        'الفروانية',
        'الفردوس',
        'جليب الشيوخ',
        'خيطان',
        'العمرية',
        'الرابية',
        'الري',
        'الرقعي',
        'الرحاب',
        'صباح الناصر',
        'سباق الهجن',
        'جنوب عبدالله المبارك',
        'الشداديه',
        'غرب عبدالله المبارك',
        'صباح السالم الجامعيه',
        'صيهيد العوازم',
    ],
    'الجهراء': [
        'العبدلي',
        'النهضة / شرق الصليبخات',
        'أمغرة',
        'بر الجهراء',
        'الجهراء',
        'الجهراء المنطقة الصناعية',
        'كبد',
        'النعيم',
        'النسيم',
        'العيون',
        'القصر',
        'مدينة سعد العبدالله',
        'السالمي',
        'السكراب',
        'جنوب الدوحة / القيروان',
        'الصبية',
        'الصليبية',
        'الصليبية المنطقة الزراعية',
        'تيماء',
        'الواحة',
    ],
    'حولي': [
        'أنجفة',
        'بيان',
        'البدع',
        'حولي',
        'حطين',
        'الجابرية',
        'ميدان حولي',
        'مشرف',
        'مبارك الجابر',
        'النقرة',
        'الرميثية',
        'سلام',
        'السالمية',
        'سلوى',
        'الشعب',
        'الشهداء',
        'الصديق',
        'جنوب السرة',
        'الزهراء',
    ],
    'مبارك الكبير': [
        'أبو الحصانية',
        'أبو فطيرة',
        'العدان',
        'القرين',
        'القصور',
        'الفنطاس',
        'الفنيطيس',
        'المسيلة',
        'مبارك الكبير',
        'صباح السالم',
        'صبحان',
        'جنوب وسطي',
        'وسطي',
    ],
};

export const kuwaitGovernatesEn = {
    'Al Aasima': [
        'Dahyat Abdulla Al-Salem',
        'Adailiya',
        'Bnaid Al-Qar',
        'Daʿiya',
        'Dasma',
        'Doha',
        'Doha Port',
        "Faiha'",
        'Failaka',
        'Granada',
        'Jaber Al-Ahmad City',
        'Jibla',
        'Kaifan',
        'Khaldiya',
        'Mansuriya',
        'Mirgab',
        'Nahdha',
        'North West Sulaibikhat',
        'Nuzha',
        'Qadsiya',
        'Qurtuba',
        'Rawda',
        'Shamiya',
        'Sharq',
        'Shuwaikh',
        'Shuwaikh Industrial Area',
        'Shuwaikh Port',
        'Sulaibikhat',
        'Surra',
        'Umm an Namil Island',
        'Yarmouk',
        'Salhiya',
        'Qibla',
        'Abdullah Al-Salem',
    ],
    'Hawalli Governorate': [
        'Anjafa',
        'Bayān',
        "Bi'da",
        'Hawally',
        'Hittin',
        'Jabriya',
        'Maidan Hawalli',
        'Mishrif',
        'Mubarak Al-Jabir',
        'Nigra',
        'Rumaithiya',
        'Salam',
        'Salmiya',
        'Salwa',
        "Sha'ab",
        'Shuhada',
        'Siddiq',
        'South Surra',
        'Zahra',
    ],
    'Mubarak Al-Kabeer': [
        'Abu Al Hasaniya',
        'Abu Futaira',
        'Adān',
        'Al Qurain',
        'Al-Qusour',
        'Fintās',
        'Funaitīs',
        'Misīla',
        'Mubarak Al-Kabeer',
        'Sabah Al-Salem',
        'Sabhān',
        'South Wista',
        'Wista',
    ],
    'Ahmadi Governorate': [
        'Abu Halifa',
        'Abdullah Port',
        'Ahmadi',
        'Ali As-Salim',
        'Aqila',
        'Bar Al Ahmadi',
        'Bneidar',
        'Dhaher',
        'Fahaheel',
        'Fahad Al-Ahmad',
        'Hadiya',
        'Jaber Al-Ali',
        'Jawaher Al Wafra',
        "Jilei'a",
        'Khairan',
        'Mahbula',
        'Mangaf',
        "Miqwa'",
        'New Khairan City',
        'New Wafra',
        'Nuwaiseeb',
        'Riqqa',
        'Sabah Al-Ahmad City',
        'Sabah Al Ahmad Sea City',
        'Sabahiya',
        "Shu'aiba (North & South)",
        'South Sabahiya',
        'Wafra',
        'Zoor',
        'Zuhar',
    ],
    'Farwaniya Governorate': [
        'Abdullah Al-Mubarak',
        'Airport District',
        'Andalous',
        'Ardiya',
        'Ardiya Herafiya',
        'Ardiya Industrial Area',
        'Ashbelya',
        'Dhajeej',
        'Farwaniya',
        'Fordous',
        'Jleeb Al-Shuyoukh',
        'Khaitan',
        'Omariya',
        'Rabiya',
        'Rai',
        'Al-Riggae',
        'Rihab',
        'Sabah Al-Nasser',
        'Sabaq Al Hajan',
        'Abdullah Al-Mubarak',
    ],
    'Jahra Governorate': [
        'Abdali',
        'Al Nahda / East Sulaibikhat',
        'Amghara',
        'Bar Jahra',
        'Jahra',
        'Jahra Industrial Area',
        'Kabad',
        'Naeem',
        'Nasseem',
        'Oyoun',
        'Qasr',
        'Saad Al Abdullah City',
        'Salmi',
        'Sikrab',
        'South Doha / Qairawān',
        'Subiya',
        'Sulaibiya',
        'Sulaibiya Agricultural Area',
        'Taima',
        'Waha',
    ],
};

export const bahrainGovernoratesAr = {
    'محافظة العاصمة': [
        'المنامة',
        'الجفير',
        'السيف',
        'العدلية',
        'الحورة',
        'بلاد القديم',
        'الزنج',
        'الفاتح',
        'العدلية',
        'المنطقة الدبلوماسية',
    ],
    'محافظة المحرق': ['المحرق', 'الحد', 'عراد', 'قلالي', 'البسيتين', 'ديار المحرق'],
    'المحافظة الشمالية': [
        'البديع',
        'الدراز',
        'سار',
        'باربار',
        'بني جمرة',
        'الجنبية',
        'المالكية',
        'القدم',
        'شاخورة',
        'هملة',
        'الدراز',
        'جد الحاج',
        'الجسرة',
        'ديار المحرق',
    ],
    'المحافظة الجنوبية': [
        'الرفاع',
        'الرفاع الغربي',
        'الرفاع الشرقي',
        'مدينة عيسى',
        'الزلاق',
        'عسكر',
        'جو',
        'سترة',
        'عوالي',
        'جزر حوار',
    ],
};

export const bahrainGovernoratesEn = {
    'Capital Governorate': [
        'Manama',
        'Juffair',
        'Seef',
        'Gudaibiya',
        'Hoora',
        'Bilad Al Qadeem',
        'Zinj',
        'Al-Fateh',
        'Adliya',
        'Diplomatic Area',
    ],
    'Muharraq Governorate': ['Muharraq', 'Hidd', 'Arad', 'Galali', 'Busaiteen', 'Diyar Al Muharraq'],
    'Northern Governorate': [
        'Budaiya',
        'Diraz',
        'Saar',
        'Barbar',
        'Bani Jamra',
        'Janabiya',
        'Al-Malikiyah',
        'Al Qadam',
        'Shakhurah',
        'Hamala',
        'Duraz',
        'Jid Al Haj',
        'Jasra',
        'Diyar Al Muharraq',
    ],
    'Southern Governorate': [
        'Riffa',
        'West Riffa',
        'East Riffa',
        'Isa Town',
        'Zallaq',
        'Askar',
        'Jaww',
        'Sitra',
        'Awali',
        'Hawar Islands',
    ],
};

export const saudiArabiaGovernoratesAr = {
    'منطقة الرياض': [
        'الرياض',
        'الخرج',
        'المجمعة',
        'الزلفي',
        'الغاط',
        'حوطة بني تميم',
        'عفيف',
        'القويعية',
        'وادي الدواسر',
        'رفحاء',
        'الحريق',
        'ضرما',
        'المزاحمية',
        'شقراء',
        'ثادق',
        'الارطاوية',
    ],
    'منطقة مكة المكرمة': [
        'مكة المكرمة',
        'جدة',
        'الطائف',
        'رابغ',
        'الليث',
        'القنفذة',
        'خليص',
        'الجموم',
        'رنية',
        'تربة',
        'ميسان',
        'الخرمة',
    ],
    'منطقة المدينة المنورة': ['المدينة المنورة', 'ينبع', 'العلا', 'بدر', 'خيبر', 'مهد الذهب', 'الحناكية'],
    'المنطقة الشرقية': [
        'الدمام',
        'الخبر',
        'الظهران',
        'الجبيل',
        'الأحساء',
        'الهفوف',
        'القطيف',
        'رأس تنورة',
        'الخفجي',
        'النعيرية',
        'القيصومة',
        'بقيق',
        'البطحاء',
        'ابقيق',
    ],
    'منطقة عسير': [
        'أبها',
        'خميس مشيط',
        'بيشة',
        'أحد رفيدة',
        'سراة عبيدة',
        'النماص',
        'تنومة',
        'المجاردة',
        'رجال ألمع',
        'ظهران الجنوب',
        'تثليث',
    ],
    'منطقة جازان': [
        'جيزان',
        'صبيا',
        'صامطة',
        'أبو عريش',
        'فرسان',
        'الداير',
        'العارضة',
        'الحرث',
        'الخوبة',
        'الدرب',
        'بيش',
    ],
    'منطقة نجران': ['نجران', 'شرورة', 'الخرخير'],
    'منطقة الباحة': ['الباحة', 'بلجرشي', 'المخواة', 'العقيق', 'قلوة', 'المندق'],
    'منطقة الجوف': ['سكاكا', 'دومة الجندل', 'القريات', 'طبرجل'],
    'منطقة الحدود الشمالية': ['عرعر', 'رفحاء', 'طريف'],
    'منطقة حائل': ['حائل', 'بقعاء', 'الشنان', 'الغزالة', 'موقق'],
    'منطقة تبوك': ['تبوك', 'الوجه', 'ضبا', 'تيماء', 'أملج', 'حقل'],
    'منطقة القصيم': ['بريدة', 'عنيزة', 'الرس', 'البكيرية', 'المذنب', 'رياض الخبراء', 'البدائع', 'ضريه'],
};

export const saudiArabiaGovernoratesEn = {
    'Riyadh Region': [
        'Riyadh',
        'Al Kharj',
        "Al Majma'ah",
        'Al Zulfi',
        'Al Ghat',
        'Hotat Bani Tamim',
        'Afif',
        "Al Quway'iyah",
        'Wadi ad-Dawasir',
        'Rafha',
        'Al Hariq',
        'Dhurma',
        'Al Muzahimiyah',
        'Shaqra',
        'Thadiq',
        'Al Artawiyah',
    ],
    'Makkah Region': [
        'Makkah',
        'Jeddah',
        "Ta'if",
        'Rabigh',
        'Al Lith',
        'Al Qunfudhah',
        'Khulais',
        'Al Jumum',
        'Ranyah',
        'Turubah',
        'Maysan',
        'Al Khurma',
    ],
    'Madinah Region': ['Madinah', 'Yanbu', 'AlUla', 'Badr', 'Khaybar', 'Mahd adh Dhahab', 'Al Hinakiyah'],
    'Eastern Region': [
        'Dammam',
        'Al Khobar',
        'Dhahran',
        'Jubail',
        'Al Ahsa',
        'Hofuf',
        'Qatif',
        'Ras Tanura',
        'Khafji',
        'Al Nairyah',
        'Al Qaisumah',
        'Buqayq',
        'Al Batha',
        'Abqaiq',
    ],
    'Asir Region': [
        'Abha',
        'Khamis Mushait',
        'Bisha',
        'Ahad Rafidah',
        'Sarat Abidah',
        'Al Namas',
        'Tanomah',
        'Al Majaridah',
        'Rijal Almaa',
        'Zahran Al Janoub',
        'Tathleeth',
    ],
    'Jizan Region': [
        'Jizan',
        'Sabya',
        'Samtah',
        'Abu Arish',
        'Farasan',
        'Al Dayer',
        'Al Aridah',
        'Al Harth',
        'Al Khoubah',
        'Al Darb',
        'Beish',
    ],
    'Najran Region': ['Najran', 'Sharurah', 'Al Kharkhir'],
    'Al-Bahah Region': ['Al Bahah', 'Baljurashi', 'Al Makhwah', 'Al Aqiq', 'Qilwah', 'Almandaq'],
    'Al-Jawf Region': ['Sakakah', 'Dumat al-Jandal', 'Al Qurayyat', 'Tabarjal'],
    'Northern Borders Region': ['Arar', 'Rafha', 'Turaif'],
    'Hail Region': ['Hail', "Baqa'a", 'Shinan', 'Al Ghazalah', 'Madhayah'],
    'Tabuk Region': ['Tabuk', 'Al Wajh', 'Duba', 'Tayma', 'Umluj', 'Haql'],
    'Qassim Region': [
        'Buraidah',
        'Unaizah',
        'Al Rass',
        'Al Bukayriyah',
        'Al Mithnab',
        'Riyadh Al Khabra',
        'Al Badayea',
        'Dharriyah',
    ],
};
