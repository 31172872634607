import { useEffect, useState, useRef } from 'react'
import { animated, useSpring, useSprings } from '@react-spring/web'
import { postDataCall } from '../services/utils';

import { useTranslation } from 'react-i18next';

import { AiFillHome } from "react-icons/ai";
import { IoIosHelpCircle } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { TbCircleLetterD } from "react-icons/tb";
import { IoIosArrowBack } from "react-icons/io";
import logo from '../../assets/image/logo_resized.png';

function Home({ setSelectedPage }) {

    const { t } = useTranslation();

    const springs = useSpring({
        from: { background: 'linear-gradient(180deg, rgba(11, 68, 117, 1) 2%, rgba(255, 255, 255, 1) 0%)' },
        to: { background: 'linear-gradient(180deg, rgba(11, 68, 117, 1) 2%, rgba(255, 255, 255, 1) 100%)' },
        config: { duration: 400 }
    })

    return (
        <animated.div style={{ ...springs }} id='home-container'>

            <TbCircleLetterD id='D-icon-home' />
            <div id='home-header'>
                <span>{t('chatbot.hiThere')}👋</span>
                <span>{t('chatbot.howCanWeHelp')}</span>

                <div id='ask-a-question' onClick={() => setSelectedPage('Help')}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{t('chatbot.askAQuestion')}</span>
                        <span>{t('chatbot.getFastResponses')}</span>
                    </div>

                    <IoSend id='ask-a-question-icon' />
                </div>
            </div>

            <div id='chatbot-nav'>
                <AiFillHome className='nav-icon' onClick={() => setSelectedPage('Home')} />
                <IoIosHelpCircle className='nav-icon' onClick={() => setSelectedPage('Help')} />
            </div>
        </animated.div>
    )
}

function Help({ setSelectedPage, user_email, user_first_name }) {

    const { t, i18n } = useTranslation();

    const [emailInput, setEmailInput] = useState('')

    const messagesRef = useRef()

    const [messages, setMessages] = useState([
        {
            role: 'assistant',
            content: t('chatbot.introduction'),
            id: Date.now()
        }
    ])

    const faq = [
        t('chatbot.customerService'),
        t('chatbot.availableCourses'),
        t('chatbot.refundMethods'),
        t('chatbot.paymentMethods'),
        t('chatbot.createAccount'),
        t('chatbot.reactivateAccount')
    ]

    const backgroundSprings = useSpring({
        from: { background: 'linear-gradient(180deg, rgba(11, 68, 117, 1) 2%, rgba(255, 255, 255, 1) 100%)' },
        to: { background: 'linear-gradient(180deg, rgba(11, 68, 117, 1) 2%, rgba(255, 255, 255, 1) 0%)' },
        config: { duration: 100 }
    })

    const [springs, api] = useSprings(
        messages.length,
        () => ({
            to: { opacity: 1, transform: 'scale(1)' },
            from: { opacity: 0, transform: 'scale(0.3)' },
            config: { tension: 250, friction: 20 },
        }),
        []
    )

    function Message({ message, index }) {

        function messageStyles() {
            if (i18n.language == 'ar') {
                return { display: 'flex', alignItems: 'center', ...springs[index], direction: 'rtl', marginLeft: 'auto' }
            } else {
                return { display: 'flex', alignItems: 'center', ...springs[index], direction: 'ltr', marginRight: 'auto' }
            }
        }

        return (
            <animated.div style={messageStyles()}>
                {message.role == 'assistant' ? <img src={logo} className="message-ai-img" /> : null}
                <div className='message'>
                    <span>{message.content}</span>
                </div>
            </animated.div>
        )
    }

    function CustomerServiceForm() {

        const [formData, setFormData] = useState({
            email: '',
            inquiry: ''
        })

        function handleInput(e, element) {
            if (element == 'email') {
                setFormData({ ...formData, email: e.target.value })
            } else if (element == 'inquiry') {
                setFormData({ ...formData, inquiry: e.target.value })
            }

        }

        function buttonDisabled() {
            if (formData.email == '' || formData.inquiry == '') return true;
            else return false;
        }

        async function handleSubmit() {

            if (!buttonDisabled()) {
                const resp = await postDataCall('/v1/chatbot_customer_service', {
                    email: formData.email,
                    inquiry: formData.inquiry
                })

                if (resp) {
                    setMessages((prevMessages) => [...prevMessages,
                    {
                        role: 'assistant',
                        content: t('chatbot.thankYou'),
                        id: Date.now()
                    }])
                }

                setFormData({ email: '', inquiry: '' })
            }

        }

        return (
            <>
                <input value={formData.email} onChange={(e) => handleInput(e, 'email')} className='cs-form-input' placeholder='Enter Email...' />
                <textarea maxLength={255} value={formData.inquiry} onChange={(e) => handleInput(e, 'inquiry')} className='cs-form-textarea' placeholder='Ask a question' />
                <button disabled={buttonDisabled()} onClick={handleSubmit} style={{ backgroundColor: buttonDisabled() ? 'grey' : null }} className='cs-form-button'>Submit</button>
            </>
        )
    }

    function handleInputChange(e) {
        setEmailInput(e.target.value)
    }

    async function handleEmailSubmit(key) {
        if (emailInput != '') {
            const resp = await postDataCall('/v1/chatbot_reactivation', {
                email: emailInput
            })

            if (resp) {
                setMessages((prevMessages) => [...prevMessages,
                {
                    role: 'assistant',
                    content: t('chatbot.thankYou'),
                    id: Date.now()
                }])
            }

            setEmailInput('')
        }
    }

    useEffect(() => {
        setTimeout(() => (messagesRef.current.scrollTop = messagesRef.current.scrollHeight), 10);
    }, [messages])

    function getMessage(question) {

        switch (question) {
            case t('chatbot.customerService'):
                return setMessages((prevMessages) => [...prevMessages,
                {
                    role: 'assistant',
                    content: t('chatbot.customerServiceResponse'),
                    id: Date.now()
                }])
            case t('chatbot.availableCourses'):
                return setMessages((prevMessages) => [...prevMessages,
                {
                    role: 'assistant',
                    content: t('chatbot.availableCoursesResponse'),
                    id: Date.now()
                }])
            case t('chatbot.refundMethods'):
                return setMessages((prevMessages) => [...prevMessages,
                {
                    role: 'assistant',
                    content: t('chatbot.refundMethodsResponse'),
                    id: Date.now()
                }])
            case t('chatbot.paymentMethods'):
                return setMessages((prevMessages) => [...prevMessages,
                {
                    role: 'assistant',
                    content: t('chatbot.paymentMethodsResponse'),
                    id: Date.now()
                }])
            case t('chatbot.createAccount'):
                return setMessages((prevMessages) => [...prevMessages,
                {
                    role: 'assistant',
                    content: t('chatbot.createAccountResponse'),
                    id: Date.now()
                }])
            case t('chatbot.reactivateAccount'):
                return setMessages((prevMessages) => [...prevMessages,
                {
                    role: 'assistant',
                    content: t('chatbot.reactivateAccountResponse'),
                    id: Date.now()
                }])
        }

    }

    return (
        <animated.div style={{ ...backgroundSprings }} id='help-container'>

            <div id='help-nav'>
                <IoIosArrowBack id='back-arrow' onClick={() => setSelectedPage('Home')} />
                <span>DAWRATY</span>
            </div>

            <div id='messages-container' ref={messagesRef}>
                <div id='help-header'>
                    <img src={logo} style={{ width: '120px' }} />
                    <span>{t('chatbot.askAQuestion')}</span>
                    <span>{t('chatbot.getFastResponses')}</span>
                </div>

                {messages.map((message, key) =>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Message message={message} key={key} index={key} />
                        {message.content.includes(t('chatbot.customerServiceResponse')) ?
                            <CustomerServiceForm />
                            :
                            null
                        }
                        {message.content.includes('email') || message.content.includes('بريد إلكتروني') ?
                            <div>
                                <input style={{ marginLeft: i18n.language == 'ar' ? '38px' : '0px' }} value={emailInput} onChange={(e) => handleInputChange(e)} type="email" className='email-input' placeholder={t('chatbot.enterEmail')} />
                                <IoSend onClick={handleEmailSubmit} className='email-submit-icon' />
                            </div>
                            :
                            null
                        }
                    </div>
                )}

                {/* <input placeholder='Message...' id='help-input' /> */}
            </div>
            <div className='faq'>
                {faq.map((question) => <span onClick={() => getMessage(question)}>{question}</span>)}
            </div>
        </animated.div>
    )
}

function Chatbot({ user_email, user_first_name }) {

    const { t } = useTranslation();

    const [selectedPage, setSelectedPage] = useState('Home')

    function fetchPage() {
        switch (selectedPage) {
            case 'Home':
                return <Home setSelectedPage={setSelectedPage} />
            case 'Help':
                return <Help setSelectedPage={setSelectedPage} user_email={user_email} user_first_name={user_first_name} />;
        }
    }

    return (
        <div id='chatbot-container'>

            {fetchPage()}

        </div>
    )
}

export default Chatbot